
export function withTimeout<T>(
  timeout: number,
  promise: Promise<T>,
  error?: Error
): Promise<T> {
  return Promise.race([
    promise,
    new Promise<T>((_resolve, reject) => {
      setTimeout(() => {
        reject(error || new Error(`timed out after ${timeout}ms`))
      }, timeout)
    })
  ])
}

export function deepCopy<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj))
}

export function roundDecimal(num: number, dp: number): number {
  // fixed JS' floating point innacuracies problem,
  // 1.005.toFixed(2) gives result as 1.00 which should be 1.01;
  return Number(Math.round(Number(num + 'e' + dp)) + 'e-' + dp)
}

export function createQueryString(obj: any): string {
  const parts: string[] = []
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
    }
  }
  return parts.join('&')
}

export function isDefined(val: any): boolean {
  return typeof val != 'undefined'
}

export function isDefinedAndNotNull(val: any): boolean {
  return typeof val != 'undefined' && val != null
}

/**
 * Get the value of a cookie
 * Source: https://vanillajstoolkit.com/helpers/getcookie/
 * @param name - The name of the cookie
 * @return The cookie value
 */
export function getCookie(name: string): string | undefined {
  if (typeof document === 'undefined') {
    throw new Error(
      'getCookie() is not supported on the server. Fallback to a different value when rendering on the server.',
    );
  }

  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts[1].split(';').shift();
  }

  return undefined;
}
