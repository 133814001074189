/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as React from 'react'
import { Ii18nHelper, i18n } from '../settings/i18n'
import { IEnvironment } from '../environments'
import * as i18next from 'i18next'
import IClient from '../data_sources'
import { IImageStorage } from '../data_sources/imagestorage/imageStorage'
import { DexieImageStorage } from '../data_sources/imagestorage'

export interface IOneplaceLibraryConfigProviderProps {
  i18n?: i18next.i18n
  env: IEnvironment
  client: IClient
  imageStorage?: IImageStorage
}
export interface IOneplaceLibrary {
  i18next: i18next.i18n
  env: IEnvironment
  client: IClient
  imageStorage: IImageStorage
}

export const OneplaceLibraryContext = React.createContext<IOneplaceLibrary>(
  null as any
)

export class OneplaceLibraryContextProvider extends React.Component<
  IOneplaceLibraryConfigProviderProps,
  IOneplaceLibrary
> {
  i18next: i18next.i18n
  i18Helper: Ii18nHelper | null
  constructor(props: IOneplaceLibraryConfigProviderProps) {
    super(props)

    if (props.i18n) {
      this.i18next = props.i18n
      this.i18Helper = null
    } else {
      this.i18Helper = i18n
      this.initialiseApp()
      this.i18next = this.i18Helper.instance()
    }

    const imageStorage: IImageStorage = props.imageStorage
      ? props.imageStorage
      : new DexieImageStorage()

    this.state = {
      i18next: this.i18next,
      env: props.env,
      client: props.client,
      imageStorage: imageStorage
    }
  }

  async initialiseApp() {
    await this.i18Helper?.initialise()
  }

  render(): React.ReactNode {
    return (
      <OneplaceLibraryContext.Provider value={this.state}>
        {this.props.children}
      </OneplaceLibraryContext.Provider>
    )
  }
}

export interface IOneplaceLibraryContextProp {
  ctx: IOneplaceLibrary
}

export function withOneplaceLibraryContext<
  TComponentProps extends IOneplaceLibraryContextProp
>(Component: React.ComponentType<TComponentProps>) {
  return function OneplaceLibraryComponent(
    props: Pick<
      TComponentProps,
      Exclude<keyof TComponentProps, keyof IOneplaceLibraryContextProp>
    >
  ) {
    return (
      <OneplaceLibraryContext.Consumer>
        {(ctx) => <Component {...(props as TComponentProps)} ctx={ctx}/>}
      </OneplaceLibraryContext.Consumer>
    )
  }
}

export default OneplaceLibraryContextProvider
