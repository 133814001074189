import * as React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

export interface IPageLoaderProps {
  loading: boolean
  status?: string
  paddingTop?: number
}

export const PageLoader = (props: IPageLoaderProps) => (
  <div
    style={{
      paddingTop:
        typeof props.paddingTop != 'undefined' ? props.paddingTop : 50,
      textAlign: 'center',
      width: '100%'
    }}
  >
    {props.loading && <CircularProgress size={42} />}
    {props.status && (
      <Typography variant='subtitle1'>{props.status}</Typography>
    )}
  </div>
)
