import {
  IImageStorage,
  STORED_IMAGE_PREFIX,
  ImageStorageType,
  TEMP_IMAGE_PREFIX,
  getImageStorageType
} from './imageStorage'
import Dexie from 'dexie'

interface IStoredImage {
  imageId?: number
  timestamp: number
  data: Blob
}

class DexieImageDB extends Dexie {
  images!: Dexie.Table<IStoredImage, number>

  constructor() {
    super('survey_images')
    this.version(1).stores({
      images: '++imageId'
    })
  }
}

// Future Enhancement: Implement a shared superclass for Image Storage, since the logic is pretty much the same
export class DexieImageStorage implements IImageStorage {
  db: DexieImageDB
  objectUrls: {
    [objectUrl: string]: Blob
  } = {}
  storedImageObjectUrls: {
    [imageId: number]: string
  } = {}

  constructor() {
    this.db = new DexieImageDB()
    this.db.version(1).stores({
      images: '++imageId'
    })
  }

  async storeImage(
    imageData: Blob,
    storageType: ImageStorageType
  ): Promise<string> {
    console.log('storing image: ' + storageType)
    if (storageType == 'temporary') {
      // just create objectURL and keep a reference to the blob in memory
      const objectURL = window.URL.createObjectURL(imageData)
      this.objectUrls[objectURL] = imageData
      return TEMP_IMAGE_PREFIX + objectURL
    } else {
      // Store image on filesystem and keep a reference in indexedDB
      const storedImage: IStoredImage = {
        timestamp: Date.now(),
        data: imageData
      }
      const imageId = await this.db.images.add(storedImage)
      return STORED_IMAGE_PREFIX + imageId
    }
  }

  async getImageObjectUrl(localImageUrl: string): Promise<string> {
    const [storageType, imageId] = getImageStorageType(localImageUrl)
    if (storageType == 'temporary') {
      return imageId
    } else {
      if (imageId in this.storedImageObjectUrls) {
        return this.storedImageObjectUrls[Number(imageId)]
      } else {
        const imageData = await this.getImageData(localImageUrl)
        const objectURL = window.URL.createObjectURL(imageData)
        this.objectUrls[objectURL] = imageData
        this.storedImageObjectUrls[Number(imageId)] = objectURL
        return objectURL
      }
    }
  }

  async getImageData(localImageUrl: string): Promise<Blob> {
    const [storageType, imageId] = getImageStorageType(localImageUrl)
    if (storageType == 'temporary') {
      return this.objectUrls[imageId]
    } else {
      const image = await this.db.images.get(Number(imageId))
      if (image) {
        return image.data
      } else {
        throw new Error('missimage iamges')
      }
    }
  }

  async makePermenant(tempImageUrl: string): Promise<string> {
    const [storageType, imageId] = getImageStorageType(tempImageUrl)
    if (storageType != 'temporary') {
      throw new Error('makePermenant() called on a non-temporary image')
    }
    const imageData = this.objectUrls[imageId]
    const storedUrl = await this.storeImage(imageData, 'permanent')
    const storedImageId = getImageStorageType(storedUrl)[1]
    this.storedImageObjectUrls[Number(storedImageId)] = imageId
    return storedUrl
  }

  async removeImage(localImageUrl: string): Promise<void> {
    console.log('removing image', localImageUrl)
    const [storageType, imageId] = getImageStorageType(localImageUrl)
    if (storageType == 'temporary') {
      window.URL.revokeObjectURL(imageId)
      delete this.objectUrls[imageId]
    } else {
      // Revoke ObjectURL
      const objectUrl = this.storedImageObjectUrls[Number(imageId)]
      if (objectUrl) {
        window.URL.revokeObjectURL(objectUrl)
      }
      console.log('removing image id', imageId)
      // Delete database record
      await this.db.images.delete(Number(imageId))
    }
  }

  revokeObjectUrls(): void {
    const objectUrls = Object.keys(this.objectUrls)
    console.log(`revoking ${objectUrls.length} object URLs`)
    for (const objectUrl of objectUrls) {
      window.URL.revokeObjectURL(objectUrl)
    }
    this.storedImageObjectUrls = {}
    this.objectUrls = {}
  }
}
