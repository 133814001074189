import * as React from 'react'
import { IControlProps } from './types'
import Typography from '@material-ui/core/Typography'

export default class SubtotalControl extends React.Component<IControlProps> {
  constructor(props: any) {
    super(props)
  }

  render(): React.ReactNode {
    return (
      <Typography style={{ marginRight: 12, whiteSpace: 'pre-line' }}>
        {this.props.field.value || ''}
      </Typography>
    )
  }
}
