import * as React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { IControlProps } from './types'
import { IControlColor, COLOURS } from './colours'
import { GRID_CELLPADDING, GRID_CELLFONTSIZE } from './grids/consts'
import {
  IOneplaceLibraryContextProp,
  withOneplaceLibraryContext
} from '../OneplaceLibraryProvider'

const TICKED = 'Ticked'
const NOT_TICKED = 'Not Ticked'

export interface ICheckboxControlProps
  extends IOneplaceLibraryContextProp,
    IControlProps {}

export default withOneplaceLibraryContext(
  class CheckboxControl extends React.Component<ICheckboxControlProps> {
    tickedLabel: string
    notTickedLabel: string

    constructor(props: any) {
      super(props)
      props.tickedLabel
        ? (this.tickedLabel = props.tickedlabel)
        : (this.tickedLabel = TICKED)
      props.notTickedLabel
        ? (this.notTickedLabel = props.notTickedLabel)
        : (this.notTickedLabel = NOT_TICKED)
      this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      this.props.form.setFieldValue(this.props.field.name, event.target.checked)
    }

    checkValue() {
      return this.props.field.value || this.props.field.value == 'true'
    }

    render(): React.ReactNode {
      let checkedColour: IControlColor | null = null
      let uncheckedColour: IControlColor | null = null

      if (this.props.colourRules) {
        const ticked = this.props.colourRules.find(
          (rule) => rule.stringValue == TICKED
        )
        const unticked = this.props.colourRules.find(
          (rule) => rule.stringValue == NOT_TICKED
        )
        if (ticked && COLOURS[ticked.color]) {
          checkedColour = COLOURS[ticked.color]
        }
        if (unticked && COLOURS[unticked.color]) {
          uncheckedColour = COLOURS[unticked.color]
        }
      }

      if (this.props.gridCell) {
        const value =
          String(this.props.field.value) == 'true'
            ? this.props.ctx.i18next.t('ticked')
            : this.props.ctx.i18next.t('not_ticked')
        const cellStyle: React.CSSProperties = {
          padding: GRID_CELLPADDING,
          fontSize: GRID_CELLFONTSIZE
        }

        if (this.checkValue() && checkedColour) {
          cellStyle.color = checkedColour.foreground
          cellStyle.background = checkedColour.background
        } else if (!this.checkValue() && uncheckedColour) {
          cellStyle.color = uncheckedColour.foreground
          cellStyle.background = uncheckedColour.background
        }

        return (
          <div id={this.props.id} style={cellStyle}>
            {value}
          </div>
        )
      } else {
        const wrapperStyle: React.CSSProperties = {
          display: 'block',
          padding: 2
        }
        const checkboxStyle: React.CSSProperties = {
          marginTop: -10,
          marginBottom: -10
        }

        if (this.checkValue() && checkedColour) {
          checkboxStyle.color = checkedColour.foreground
          wrapperStyle.background = checkedColour.background
        } else if (!this.checkValue() && uncheckedColour) {
          checkboxStyle.color = uncheckedColour.foreground
          wrapperStyle.background = uncheckedColour.background
        }

        return (
          <label style={wrapperStyle}>
            <Checkbox
              id={this.props.id}
              checked={
                true == this.props.field.value ||
                'true' == this.props.field.value ||
                false
              }
              disabled={this.props.disabled || false}
              onChange={this.handleChange}
              color='primary'
              style={checkboxStyle}
            />
          </label>
        )
      }
    }
  }
)
