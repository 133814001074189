import * as React from 'react'
import Radio from '@material-ui/core/Radio'
import Select from '@material-ui/core/Select'
import { IControlProps, SelectOptions } from './types'
import { Typography } from '@material-ui/core'
import { COLOURS } from './colours'
import { GRID_CELLPADDING, GRID_CELLFONTSIZE } from './grids/consts'

export interface ISelectControlProps extends IControlProps {
  options: SelectOptions
  dropdown?: boolean
  showBlankOption: boolean
}

export default class SelectControl extends React.Component<
  ISelectControlProps
  > {
  constructor(props: any) {
    super(props)
    this.onSelectChange = this.onSelectChange.bind(this)
    this.onRadioClick = this.onRadioClick.bind(this)
  }

  onSelectChange(event: any) {
    const newValue = event.target.value
    if (this.props.onFieldChange) {
      this.props.onFieldChange(
        this.props.field.value,
        newValue,
        this.props.form
      )
    }
    this.props.form.setFieldValue(this.props.field.name, newValue)
  }

  onRadioClick(event: any) {
    let selectedValue = event.target.value
    if (selectedValue == this.props.field.value) {
      // Deselect value
      selectedValue = ''
    }
    if (this.props.onFieldChange) {
      this.props.onFieldChange(
        this.props.field.value,
        selectedValue,
        this.props.form
      )
    }
    this.props.form.setFieldValue(this.props.field.name, selectedValue)
  }

  render() {
    const disabled = this.props.disabled || false
    const showBlankOption =
      this.props.showBlankOption != null ? this.props.showBlankOption : true
    const colourRules = this.props.colourRules || []
    const options = this.props.options
      ? this.props.options.map((option) => {
        const match = colourRules.find(
          (rule) => rule.stringValue == option.value
        )
        if (match && COLOURS[match.color]) {
          return {
            label: option.label,
            value: option.value,
            colour: COLOURS[match.color]
          }
        }
        return option
      })
      : []

    const selectedValue = options.find(
      (opt) => opt.value == this.props.field.value
    )

    if (this.props.gridCell) {
      const cellStyle: React.CSSProperties = {
        padding: GRID_CELLPADDING,
        fontSize: GRID_CELLFONTSIZE
      }
      if (selectedValue && selectedValue.colour) {
        cellStyle.color = selectedValue.colour.foreground
        cellStyle.background = selectedValue.colour.background
      }
      return (
        <div id={this.props.id} style={cellStyle}>
          {this.props.field.value || ''}
        </div>
      )
    } else if (this.props.dropdown || options.length > 5) {
      const selectStyle: React.CSSProperties = {}
      if (selectedValue && selectedValue.colour) {
        selectStyle.paddingLeft = 8
        selectStyle.color = selectedValue.colour.foreground
        selectStyle.background = selectedValue.colour.background
      }
      return (
        <Select
          id={this.props.id}
          disabled={disabled}
          native
          fullWidth
          value={this.props.field.value || ''}
          onChange={this.onSelectChange}
          style={selectStyle}
        >
          {showBlankOption && <option value='' />}
          {options.map((option, idx) => (
            <option
              key={idx}
              value={option.value}
              style={{ color: 'black', background: 'white' }}
            >
              {option.label}
            </option>
          ))}
        </Select>
      )
    } else {
      return (
        <div id={this.props.id}>
          {options.map((option, idx) => {
            const labelStyle: React.CSSProperties = {
              display: 'flex',
              alignItems: 'center'
            }
            const radioStyle: React.CSSProperties = { padding: 8 }
            const labelTextStyle: React.CSSProperties = {}
            if (
              selectedValue &&
              option.value == selectedValue.value &&
              selectedValue.colour
            ) {
              labelStyle.background = selectedValue.colour.background
              labelTextStyle.color = selectedValue.colour.foreground
              radioStyle.color = selectedValue.colour.foreground
            }
            return (
              <label style={labelStyle} key={idx}>
                <Radio
                  name={this.props.id}
                  value={option.value}
                  color='primary'
                  disabled={disabled}
                  onClick={this.onRadioClick}
                  style={radioStyle}
                  checked={option.value == this.props.field.value}
                />
                <Typography component='span' style={labelTextStyle}>
                  {option.label}
                </Typography>
              </label>
            )
          })}
        </div>
      )
    }
  }
}
