import React from 'react'
import { createStyles, makeStyles, Grid, Link } from '@material-ui/core'
import { INotificationFCMData } from '../../models/Notifications'
import { displayDateInZone } from '../../utils/dates'
import { truncateText } from '../../utils/stringUtil'

const useStyles = makeStyles(() =>
  createStyles({
    snackbar: {
      borderLeft: '4px solid #f50057',
      backgroundColor: 'white',
      color: 'black'
    },
    snackbar_icon: {
      fontFamily: 'oneplaceIcons',
      fontSize: '3rem !important',
      color: '#f50057'
    },
    snackbar_link: {
      color: 'black',
      textTransform: 'none',
      textAlign: 'left'
    }
  })
)

export interface INotificationSnackbarProps {
  notificationFCMData: INotificationFCMData
  clickLink: (url: string, notificationId: string) => void
}

export default function NotificationSnackbar(
  props: INotificationSnackbarProps
) {
  const classes = useStyles()
  return (
    <>
      <Grid container direction='row'>
        <Grid item style={{ marginRight: '10px' }}>
          {props.notificationFCMData.entityType === 'ticket' && (
            <span className={classes.snackbar_icon}>
              <span className='icon-1place-tickets' />
            </span>
          )}
          {props.notificationFCMData.entityType === 'site' && (
            <span className={classes.snackbar_icon}>
              <span className='icon-1place-site' />
            </span>
          )}
          {props.notificationFCMData.entityType === 'franchisee' && (
            <span className={classes.snackbar_icon}>
              <span className='icon-1place-franchisee' />
            </span>
          )}
          {props.notificationFCMData.entityType === 'person' && (
            <span className={classes.snackbar_icon}>
              <span className='icon-1place-customer' />
            </span>
          )}
        </Grid>
        <Grid item>
          <Link
            component='button'
            onClick={() => {
              props.clickLink(
                props.notificationFCMData.appLink,
                props.notificationFCMData.notificationId
              )
            }}
            className={classes.snackbar_link}
          >
            <b>{props.notificationFCMData.entityName}</b>
            <br />
            {props.notificationFCMData.entityType === 'ticket' && (
              <>
                Subject: {props.notificationFCMData.subject}
                <br />
                Status: {props.notificationFCMData.ticketStatus}
                <br />
                Due date:{' '}
                {displayDateInZone(
                  props.notificationFCMData.date,
                  props.notificationFCMData.timezone
                )}
                <br />
                {props.notificationFCMData.comment &&
                  'comment: ' +
                    truncateText(props.notificationFCMData.comment, 50)}
              </>
            )}
            {props.notificationFCMData.entityType !== 'ticket' && (
              <>
                {props.notificationFCMData.customFieldName}:{' '}
                {displayDateInZone(
                  props.notificationFCMData.date,
                  props.notificationFCMData.timezone
                )}
                <br />
                {truncateText(props.notificationFCMData.content, 50)}
              </>
            )}
          </Link>
        </Grid>
      </Grid>
    </>
  )
}
