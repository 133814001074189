import { IIDAndName } from './Entity'
import { ICustomField } from './CustomFields'

export interface IFranchise {
  id: number
  name: string
  franchisees?: IFranchisee[]
}

export interface IFranchisee {
  id: number
  name: string
  email: string
  attributes: IAttribute[]
  primaryContact?: IContact
  primaryAddress?: IAddress
  customFields?: ICustomField[]
  external?: boolean
  disabledFields?: IFranchiseeSiteDisabledFields
}

export interface IFranchisees {
  franchisees: IFranchisee[]
}

export interface ISites {
  sites: ISite[]
}

export interface IAttribute {
  id: number
  name: string
}

export interface IAddress {
  id?: number
  street: string
  suburb: string
  city: string
  postcode: string
  state: string
  country: string
  unitNumber: string
  //deprecated as of 26/02/2024
  unit_no: string
}

// unitno deprecated as of 26/02/2024
export const SiteAddressFields: Array<keyof IAddress> = [
  'unit_no',
  'unitNumber',
  'street',
  'suburb',
  'city',
  'postcode',
  'state',
  'country'
]

export interface IContact {
  id: number | null
  email: string
  //deprecated as of 26/02/2024
  mobile: string
  mobilePhone: string
  fax: string | null
  firstName: string
  //deprecated as of 26/02/2024
  first_name: string
  lastName: string
  //deprecated as of 26/02/2024
  last_name: string
  workPhone: string
  //deprecated as of 26/02/2024
  work_phone: string
  homePhone: string
  //deprecated as of 26/02/2024
  home_phone: string
  emailCC: string
  position: string
}

export interface ISite {
  id: number
  name: string
  address?: IAddress
  email: string
  contact?: IContact
  attributes: IAttribute[]
  customFields?: ICustomField[]
  external?: boolean
  disabledFields?: IFranchiseeSiteDisabledFields

  // Unfortunately site franchisee details come back differently depending
  // which API endpoint we use
  franchisee?: {
    id: number
    name: string
    email?: string
    attributes?: IAttribute[] | null
  }
  franchiseeId?: number

  franchiseeEmail?: string
  franchiseeName?: string
  franchiseeAttributes?: IAttribute[] | null
}

export interface ITags {
  tags: ITag[]
}

export interface ITag {
  id: number
  name: string
  mandatory?: boolean
}

export interface IFranchiseeSiteDisabledFields {
  email: boolean
  address: boolean
  telephone: boolean
  mobile: boolean
}

export function getFranchiseeIdBySite(sites: ISites, siteId: number) {
  const site = sites.sites.find((f) => f.id == siteId)
  if (!site) {
    throw new Error('Failed to find site with ID: ' + String(siteId))
  }
  return site.franchiseeId ? site.franchiseeId : site.franchisee!.id
}

export function getFranchisee(franchisees: IFranchisees, franchiseeId: number) {
  const franchisee = franchisees.franchisees.find((f) => f.id == franchiseeId)
  if (!franchisee) {
    throw new Error('Failed to find franchisee ID: ' + String(franchiseeId))
  }
  return franchisee
}

export function getSite(sites: ISite[], siteId: number) {
  const site = sites.find((s) => s.id == siteId)
  if (!site) {
    throw new Error('Failed to find site ID: ' + String(siteId))
  }
  return site
}

export function findSite(sites: IIDAndName[], siteId: number) {
  const site = sites.find((s) => s.id == siteId)
  if (!site) {
    throw new Error('Failed to find site ID: ' + String(siteId))
  }
  return site
}

export function getSiteFranchiseeId(site: ISite): number {
  if (site) {
    if (site.franchiseeId) {
      return site.franchiseeId
    } else if (site.franchisee && site.franchisee.id) {
      return site.franchisee.id
    }
  }
  return -1
}
