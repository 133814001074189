import * as React from 'react'
import Input from '@material-ui/core/Input'
import Slider from '@material-ui/core/Slider'
import { IControlProps } from './types'
import { GRID_CELLPADDING, GRID_CELLFONTSIZE } from './grids/consts'

export const CHECKLIST_QUESTION_AVOID_CONDITIONAL_QUESTION_REFRESH =
  'avoidConditionalQuestionRefresh-'

export default class PercentageControl extends React.Component<IControlProps> {
  constructor(props: any) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSlide = this.handleSlide.bind(this)
  }

  handleChange(event: any) {
    let newVal = event.target.value
    if (newVal < 0) {
      newVal = '0'
    } else if (newVal > 100) {
      newVal = '100'
    }
    this.props.form.setFieldValue(this.props.field.name, newVal)
  }

  // prevent conditional question refresh for dragging
  handleSlide(_event: any, value: unknown) {
    this.props.form.setFieldValue(
      this.props.field.name,
      String(CHECKLIST_QUESTION_AVOID_CONDITIONAL_QUESTION_REFRESH + value)
    )
  }

  // only apply conditional rule when dragging end
  // Bug: for some reason the page redirected after calling "refreshConditionalQuestion"
  refreshConditionalQuestion = (_event: any, value: unknown) => {
    this.props.form.setFieldValue(this.props.field.name, value)
  }

  render() {
    if (this.props.gridCell) {
      return (
        <div
          id={this.props.id}
          style={{ padding: GRID_CELLPADDING, fontSize: GRID_CELLFONTSIZE }}
        >
          {this.props.field.value || ''}
        </div>
      )
    } else {
      return (
        <div style={{ display: 'flex' }}>
          <Input
            id={this.props.id}
            type='number'
            value={this.props.field.value || ''}
            onChange={this.handleChange}
            style={{ width: 50, marginRight: 20 }}
          />
          <Slider
            min={0}
            max={100}
            step={1}
            valueLabelDisplay='auto'
            value={Number(this.props.field.value) || 0}
            onChange={this.handleSlide}
            onChangeCommitted={this.refreshConditionalQuestion}
          />
        </div>
      )
    }
  }
}
