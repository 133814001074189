import { IAttachment } from '../../models/Checklist'
import { postData, deleteData } from './postData'
import { UnauthorisedError } from '../../errors/UnauthorisedError'
import { BadRequestError } from '../../errors/BadRequestError'
import { ForbiddenError } from '../../errors/ForbiddenError'
import { NotFoundError } from '../../errors/NotFoundError'
import { TimeoutError } from '../../errors/TimeoutError'
import { InternalServerError } from '../../errors/InternalServerError'
import {
  IAttachmentResponse,
  IResponse,
  ISubmitAttachmentResponse
} from '../../models/Entity'

export type ScheduleType = 'completed' | 'week' | 'overdue' | 'today'

export interface IApi {
  getAuthenticatedPhotoUrl(url: string): string
  saveAttachment(attachment: IAttachment): Promise<IAttachmentResponse>
  deleteAttachment(attachment: IAttachment): Promise<string>
  viewAttachment(attachment: IAttachment): void
}

export interface IApiClientConfig {
  url: string
  header?: any
  timeout?: number //TODO there's already a defaultTimeout in the client interface
}

export class Api implements IApi {
  clientConfig: IApiClientConfig = {} as IApiClientConfig

  constructor(cfg?: IApiClientConfig) {
    if (cfg) {
      this.clientConfig = cfg
    }
  }

  getAuthenticatedPhotoUrl(url: string): string {
    // Adds access token to URL if it is on the API
    const photoUrl = url
    return photoUrl
  }

  async saveAttachment(attachment: IAttachment): Promise<IAttachmentResponse> {
    const attachmentFormData = new FormData()
    attachmentFormData.append('attachment', JSON.stringify(attachment))

    /*const blobData: Blob = await fetch(attachment.localObjectUrl!).then((url) =>
      url.blob()
    )*/
    attachmentFormData.append('file', attachment.file!, attachment.fileName)

    return postData(
      this.clientConfig.url,
      this.clientConfig.header,
      attachmentFormData,
      this.clientConfig.timeout
    )
      .then((data: [number, ISubmitAttachmentResponse]) => {
        const [status, response] = data
        if ([200, 201].includes(status)) {
          return response.data
        }
        this.validateResponseStatus(status, 'saveAttachment()')
        throw new Error('Error Adding Attachment.')
      })
      .then((result: IAttachmentResponse) => {
        return { UUID: result.UUID, url: result.url }
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async deleteAttachment(attachment: IAttachment): Promise<string> {
    const id = attachment.uuid || attachment.id
    const url = this.clientConfig.url.endsWith('/')
      ? this.clientConfig.url + String(id)
      : this.clientConfig.url + '/' + String(id)

    return deleteData(
      url,
      this.clientConfig.header,
      {},
      this.clientConfig.timeout
    )
      .then((data: [number, IResponse]) => {
        const [status, response] = data
        if (status === 200) {
          return response.data
        } else if (status === 204) {
          return ''
        }
        this.validateResponseStatus(status, 'deleteAttachment()')
        throw new Error('Error Deleting Attachment.')
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  viewAttachment(attachment: IAttachment): void {
    window.open(attachment.url!)
  }

  private validateResponseStatus = (status: number, errorMsg: string) => {
    switch (status) {
      case 401:
        throw new UnauthorisedError(errorMsg)
      case 0:
        throw new UnauthorisedError(errorMsg)
      case 400:
        throw new BadRequestError(errorMsg)
      case 403:
        throw new ForbiddenError(errorMsg)
      case 404:
        throw new NotFoundError(errorMsg)
      case 408:
        throw new TimeoutError(errorMsg)
      case 500:
        throw new InternalServerError(errorMsg)
      case 501:
        throw new InternalServerError(errorMsg)
      case 502:
        throw new InternalServerError(errorMsg)
      case 503:
        throw new InternalServerError(errorMsg)
      case 504:
        throw new InternalServerError(errorMsg)
    }
  }
}

export default Api
