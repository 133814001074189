import { logError } from '../../logging'
import { UserFacingError } from '../../errors/UserFacingError'
import { NetworkError } from '../../errors/NetworkError'

export function postData(
  url: string,
  headers: any,
  data: any,
  timeout = 0
): Promise<[number, any]> {
  return new Promise<[number, any]>((resolve, reject) => {
    // We have to use XHR because fetch() sets the origin header to null
    // in cordova on android, which causes a 403 in the API
    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) return

      if (xhr.status > 0) {
        resolve([xhr.status, xhr.response])
      }
    }
    xhr.open('POST', url)
    xhr.timeout = timeout
    xhr.ontimeout = (e) => {
      logError(e, 'request timed out')
      reject(new UserFacingError('Request Timeout'))
    }

    xhr.onerror = (e) => {
      logError(e, 'on error in XMLHttpRequest ' + url)
      // most likely a network error
      reject(new NetworkError('Error sending request'))
    }

    Object.keys(headers).forEach((header) => {
      xhr.setRequestHeader(header, headers[header])
    })
    xhr.responseType = 'json'
    xhr.send(data)
  })
}

export function putData(
  url: string,
  headers: any,
  data: any
): Promise<[number, any]> {
  return new Promise<[number, any]>((resolve) => {
    // We have to use XHR because fetch() sets the origin header to null
    // in cordova on android, which causes a 403 in the API
    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        resolve([xhr.status, xhr.response])
      }
    }
    xhr.open('PUT', url)
    Object.keys(headers).forEach((header) => {
      xhr.setRequestHeader(header, headers[header])
    })
    xhr.responseType = 'json'
    xhr.send(data)
  })
}

export function deleteData(
  url: string,
  headers: any,
  data: any,
  timeout = 0
): Promise<[number, any]> {
  return new Promise<[number, any]>((resolve, reject) => {
    // We have to use XHR because fetch() sets the origin header to null
    // in cordova on android, which causes a 403 in the API
    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) return

      if (xhr.status > 0) {
        resolve([xhr.status, xhr.response])
      }
    }
    xhr.open('DELETE', url)
    xhr.timeout = timeout
    xhr.ontimeout = (e) => {
      logError(e, 'request timed out')
      reject(new UserFacingError('Request Timeout'))
    }

    xhr.onerror = (e) => {
      logError(e, 'on error in XMLHttpRequest ' + url)
      // most likely a network error
      reject(new NetworkError('Error sending request'))
    }

    Object.keys(headers).forEach((header) => {
      xhr.setRequestHeader(header, headers[header])
    })
    xhr.responseType = 'json'
    xhr.send(data)
  })
}
