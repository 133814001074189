import * as React from 'react'
import ReactTable, { Column } from 'react-table'
import { IControlProps } from '../types'
import { IDataGridFieldProps, IChecklistField } from '../../../models/Checklist'
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CommentIcon from '@material-ui/icons/Comment'
import PhotoIcon from '@material-ui/icons/PhotoCamera'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { fieldControls } from '../../checklists/utils/basicControls'
import { fieldToControlValue } from '../../checklists/utils/controlValues'
import { fieldToControlProps } from '../../checklists/utils/controlProps'
import { UnsupportedControl } from '../UnsupportedControl'
import { DeepPartial } from '../../../types'
import {
  GridFieldDialog,
  IGridFieldDialogButtons,
  FieldPostSaveAction
} from './GridFieldDialog'
import {
  getDialogButtonsEnabbled,
  getPreviousCellIdx,
  getNextCellIdx
} from './utils'
import { deepCopy } from '../../../utils'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  IOneplaceLibraryContextProp,
  withOneplaceLibraryContext
} from '../../OneplaceLibraryProvider'
import { hasAttachments, hasPhotos } from '../../checklists/utils/attachments'

const styles = (theme: Theme) =>
  createStyles({
    mandatoryQuestion: {
      color: theme.palette.secondary.main
    }
  })

export interface IDynamicDataGridControlProps
  extends IControlProps,
    IOneplaceLibraryContextProp,
    WithStyles<typeof styles> {
  fullName?: string
  dateTimeFormat?: string
}

export interface IDynamicDataGridControlState {
  fieldDialogOpen: boolean
  fieldDialogTitle?: string
  fieldDialogRowIdx?: number
  fieldDialogColIdx?: number
  fieldDialogField?: IChecklistField
  fieldDialogFieldDef?: IChecklistField
  fieldDialogButtons?: IGridFieldDialogButtons
  tableShowPagination: boolean
}

const SUPPORTED_FIELD_TYPES = [
  'checkbox',
  'date',
  'datetime',
  'select',
  'select_many',
  'text',
  'textarea',
  'yesno',
  'number',
  'percentage',
  'signature',
  'timer',
  'subtotal'
]

const DEFAULT_PAGE_SIZE = 100

export default withStyles(styles)(
  withOneplaceLibraryContext(
    class DynamicDataGridControl extends React.Component<
      IDynamicDataGridControlProps,
      IDynamicDataGridControlState
    > {
      columns: Column[]

      constructor(props: any) {
        super(props)
        this.fieldCellOnClick = this.fieldCellOnClick.bind(this)
        this.fieldCell = this.fieldCell.bind(this)
        this.fieldDialogOnSave = this.fieldDialogOnSave.bind(this)
        this.fieldDialogOnClose = this.fieldDialogOnClose.bind(this)
        this.addRow = this.addRow.bind(this)
        this.onDataUpdated = this.onDataUpdated.bind(this)

        const dataGrid = this.getValue()
        const table = dataGrid.values!
        let showPagination = false
        if (table != null && table.length > DEFAULT_PAGE_SIZE) {
          showPagination = true
        }

        this.state = {
          fieldDialogOpen: false,
          tableShowPagination: showPagination
        }

        // configure columns
        this.columns = dataGrid.columnLabels.map(
          (col): Column => {
            const field = col.field!
            const labelClass = field.mandatory
              ? this.props.classes.mandatoryQuestion
              : ''
            const header = (
              <div
                style={{
                  height: '3.2rem',
                  whiteSpace: 'pre-wrap',
                  padding: 5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div role='columnlabel' className={labelClass}>
                  {field.label}
                </div>
              </div>
            )
            return {
              Header: header,
              accessor: String(col.offset - 1), // dataGrid.values indexed from 0 (no row label)
              minWidth: 150,
              Cell: this.fieldCell
            }
          }
        )

        const actionHeader = (
          <div
            style={{
              height: '3.2rem',
              whiteSpace: 'pre-wrap',
              padding: 5,
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div role='columnlabel' className=''>
              {this.props.ctx.i18next.t('dynamic_datagrid_delete_label')}
            </div>
          </div>
        )

        this.columns.push({
          Header: actionHeader,
          accessor: String(this.columns.length),
          minWidth: 150,
          Cell: this.removeLinkCell
        })
      }

      getValue(): IDataGridFieldProps {
        return this.props.field.value
      }

      fieldCellOnClick(rowIdx: number, colIdx: number) {
        const grid = this.getValue()
        const data = grid.values!
        const field = data[rowIdx][colIdx]
        const fieldDef = grid.columnLabels[colIdx].field!
        const buttons = getDialogButtonsEnabbled({
          rowCount: data.length,
          colCount: data[0].length,
          rowIdx,
          colIdx,
          addRowEnabled: true
        })
        this.setState({
          fieldDialogOpen: true,
          fieldDialogTitle:
            this.props.ctx.i18next.t('row') + ' ' + (rowIdx + 1),
          fieldDialogRowIdx: rowIdx,
          fieldDialogColIdx: colIdx,
          fieldDialogField: field,
          fieldDialogFieldDef: fieldDef,
          fieldDialogButtons: buttons
        })
      }

      fieldCell(cellInfo: any) {
        const grid = this.getValue()
        const data = grid.values!
        const rowIdx = Number(cellInfo.index)
        const colIdx = Number(cellInfo.column.id)
        // use react-table  "react-table": "6.8.6", Older v6 version have a bug that
        // breaks this code
        const field = data[rowIdx][colIdx]
        const fieldDef = grid.columnLabels[colIdx].field!

        let Control: React.ComponentType<IControlProps>
        if (!SUPPORTED_FIELD_TYPES.includes(field.type)) {
          Control = UnsupportedControl
        } else {
          Control = fieldControls[field.type]
          if (!Control) {
            Control = UnsupportedControl
          }
        }

        const controlId = `Q${field.id}_${rowIdx}_${colIdx}`
        const controlProps: DeepPartial<IControlProps> = {
          field: {
            name: controlId,
            value: fieldToControlValue(field)
          },
          gridCell: true,
          id: controlId
        }

        // Add field-specific control properties
        fieldToControlProps(fieldDef, controlProps)

        if (fieldDef.type == 'date') {
          controlProps.dateFormat = this.props.dateFormat!
          fieldDef.dateFormat = this.props.dateFormat!
        } else if (fieldDef.type == 'datetime') {
          controlProps.dateTimeFormat = this.props.dateTimeFormat!
          fieldDef.dateTimeFormat = this.props.dateTimeFormat!
        }

        return (
          <div
            id={controlId + '_wrapper'}
            style={{
              height: '100%',
              minHeight: 26,
              cursor: 'pointer',
              display: 'flex'
            }}
            onClick={this.fieldCellOnClick.bind(this, rowIdx, colIdx)}
          >
            <div
              style={{
                flex: '1 1',
                overflow: 'hidden'
              }}
            >
              <Control {...(controlProps as IControlProps)} />
            </div>
            {(field.comment || field.photos) && (
              <div style={{ marginTop: 5, marginRight: 3, color: '#AAA' }}>
                {field.comment && <CommentIcon />}
                {hasPhotos(field.photos!) && <PhotoIcon />}
                {hasAttachments(field.photos!) && <AttachFileIcon />}
              </div>
            )}
          </div>
        )
      }

      removeLinkCell = (cellInfo: any) => {
        const rowIdx = Number(cellInfo.index)
        const controlId = `${rowIdx}`
        return (
          <div
            id={controlId + '_wrapper'}
            style={{
              height: '100%',
              minHeight: 26,
              cursor: 'pointer',
              width: 30,
              textAlign: 'center',
              color: '#AAA'
            }}
            onClick={() => this.removeRow(rowIdx)}
          >
            <DeleteIcon />
          </div>
        )
      }
      fieldDialogOnSave(
        fieldRowIdx: number,
        fieldColIdx: number,
        field: IChecklistField,
        action: FieldPostSaveAction
      ) {
        const data = this.getValue().values!
        console.log('onGridSave', fieldRowIdx, fieldColIdx, field, action)
        data[fieldRowIdx][fieldColIdx] = field
        this.onDataUpdated(data)

        const rowCount = data.length
        const colCount = data[0].length

        if (action == 'previous') {
          const { rowIdx, colIdx } = getPreviousCellIdx({
            rowCount,
            colCount,
            rowIdx: fieldRowIdx,
            colIdx: fieldColIdx
          })
          this.fieldCellOnClick(rowIdx, colIdx)
        } else if (action == 'next') {
          const { rowIdx, colIdx } = getNextCellIdx({
            rowCount,
            colCount,
            rowIdx: fieldRowIdx,
            colIdx: fieldColIdx
          })
          this.fieldCellOnClick(rowIdx, colIdx)
        } else if (action == 'add_row') {
          this.addRow()
          const { rowIdx, colIdx } = getNextCellIdx({
            rowCount: rowCount + 1,
            colCount,
            rowIdx: fieldRowIdx,
            colIdx: fieldColIdx
          })
          this.fieldCellOnClick(rowIdx, colIdx)
        } else {
          this.setState({ fieldDialogOpen: false })
        }
      }

      fieldDialogOnClose() {
        this.setState({ fieldDialogOpen: false })
      }

      addRow() {
        const dataGrid = this.getValue()
        const data = dataGrid.values!
        const newRow: IChecklistField[] = []
        dataGrid.columnLabels.forEach((col) => {
          newRow.push(deepCopy(col.field!))
        })
        data.push(newRow)
        if (data.length > DEFAULT_PAGE_SIZE) {
          this.setState({ tableShowPagination: true })
        }
        this.onDataUpdated(data)
      }

      removeRow = (rowIdx: number) => {
        const dataGrid = this.getValue()
        const data = dataGrid.values!
        if (data.length > 1) {
          // keep at least one row
          data.splice(rowIdx, 1)
          if (data.length <= DEFAULT_PAGE_SIZE) {
            this.setState({ tableShowPagination: false })
          }
          this.onDataUpdated(data)
        }
      }

      onDataUpdated(newData: IChecklistField[][]) {
        const dataGrid = this.getValue()
        const data = [...newData]
        dataGrid.values = data
        this.props.form.setFieldValue(this.props.field.name, dataGrid)
      }

      render(): React.ReactNode {
        const data = this.getValue().values!
        return (
          <Typography component='div'>
            <ReactTable
              columns={this.columns}
              data={data}
              sortable={false}
              showPagination={this.state.tableShowPagination}
              minRows={0}
              defaultPageSize={DEFAULT_PAGE_SIZE}
            />
            <div style={{ marginTop: 12 }}>
              <Button
                variant='contained'
                color='primary'
                style={{ backgroundColor: '#888' }}
                id={this.props.id + '_add_row_button'}
                onClick={this.addRow}
              >
                {this.props.ctx.i18next.t('add_row')}
              </Button>
            </div>
            {this.state.fieldDialogOpen && (
              <GridFieldDialog
                isOpen={this.state.fieldDialogOpen}
                title={this.state.fieldDialogTitle!}
                rowIdx={this.state.fieldDialogRowIdx!}
                colIdx={this.state.fieldDialogColIdx!}
                field={this.state.fieldDialogField!}
                fieldDef={this.state.fieldDialogFieldDef!}
                buttonsEnabled={this.state.fieldDialogButtons!}
                imageStorage={this.props.ctx.imageStorage}
                onSave={this.fieldDialogOnSave}
                onClose={this.fieldDialogOnClose}
                onDeletePhoto={this.props.onDeletePhoto}
                onTicketPreset={this.props.onTicketPreset}
                fullName={this.props.fullName}
                dateTimeFormat={this.props.dateTimeFormat}
                parentId={this.props.fieldId}
                parentType='dynamicdatagrid'
                allowSavedPhotoDeletion={this.props.allowSavedPhotoDeletion}
                allowPhotosFromGallery={this.props.allowPhotosFromGallery}
              />
            )}
          </Typography>
        )
      }
    }
  )
)
