export function getI18nConfig(): any {
  return {
    lng: 'en',
    debug: true,
    fallbackLng: 'en',
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false
    },
    resources: {
      en: {
        common: {
          ok: 'OK',
          save: 'Save',
          save_and_take_another_photo: 'Save & Photo',
          delete: 'Delete',
          cancel: 'Cancel',
          clear: 'Clear',
          add: 'Add',
          close: 'Close',
          new_photo_comment: 'Add New Photo Comment',
          signature: 'Signature',
          start_timer: 'Start Timer',
          start_break: 'Start Break',
          stop_break: 'Finish Break',
          stop_timer: 'Stop Timer',
          ticked: 'Ticked',
          not_ticked: 'Not Ticked',
          previous: 'Previous',
          next: 'Next',
          row: 'Row',
          add_row: 'Add Row',
          saving: 'Saving...',
          comment: 'Comment',
          photos: 'Photos',
          attachments: 'Attachments',
          take_a_photo: 'Take a Photo',
          select_from_gallery: 'Select from Gallery',
          add_signature: 'Add Signature',
          dynamic_datagrid_delete_label: 'Delete',
          dynamic_datagridlist_delete_label: 'Delete List',
          dynamic_datagridlist_add_label: 'Add List',
          initial_photo_comment: 'Photo Comment',
          select: 'Select',
          search: 'Search',
          photo_comment: 'Photo Comment',
          gallery: 'Group Photos',
          add_gallery_photo: 'Add Group Photo',
          validation_errors: 'Validation Errors',
          please_correct_the_following: 'Please correct the following:',
          customLabel_franchisee: 'Franchisee',
          customLabel_franchisees: 'Franchisees',
          customLabel_site: 'Site',
          customLabel_sites: 'Sites',
          question_help: 'Help',
          question_help_image: 'Help Image',
          question_photo: 'Add Photo',
          question_attachment: 'Upload File',
          question_comment: 'Add Comment'
        }
      }
    }
  }
}
