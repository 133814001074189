import * as React from 'react'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { IControlProps } from './types'
import { formatDate } from '../../utils/dates'
import { parseISO, isValid } from 'date-fns'
import { GRID_CELLPADDING, GRID_CELLFONTSIZE } from './grids/consts'
import InputAdornment from '@material-ui/core/InputAdornment'
import EventIcon from '@material-ui/icons/Event'
import { CONFIG } from '../../config'
import DateFnsUtils from '@date-io/date-fns'

export interface IDateControlState {
  value: any
}

export default class DateControl extends React.Component<
  IControlProps,
  IDateControlState
> {
  dateFormat: string
  constructor(props: IControlProps) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    if (props.dateFormat) {
      // eslint-disable-next-line no-useless-escape
      this.dateFormat = props.dateFormat.replace(/\'/gi, '')
    } else {
      this.dateFormat = CONFIG.displayDateFormat
    }
    const initialValue = this.fromStoredValue(this.props.field.value || '')
    if (initialValue == '') {
      this.state = {
        value: null
      }
    } else {
      this.state = {
        value: initialValue
      }
    }
  }

  fromStoredValue(value: string) {
    return value ? parseISO(value) : ''
  }

  toStoredValue(value: string | null) {
    return value ? formatDate('iso_dateonly', value) : ''
  }

  handleChange(date: any) {
    if (date == null || isValid(date)) {
      this.props.form.setFieldValue(
        this.props.field.name,
        this.toStoredValue(date)
      )
    } else {
      this.props.form.setFieldValue(
        this.props.field.name,
        this.toStoredValue(null)
      )
    }
    this.setState({ value: date })
  }

  render() {
    if (this.props.gridCell) {
      const value = this.fromStoredValue(this.props.field.value || '')

      const displayValue = value
        ? formatDate('display_date', value, this.dateFormat)
        : ''

      return (
        <div
          id={this.props.id}
          style={{ padding: GRID_CELLPADDING, fontSize: GRID_CELLFONTSIZE }}
        >
          {displayValue}
        </div>
      )
    } else {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            disabled={this.props.disabled}
            clearable={true}
            value={this.state.value}
            onChange={(date) => this.handleChange(date)}
            format={this.dateFormat}
            id={this.props.id}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <EventIcon />
                </InputAdornment>
              )
            }}
          />
        </MuiPickersUtilsProvider>
      )
    }
  }
}
