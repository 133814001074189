import * as React from 'react'
import { PageLoader } from '../common/PageLoader'
import Lightbox from 'react-image-lightbox'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'
import { TEMP_IMAGE_PREFIX } from '../../data_sources/imagestorage/imageStorage'

export interface IHelpImagesProps extends IOneplaceLibraryContextProp {
  imageUrls: Array<{ url: string }>
  accessToken?: string
  isOpen: boolean
  onClose: () => void
}

export interface IHelpImagesState {
  loadState: 'loading' | 'loaded'
  localImageUrls: string[]
  photoIndex: number
}

export const HelpImages = withOneplaceLibraryContext(
  class extends React.Component<IHelpImagesProps, IHelpImagesState> {
    static displayName = 'HelpImages'
    constructor(props: any) {
      super(props)
      this.state = {
        loadState: 'loading',
        localImageUrls: [],
        photoIndex: 0
      }
      this.loadData()
    }
    async loadData() {
      const imageDataPromises = this.props.imageUrls.map((helpLink) => {
        if (this.props.accessToken) {
          return this.props.ctx.client.getLocalAssetUrl('helpimg', helpLink.url)
        } else {
          return this.props.ctx.client.getLocalAssetUrl(
            'helpimg',
            helpLink.url,
            this.props.accessToken
          )
        }
      })

      const templocalImageUrls = await Promise.all(imageDataPromises)
      const localImageUrls: string[] = []
      templocalImageUrls.forEach((field: string) => {
        console.log('before' + field)
        if (field.includes(TEMP_IMAGE_PREFIX)) {
          field = field.substr(TEMP_IMAGE_PREFIX.length)
        }
        localImageUrls.push(field)
      })
      console.log(localImageUrls)
      this.setState({
        loadState: 'loaded',
        localImageUrls
      })
    }
    render() {
      // make sure help image overlay is on the top
      const modalStyles = { overlay: { zIndex: 1000000 } }
      return (
        <div>
          {this.state.loadState != 'loaded' && (
            <div style={{ minWidth: 175 }}>
              <PageLoader loading={true} />
            </div>
          )}
          {this.state.loadState == 'loaded' &&
            this.state.localImageUrls.length > 0 && (
              <Lightbox
                reactModalStyle={modalStyles}
                mainSrc={this.state.localImageUrls[this.state.photoIndex]}
                nextSrc={
                  this.state.localImageUrls[
                    (this.state.photoIndex + 1) %
                      this.state.localImageUrls.length
                  ]
                }
                prevSrc={
                  this.state.localImageUrls[
                    (this.state.photoIndex +
                      this.state.localImageUrls.length -
                      1) %
                      this.state.localImageUrls.length
                  ]
                }
                onCloseRequest={this.props.onClose}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (this.state.photoIndex +
                        this.state.localImageUrls.length -
                        1) %
                      this.state.localImageUrls.length
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex:
                      (this.state.photoIndex + 1) %
                      this.state.localImageUrls.length
                  })
                }
              />
            )}
        </div>
      )
    }
  }
)
export default HelpImages
