import * as React from 'react'
import Paper from '@material-ui/core/Paper'

export const PhotoWrapper: React.SFC<{}> = (props) => (
  <Paper
    square
    elevation={1}
    style={{
      margin: 4,
      overflow: 'hidden',
      width: 60,
      height: 60,
      cursor: 'pointer'
    }}
  >
    {props.children}
  </Paper>
)
