import * as React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Badge from '@material-ui/core/Badge'
import { StickyContainer, Sticky } from 'react-sticky'
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    badgeItem: {
      padding: '0 20px'
    },
    tabsCompact: {
      minWidth: 'initial',
      background: '#5567a8',
      opacity: 1
    },
    tabsLight: {
      background: theme.palette.primary.light,
      color: '#fff'
    },
    tabsNormal: {
      background: theme.palette.primary.main
    },
    tabsDark: {
      background: theme.palette.primary.dark
    }
  })

export interface ITabComponentContext {
  nextTab: () => void
  previousTab: () => void
  notifyUpdate: (forceScroll?: boolean) => void
  nextTabDisabled: boolean
  previousTabDisabled: boolean
}

export interface ITabsContextProp {
  tabs: ITabComponentContext
}

export const TabsContext = React.createContext<ITabComponentContext>(
  null as any
)

export interface ITabDefinition {
  name: string
  label: string | React.ReactElement<any>
  badgeContent?: string
  component: React.ReactElement<any>
  hideTab?: boolean
}

export interface ITabsProps extends WithStyles<typeof styles> {
  color?: 'light' | 'dark' | 'normal'
  compact?: boolean
  tabs: ITabDefinition[]
  initialTabIdx?: number
  rememberScrollPosition?: boolean
  onClickTab?: (tabName: string) => void
  hasDetailsTab: boolean
  disabled?: boolean
  stickyTabOffset?: number
}

export interface ITabsState {
  selectedTab: number
  autoScrolled: boolean
  disabled: boolean
}

const TabsComponent = withStyles(styles)(
  class extends React.Component<ITabsProps, ITabsState> {
    static displayName = 'TabsComponent'
    tabScrollYValues: { [tabName: string]: number } = {}

    constructor(props: any) {
      super(props)
      this.state = {
        selectedTab: this.props.initialTabIdx || 0,
        autoScrolled: false,
        disabled: this.props.disabled || false
      }

      if (this.props.rememberScrollPosition) {
        this.tabScrollYValues[this.props.tabs[this.state.selectedTab].name] =
          window.scrollY
      }
    }

    handleChange = (_event: any, value: any) => {
      if (this.props.rememberScrollPosition) {
        this.tabScrollYValues[this.props.tabs[this.state.selectedTab].name] =
          window.scrollY
      }
      this.setState({
        selectedTab: value,
        autoScrolled: false
      })
      const tab = this.props.tabs[value]
      if (this.props.onClickTab) {
        this.props.onClickTab(tab.name)
      }
    }

    nextTab = () => {
      if (this.state.selectedTab < this.props.tabs.length - 1) {
        this.setState({
          selectedTab: this.state.selectedTab + 1,
          autoScrolled: false
        })
      }
    }

    prevousTab = () => {
      if (this.state.selectedTab > 0) {
        this.setState({
          selectedTab: this.state.selectedTab - 1,
          autoScrolled: false
        })
      }
    }

    notifyUpdate = (forceScroll?: boolean) => {
      if (this.props.rememberScrollPosition) {
        const tabName = this.props.tabs[this.state.selectedTab].name
        const lastScrollY = this.tabScrollYValues[tabName] || 0

        if (lastScrollY && !this.state.autoScrolled) {
          // We should only scroll when enough of the page has loaded
          // so that the browser can scroll to the correct location.
          // This means the document must be longer than the last scroll position
          // plus the current viewport height (or all of the questions must have
          // loaded)
          const scrollAtSize = lastScrollY + window.innerHeight
          if (document.body.offsetHeight >= scrollAtSize || forceScroll) {
            console.log('Tabs AutoScroll to', lastScrollY)
            setTimeout(() => {
              // delay scroll so the DOM has time to update
              window.scrollTo(0, lastScrollY)
            }, 100)
            this.setState({
              autoScrolled: true
            })
          }
        }
      }
    }

    componentWillReceiveProps = (nextProps: ITabsProps) => {
      this.setState({
        disabled: nextProps.disabled ? nextProps.disabled : false
      })
    }

    render() {
      const selectedTab = this.props.tabs[this.state.selectedTab]
      const tabsClass =
        this.props.color == 'dark'
          ? this.props.classes.tabsDark
          : this.props.color == 'light'
          ? this.props.classes.tabsLight
          : this.props.classes.tabsNormal

      const narrowWidth = 600
      const fixedOffset = window.innerWidth < narrowWidth ? 48 : 64

      const offset =
        this.props.stickyTabOffset !== undefined
          ? this.props.stickyTabOffset
          : fixedOffset

      const topOffset =
        this.props.stickyTabOffset !== undefined
          ? this.props.stickyTabOffset
          : -64

      return (
        <div className={this.props.classes.root}>
          <StickyContainer>
            <Sticky topOffset={topOffset}>
              {(sProps: any) => (
                <div style={{ ...sProps.style, top: offset, zIndex: 100 }}>
                  <AppBar position='static' className={tabsClass}>
                    <Tabs
                      value={this.state.selectedTab}
                      onChange={this.handleChange}
                      variant='scrollable'
                    >
                      {this.props.tabs
                        .filter((tab) => !tab.hideTab)
                        .map((tab, idx) => {
                          const tabProps: any = {
                            key: idx,
                            label: tab.label,
                            disabled: this.state.disabled
                          }
                          if (this.props.compact) {
                            tabProps.className = this.props.classes.tabsCompact
                          }
                          if (typeof tab.badgeContent !== 'undefined') {
                            tabProps.label = (
                              <Badge
                                overlap='rectangular'
                                color='secondary'
                                className={this.props.classes.badgeItem}
                                badgeContent={tab.badgeContent}
                              >
                                {tab.label}
                              </Badge>
                            )
                          }
                          return <Tab key={idx} {...tabProps} />
                        })}
                    </Tabs>
                  </AppBar>
                </div>
              )}
            </Sticky>
            <TabsContext.Provider
              value={{
                nextTab: this.nextTab,
                previousTab: this.prevousTab,
                notifyUpdate: this.notifyUpdate,
                nextTabDisabled:
                  this.state.disabled ||
                  this.state.selectedTab ==
                    this.props.tabs.filter(
                      (tab) =>
                        tab.component.props.renderProps &&
                        tab.component.props.renderProps.showTab
                    ).length -
                      (this.props.hasDetailsTab ? 0 : 1),
                previousTabDisabled:
                  this.state.disabled || this.state.selectedTab == 0
              }}
            >
              {selectedTab.component}
            </TabsContext.Provider>
          </StickyContainer>
        </div>
      )
    }
  }
)

export function withTabsContext<TComponentProps extends ITabsContextProp>(
  Component: React.ComponentType<TComponentProps>
) {
  return function AppcontextComponent(
    props: Pick<
      TComponentProps,
      Exclude<keyof TComponentProps, keyof ITabsContextProp>
    >
  ) {
    return (
      <TabsContext.Consumer>
        {(tabs) => <Component {...(props as TComponentProps)} tabs={tabs} />}
      </TabsContext.Consumer>
    )
  }
}
export default TabsComponent
