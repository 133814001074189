import * as EXIF from 'exif-js'
import fileType from 'get-file-type-es5'
import { IPhoto } from '../../../models/Checklist'
import { IImageStorage } from '../../../data_sources/imagestorage/imageStorage'

export interface IExifData {
  ImageWidth: number
  ImageHeight: number
  Orientation: number
  DateTime: string
  DateTimeOriginal: string
}

export function getImageFromUrl(objectUrl: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = objectUrl
    image.onload = () => {
      resolve(image)
    }
    image.onerror = (_el) => {
      reject(
        new Error('unable to load image.  We only support jpg and png images.')
      )
    }
  })
}

export async function getExifData(imageData: Blob): Promise<any> {
  return new Promise<IExifData>((resolve, reject) => {
    try {
      EXIF.getData(imageData, () => {
        resolve((imageData as any).exifdata)
      })
    } catch (err) {
      reject(new Error('getExifData() error'));
    }
    setTimeout(() => reject(new Error('getExifData() failed')), 2000)
  })
}

export async function transformImageData(
  imageData: Blob,
  imageType: string,
  resolution: number,
  quality: number,
  _orientation = 0
): Promise<Blob> {
  // Create a temporary image so that we can compute the dimensions of the current image.
  const dataUrl = window.URL.createObjectURL(imageData)
  const image = await getImageFromUrl(dataUrl)
  const imgWidth = image.naturalWidth
  const imgHeight = image.naturalHeight
  window.URL.revokeObjectURL(dataUrl)
  console.log('dims', imgWidth, imgHeight)

  const longestDimension = imgWidth > imgHeight ? 'width' : 'height'
  const currentRes = longestDimension == 'width' ? imgWidth : imgHeight
  console.log('longest dim', longestDimension, currentRes)

  let canvasWidth = imgWidth
  let canvasHeight = imgHeight

  if (currentRes > resolution) {
    console.log('need to resize...')
    // Calculate new dimensions
    const newSize =
      longestDimension == 'width'
        ? Math.floor((imgHeight / imgWidth) * resolution)
        : Math.floor((imgWidth / imgHeight) * resolution)
    canvasWidth = longestDimension == 'width' ? resolution : newSize
    canvasHeight = longestDimension == 'height' ? resolution : newSize
    console.log('new canvas width / height', canvasWidth, canvasHeight)
  }

  // Create a temporary canvas to draw the image on.
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')!
  canvas.width = canvasWidth
  canvas.height = canvasHeight
  ctx.save()

  // Apply orientation (https://github.com/koba04/canvas-exif-orientation/blob/master/index.js)
  /**
   * It seems we don't need this anymore as the default behaviour for browsers now is to read exif data and rotate the image.
   * Using this code bellow will make the browser rotate the image again and display it wrong. Tested with different images
   * from https://github.com/recurser/exif-orientation-examples and all images were uploaded correctly. Tested using camera
   * (native and html) and it worked ok as well. Keeping the code here for a while until we make sure it's everything working ok.
   * See:
   * https://stackoverflow.com/questions/42401203/chrome-image-exif-orientation-issue
   * https://stackoverflow.com/questions/61390195/exif-orientation-issue-in-safari-mobile
   */
  /*switch (orientation) {
    case 1:
      break
    case 2:
      ctx.translate(canvasWidth, 0)
      ctx.scale(-1, 1)
      break
    case 3:
      ctx.translate(canvasWidth, canvasHeight)
      ctx.rotate((180 / 180) * Math.PI)
      break
    case 4:
      ctx.translate(0, canvasHeight)
      ctx.scale(1, -1)
      break
    case 5:
      canvas.width = canvasHeight
      canvas.height = canvasWidth
      ctx.rotate((90 / 180) * Math.PI)
      ctx.scale(1, -1)
      break
    case 6:
      canvas.width = canvasHeight
      canvas.height = canvasWidth
      ctx.rotate((90 / 180) * Math.PI)
      ctx.translate(0, -canvasHeight)
      break
    case 7:
      canvas.width = canvasHeight
      canvas.height = canvasWidth
      ctx.rotate((270 / 180) * Math.PI)
      ctx.translate(-canvasWidth, canvasHeight)
      ctx.scale(1, -1)
      break
    case 8:
      canvas.width = canvasHeight
      canvas.height = canvasWidth
      ctx.translate(0, canvasWidth)
      ctx.rotate((270 / 180) * Math.PI)
      break
  }*/

  // Draw the downscaled image on the canvas
  ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight)
  ctx.restore()

  // Extract the image data as a blob and create a new objectUrl
  const newData = await new Promise<Blob>((resolve) => {
    canvas.toBlob((blob) => resolve(blob!), imageType, quality)
  })
  return newData
}

export function convertExifDate(date: string): string {
  // Converts Exif date format to ISO (YYYY-MM-DDTHH:mm:SS)
  if (!date) {
    return ''
  }
  const exifDateMatcher = /(\d{4}):(\d{2}):(\d{2}) (\d{2}):(\d{2}):(\d{2})/
  // const matches = date.match(exifDateMatcher)
  const matches = exifDateMatcher.exec(date)
  if (matches) {
    const [
      ,
      // ","" = ignore first match
      year,
      month,
      day,
      hour,
      minute,
      second
    ] = matches
    return `${year}-${month}-${day}T${hour}:${minute}:${second}`
  }
  return ''
}

export interface IFileData {
  file: Blob
  fileType: any | undefined
}

export function convertBase64Image(base64Image: any) {
  // SPLIT INTO TWO PARTS
  // const parts = base64Image.split(';base64,');
  // HOLD THE CONTENT TYPE
  // const imageType = parts[0].split(':')[1];
  // DECODE BASE64 STRING
  const decodedData = window.atob(base64Image)
  // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
  const uInt8Array = new Uint8Array(decodedData.length)

  // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i)
  }
  const file = fileType(uInt8Array)
  let fileObj
  if (file) {
    fileObj = { type: file.mime }
  }
  const fileImage: IFileData = {
    fileType: file,
    // RETURN BLOB IMAGE AFTER CONVERSION
    file: new Blob([uInt8Array], fileObj)
  }
  return fileImage
}

export async function makePhotosPermanent(
  photos: IPhoto[],
  imageStorage: IImageStorage,
  reloadImageObjectUrls: boolean
) {
  for (const photo of photos) {
    const [storedUrl, storedThumbnailUrl] = await Promise.all([
      imageStorage.makePermenant(photo.url),
      imageStorage.makePermenant(photo.thumbnailUrl)
    ])
    photo.url = storedUrl
    photo.thumbnailUrl = storedThumbnailUrl
    if (reloadImageObjectUrls) {
        const imgObjectUrl = await imageStorage.getImageObjectUrl(photo.url, reloadImageObjectUrls);
        const imgThumbnailObjectUrl = await imageStorage.getImageObjectUrl(photo.thumbnailUrl, reloadImageObjectUrls);
        photo.localObjectUrl = imgObjectUrl;
        photo.localThumbnaiObjectlUrl = imgThumbnailObjectUrl;
    }
  }
}

export function getRandomFileName(): string {
  return Math.random().toString(30).substring(5)
}
