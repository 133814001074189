import * as React from 'react'
import IconButton from '@material-ui/core/IconButton'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { withOneplaceLibraryContext } from '../OneplaceLibraryProvider'
import { IAddAttachmentButtonProps } from './AddAttachmentButton'
import { IAttachment } from '../../models/Checklist'
import { CONFIG } from '../../config'
import { BasicDialog } from '../common/BasicDialog'
import DialogContentText from '@material-ui/core/DialogContentText'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

const styles = () =>
  createStyles({
    listItemText: {
      display: 'inline-block'
    },
    label: {
      width: 48,
      height: 48
    },
    uploadLabel: {
      width: '100%',
      height: '100%',
      cursor: 'pointer'
    },
    uploadButtonText: {
      position: 'absolute',
      textAlign: 'left',
      fontSize: 16,
      left: 48,
      top: 14,
      width: 200
    },
    uploadInput: {
      opacity: 0,
      position: 'absolute',
      zIndex: -10,
      right: 0
    }
  })

export interface IAddAttachmentButtonWebProps
  extends IAddAttachmentButtonProps,
    WithStyles<typeof styles> {}

export interface IAddAttachmentButtonWebState {
  showOfflineMessage: boolean
}
export const AddAttachmentButtonWeb = withStyles(styles)(
  withOneplaceLibraryContext(
    class extends React.Component<
      IAddAttachmentButtonWebProps,
      IAddAttachmentButtonWebState
    > {
      static displayName = 'AddAttachmentButtonWeb'
      private fileInputRef: React.RefObject<HTMLInputElement>
      private acceptedTypes: string[] = [
        'application/pdf',
        'application/rtf',
        'text/plain',
        'text/csv',
        'application/msword',
        'application/vnd.ms-powerpoint',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'image/jpeg',
        'image/png',
        'image/gif',
        'application/vnd.oasis.opendocument.text',
        'application/vnd.oasis.opendocument.spreadsheet',
        'application/vnd.oasis.opendocument.presentation'
      ]

      constructor(props: any) {
        super(props)
        this.fileInputRef = React.createRef()
        this.state = { showOfflineMessage: false }
      }

      takeFiles = (event: any) => {
        const files: FileList = event.target.files
        if (files && files[0]) {
          const attachments: IAttachment[] = []

          for (let i = 0; i < files.length; i++) {
            const file = files[i]
            let maxSizeExceeded = false
            if (
              file.size &&
              file.size / 1024 / 1024 > CONFIG.attachmentMaxSize
            ) {
              maxSizeExceeded = true
            }

            attachments.push({
              url: null,
              id: null,
              localObjectUrl: maxSizeExceeded
                ? null
                : URL.createObjectURL(file),
              file: file,
              fileName: file.name,
              fileSize: file.size,
              contentType: file.type,
              status: maxSizeExceeded ? 'Canceled' : null,
              uuid: null,
              fileSizeExceeded: maxSizeExceeded
            })
          }
          this.props.onAttachmentsSelected(attachments)
        }
        if (this.fileInputRef.current) {
          /*this allows the same file to be uploaded twice
          (chrome only add the file when the field changes)*/
          this.fileInputRef.current.value = ''
        }
      }

      render(): React.ReactNode {
        const iconOnly =
          this.props.standalone != null ? this.props.standalone : true
        return (
          <React.Fragment>
            {iconOnly ? (
              <label htmlFor={this.props.controlId + '-attachment-upload'}>
                <IconButton
                  id={this.props.controlId + '-attachmentbutton'}
                  className={this.props.className}
                  role='button'
                  onClick={() => {
                    if (window.navigator && window.navigator.onLine === false) {
                      this.setState({ showOfflineMessage: true })
                    } else {
                      this.fileInputRef.current?.click()
                    }
                  }}
                >
                  <AttachFileIcon />
                </IconButton>
              </label>
            ) : (
              <MenuItem
                id={this.props.controlId + '-attachmentbutton'}
                className={this.props.className}
                onClick={() => {
                  if (window.navigator && window.navigator.onLine === false) {
                    this.setState({ showOfflineMessage: true })
                  } else {
                    this.fileInputRef.current?.click()
                  }
                }}
              >
                <ListItemIcon className={this.props.className}>
                  <AttachFileIcon />
                </ListItemIcon>
                <ListItemText
                  primary={this.props.ctx.i18next.t('question_attachment')}
                />
              </MenuItem>
            )}

            <input
              ref={this.fileInputRef}
              type='file'
              multiple
              accept={this.acceptedTypes.join(', ')}
              id={this.props.controlId + '-attachment-upload'}
              className={this.props.classes.uploadInput}
              onChange={this.takeFiles}
            />
            <BasicDialog
              content={
                <DialogContentText>
                  You appear to be offline. Please not that in order to attach
                  files you need to have an internet connection.
                </DialogContentText>
              }
              title={'Network Status'}
              disableBackdropClick={true}
              isOpen={this.state.showOfflineMessage}
              onClose={() => {
                this.setState({ showOfflineMessage: false })
                this.fileInputRef.current?.click()
              }}
            />
          </React.Fragment>
        )
      }
    }
  )
)
