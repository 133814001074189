import * as React from 'react'
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'
import AddAttachmentButton from './AddAttachmentButton'
import { AttachmentsPanel } from './AttachmentsPanel'
import AttachmentDialog from './AttachmentDialog'
import { IAttachment } from '../../models/Checklist'

const styles = (_theme: Theme) =>
  createStyles({
    dividerWrapper: {
      marginTop: 16,
      marginBottom: 16
    },
    galleryHeading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  })

export interface IAttachmentsProps
  extends WithStyles<typeof styles>,
    IOneplaceLibraryContextProp {
  attachments: IAttachment[]
  onAttachmentsSelected: (attachments: IAttachment[]) => void
  onAttachmentDeleted: (attachment: IAttachment) => void
  onCommentAdded: (attachment: IAttachment, newComment: string) => void
  attachmentEntity: 'checklist' | 'incident'
}

export const Attachments = withStyles(styles)(
  withOneplaceLibraryContext(
    (
      props: IAttachmentsProps
    ): React.FunctionComponentElement<IAttachmentsProps> => {
      const [attachmentDialogOpen, setAttachmentDialogOpen] = React.useState(false)
      const [dialogAttachment, setDialogAttachment] =
        React.useState<IAttachment | null>()

      const onAttachmentClicked = (attachment: IAttachment) => {
        if (attachment.url) {
          setAttachmentDialogOpen(true)
          setDialogAttachment(attachment)
        }
      }

      return (
        <>
          <div className={props.classes.galleryHeading}>
            <Typography variant='subtitle1' style={{ fontSize: 18 }}>
              Attachments
            </Typography>
            <AddAttachmentButton
              platform={props.ctx.env.platform}
              controlId={''}
              className={''}
              onAttachmentsSelected={props.onAttachmentsSelected}
            />
          </div>
          <FormControl fullWidth style={{ marginBottom: 10, marginTop: 0 }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <AttachmentsPanel
                attachments={props.attachments}
                onAttachmentClicked={onAttachmentClicked}
              />
            </div>
          </FormControl>
          {attachmentDialogOpen && (
            <AttachmentDialog
              attachmentEntity={props.attachmentEntity}
              isOpen={true}
              attachment={dialogAttachment!}
              onClose={() => {
                setAttachmentDialogOpen(false)
                setDialogAttachment(null)
              }}
              onDelete={(att) => {
                props.onAttachmentDeleted(att)
                setAttachmentDialogOpen(false)
                setDialogAttachment(null)
              }}
              onAddComment={(newComment) => {
                props.onCommentAdded(dialogAttachment!, newComment)
                setAttachmentDialogOpen(false)
                setDialogAttachment(null)
              }}
            />
          )}
        </>
      )
    }
  )
)
