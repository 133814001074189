import Done from '@material-ui/icons/Done'
import * as React from 'react'

export interface CheckCircleCustomColorProps {
  color?: string
  background?: string
  size?: number
  iconSize?: number
}

const CheckCircleCustomColor = ({
  color = '#ffffff',
  background = '#ff5983',
  size = 20,
  iconSize = size - 4
} : CheckCircleCustomColorProps): JSX.Element => {
  return (
    <div
      style={{
        backgroundColor: background,
        width: size,
        height: size,
        borderRadius: size,
        marginLeft: 6
      }}
    >
      <Done htmlColor={color} style={{ width: iconSize, height: iconSize }} />
    </div>
  )
}
export default CheckCircleCustomColor
