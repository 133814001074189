import { CONFIG } from '../../../config'
import { IPhoto, IImageStorage, IEnvironment } from '../../..'
import { processPhoto } from './processPhoto'
import { logError } from '../../../logging'
import { convertBase64Image, getRandomFileName } from './imageUtils'

function getCDVFileError(method: string, error: any): Error {
  return new Error(
    `${method} failed. Code ` +
      ((typeof error == 'object' && error.code) || 'unknown')
  )
}

function getCordovaImageOptions(): CameraOptions {
  return {
    destinationType: Camera.DestinationType.FILE_URI,
    encodingType: Camera.EncodingType.JPEG,
    targetWidth: CONFIG.imageMaxResolution,
    targetHeight: CONFIG.imageMaxResolution,
    correctOrientation: true,
    cameraDirection: Camera.Direction.BACK
  }
}

function getCordovaPhotoFile(
  sourceType: number,
  saveToPhotoAlbum: boolean
): Promise<string | null> {
  return new Promise<string | null>((resolve, reject) => {
    navigator.camera.getPicture(
      (imagePath) => {
        resolve(imagePath)
      },
      (error: string) => {
        if (error == 'No Image Selected') {
          resolve(null)
        } else {
          reject(new Error(error))
        }
      },
      {
        ...getCordovaImageOptions(),
        sourceType,
        saveToPhotoAlbum
      }
    )
  })
}

export function getPhotoFileFromCamera(
  saveToPhotoAlbum: boolean
): Promise<string | null> {
  if (!navigator.camera) {
    alert('Take Photo currently supported in the mobile app only.')
    throw new Error('Take Photo currently supported in the mobile app only')
  }
  return getCordovaPhotoFile(Camera.PictureSourceType.CAMERA, saveToPhotoAlbum)
}

export function getPhotoFileFromGallery(): Promise<string | null> {
  if (!navigator.camera) {
    alert('Select from Gallery currently supported in the mobile app only.')
    throw new Error(
      'Select from Gallery currently supported in the mobile app only.'
    )
  }
  return getCordovaPhotoFile(Camera.PictureSourceType.PHOTOLIBRARY, false)
}

export function getPhotoData(filePath: string): Promise<Blob> {
  return new Promise<Blob>((resolve, reject) => {
    window.resolveLocalFileSystemURL(
      filePath,
      (entry: any) => {
        console.log('got file entry', entry)
        entry.file(
          (file: any) => {
            console.log('got file')
            const reader = new FileReader()
            reader.onloadend = () => {
              console.log('File read complete. Returning Blob...')
              const data = new Blob([new Uint8Array(reader.result as any)], {
                type: 'image/jpeg'
              })
              resolve(data)
            }
            reader.onerror = () => {
              reject(new Error('FileReader.read() failed.'))
            }
            reader.readAsArrayBuffer(file)
          },
          (error: any) => {
            reject(getCDVFileError('file()', error))
          }
        )
      },
      (error: any) => {
        reject(getCDVFileError('resolveLocalFileSystemURL()', error))
      }
    )
  })
}

export async function takeFromCamera(
  addPhotosToGallery: boolean,
  imageStorage: IImageStorage,
  env: IEnvironment
): Promise<IPhoto | null> {
  try {
    const photoFile = await getPhotoFileFromCamera(addPhotosToGallery)
    if (photoFile) {
      let photo: IPhoto
      if (env.platform == 'cordova' && env.os == 'browser') {
        const photoData = convertBase64Image(photoFile)
        console.log('got photo data', photoData.fileType)
        const randomString = getRandomFileName()
        let ext = ''
        if (photoData.fileType) ext = '.' + photoData.fileType.ext
        const fileName = randomString + ext
        photo = await processPhoto(photoData.file, fileName, imageStorage)
      } else {
        // Extract filename from file://aaa/bbb/c.jpg?12345
        let fileName = photoFile.split('?')[0].split('/').slice(-1)[0]
        const ext = fileName.substring(
          fileName.lastIndexOf('.'),
          fileName.length
        )
        // add a random string to the file name to avoid duplicate file name problem
        const randomString = getRandomFileName()
        fileName =
          fileName.substring(0, fileName.lastIndexOf('.')) +
          '_' +
          randomString +
          ext
        const photoData = await getPhotoData(photoFile)
        console.log('got photo data', photoData)
        photo = await processPhoto(photoData, fileName, imageStorage)
      }
      console.log('photo processed')
      photo.attachmentType = 'photo'
      photo.source = 'camera'
      return photo
    }
  } catch (e) {
    logError(e, 'Error while processing image from camera')
  }
  return null
}
