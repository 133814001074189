export interface IControlColor {
  background: string
  foreground: string
}

export const COLOURS: { [name: string]: IControlColor } = {
  white: {
    background: 'white',
    foreground: 'black'
  },
  red: {
    background: '#ED1C24',
    foreground: 'white'
  },
  purple: {
    background: 'purple',
    foreground: 'white'
  },
  pink: {
    background: '#ED1E79',
    foreground: 'white'
  },
  orange: {
    background: '#F7931E',
    foreground: 'white'
  },
  yellow: {
    background: '#FADB4E',
    foreground: 'black'
  },
  green: {
    background: '#51A351',
    foreground: 'white'
  },
  blue: {
    background: '#2962B1',
    foreground: 'white'
  },
  grey: {
    background: 'grey',
    foreground: 'white'
  },
  brown: {
    background: 'brown',
    foreground: 'white'
  },
  black: {
    background: 'black',
    foreground: 'white'
  }
}
