import {
  IChecklist,
  IChecklistRenderProps,
  IFieldRenderProps,
  IAddRenderPropsParams
} from '../../../models/Checklist'

import { validateGroups } from './validateChecklist'
import { IAttribute } from '../../../models/Franchise'

function matchAttribute(attr1: IAttribute[], attr2: IAttribute[]) {
  return attr1.find((a1) => Boolean(attr2.find((a2) => a2.id === a1.id)))
}

export function getRenderProps(
  params: IAddRenderPropsParams
): IChecklistRenderProps {
  let nextNumber = 1
  const renderProps: IChecklistRenderProps = { groups: [] }
  params.template.groups.forEach((group, groupIdx) => {
    const groupProps = { fields: [], incompleteFields: false, showTab: true }
    renderProps.groups[groupIdx] = groupProps
    if (params.template.useTabs) {
      nextNumber = 1
    }
    group.fields.forEach((question, questionIdx) => {
      const questionProps: IFieldRenderProps = {
        hidden: false
      }
      renderProps.groups[groupIdx].fields[questionIdx] = questionProps
      if (
        params.assigneeType === 'franchisee' &&
        question.franchiseeAttributes &&
        question.franchiseeAttributes.length
      ) {
        const franAttribs = params.assigneeAttributes
        if (franAttribs) {
          if (
            !franAttribs.length ||
            !matchAttribute(franAttribs, question.franchiseeAttributes)
          ) {
            questionProps.hidden = true
            question.showQuestion = false
          }
        }
      } else if (
        params.assigneeType === 'site' &&
        question.siteAttributes &&
        question.siteAttributes.length
      ) {
        const siteAttribs = params.assigneeAttributes
        if (siteAttribs) {
          if (
            !siteAttribs.length ||
            !matchAttribute(siteAttribs, question.siteAttributes)
          ) {
            questionProps.hidden = true
            question.showQuestion = false
          }
        }
      }
      if (
        !questionProps.hidden &&
        question.type !== 'conditionalRule' &&
        question.type !== 'information' &&
        question.type !== 'subtotal'
      ) {
        questionProps.questionNumber = `Q${nextNumber}`
        nextNumber++
      }
    })
  })
  validateGroups(params.template, renderProps)
  return renderProps
}
// update question numbers for conditional questions, ignore hidden questions
export function updateQuestionNumbers(
  template: IChecklist,
  renderProps: IChecklistRenderProps
): IChecklistRenderProps {
  let nextNumber = 1
  template.groups.forEach((group, groupIdx) => {
    if (template.useTabs || groupIdx !== 0) {
      nextNumber = 1
    }
    group.fields.forEach((question, questionIdx) => {
      if (
        !question.hidden &&
        question.type !== 'conditionalRule' &&
        question.type !== 'information' &&
        question.type !== 'subtotal'
      ) {
        const newQuestionNumber = `Q${nextNumber}`
        if (
          newQuestionNumber !==
          renderProps.groups[groupIdx].fields[questionIdx].questionNumber
        ) {
          // questio number changed, we need to rerender the question
          renderProps.groups[groupIdx].fields[questionIdx].questionNumber =
            newQuestionNumber
          renderProps.groups[groupIdx].fields[questionIdx].questionNeedRefresh =
            true
        } else {
          renderProps.groups[groupIdx].fields[questionIdx].questionNeedRefresh =
            false
        }
        nextNumber++
      }
    })
  })
  return renderProps
}
