import { IGridFieldDialogButtons } from './GridFieldDialog'

export function getDialogButtonsEnabbled(params: {
  rowCount: number
  colCount: number
  rowIdx: number
  colIdx: number
  addRowEnabled?: boolean
}) {
  const { rowCount, colCount, rowIdx, colIdx, addRowEnabled } = params
  const buttons: IGridFieldDialogButtons = {
    previous: false,
    next: false,
    addRow: false
  }
  const maxColIdx = colCount - 1
  const maxRowIdx = rowCount - 1
  if (colIdx > 0 || (colIdx == 0 && rowIdx > 0)) {
    buttons.previous = true
  }
  if (colIdx < maxColIdx || (colIdx == maxColIdx && rowIdx < maxRowIdx)) {
    buttons.next = true
  }
  if (addRowEnabled && colIdx == maxColIdx && rowIdx == maxRowIdx) {
    buttons.addRow = true
  }
  return buttons
}

export function getPreviousCellIdx(params: {
  rowCount: number
  colCount: number
  rowIdx: number
  colIdx: number
}): { rowIdx: number; colIdx: number } {
  const { colCount, rowIdx, colIdx } = params
  if (rowIdx == 0 && colIdx == 0) {
    return { rowIdx, colIdx }
  } else if (colIdx == 0 && rowIdx > 0) {
    return { rowIdx: rowIdx - 1, colIdx: colCount - 1 }
  }
  return { rowIdx, colIdx: colIdx - 1 }
}

export function getNextCellIdx(params: {
  rowCount: number
  colCount: number
  rowIdx: number
  colIdx: number
}): { rowIdx: number; colIdx: number } {
  const { rowCount, colCount, rowIdx, colIdx } = params
  const maxColIdx = colCount - 1
  const maxRowIdx = rowCount - 1

  if (rowIdx == maxRowIdx && colIdx == maxColIdx) {
    return { rowIdx, colIdx } // Add row handled seperately
  } else if (colIdx == maxColIdx && rowIdx < maxRowIdx) {
    return { rowIdx: rowIdx + 1, colIdx: 0 }
  }
  return { rowIdx, colIdx: colIdx + 1 }
}
