import * as React from 'react'

import { IPhoto } from '../../models/Checklist'
import {
  DialogActions,
  Button,
  Link,
  DialogContent,
  Dialog,
  DialogTitle,
  TextField
} from '@material-ui/core'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'
import { NotFoundError } from '../../errors/NotFoundError'
import { createAttachment, isAttachment } from '../checklists/utils/attachments'
import { IAttachment } from '../..'

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      minWidth: '250px'
    },
    fileLink: {
      marginRight: '5px'
    },
    errorMessage: {
      color: theme.palette.secondary.main
    }
  })

export interface IAttachmentDialogProps
  extends WithStyles<typeof styles>,
    IOneplaceLibraryContextProp {
  attachment: IPhoto | IAttachment
  isOpen: boolean
  onClose: () => void
  onDelete: (attachment: IAttachment) => void
  onAddComment?: (comment: string) => void
  attachmentEntity: 'checklist' | 'incident'
}

export interface IAttachmentDialogState {
  isDeleting: boolean
  errorMessage: string | null
  comment: string
}

export default withStyles(styles)(
  withOneplaceLibraryContext(
    class AttachmentDialog extends React.Component<
      IAttachmentDialogProps,
      IAttachmentDialogState
    > {
      constructor(props: IAttachmentDialogProps) {
        super(props)
        this.state = {
          isDeleting: false,
          errorMessage: null,
          comment: props.attachment.comment || ''
        }
      }

      onDelete = () => {
        const attachment = isAttachment(this.props.attachment)
          ? this.props.attachment
          : createAttachment(this.props.attachment) //convert to attachment as we need to send aditional props
        attachment.forEntity = this.props.attachmentEntity

        //existing attachment
        if (attachment.id && attachment.id > 0) {
          this.props.onDelete(attachment)
        }
        //temporary attachment
        else {
          this.setState({ isDeleting: true, errorMessage: null })
          this.props.ctx.client
            .deleteAttachment(attachment)
            .then(() => {
              this.props.onDelete(attachment)
            })
            .catch((error) => {
              const errorMessage =
                error instanceof NotFoundError
                  ? 'Error deleting attachment: attachment not found. It might have already been deleted'
                  : 'Error deleting attachment'
              this.setState({
                errorMessage,
                isDeleting: false
              })
            })
        }
      }

      onClose = () => {
        this.props.onClose()
      }

      showDelete = () => {
        //we don't delete existing incident attachments
        return !(
          this.props.attachmentEntity === 'incident' &&
          !this.props.attachment.uuid
        )
      }

      //incident comments cannot be changed
      disableComment = () =>
        this.props.attachmentEntity === 'checklist' &&
        !this.props.attachment.uuid

      render(): React.ReactNode {
        const attachment = this.props.attachment

        return (
          <Dialog
            disableBackdropClick={true}
            open={this.props.isOpen}
            onClose={this.onClose}
            aria-labelledby='form-dialog-title'
          >
            <DialogTitle style={{ paddingBottom: 0 }}>Attachment</DialogTitle>
            <DialogContent className={this.props.classes.dialog}>
              <Link
                className={this.props.classes.fileLink}
                href='#'
                target='_blank'
                rel='noopener noreferrer'
                onClick={(e: any) => {
                  const fileAttachment = isAttachment(this.props.attachment)
                    ? this.props.attachment
                    : createAttachment(this.props.attachment)
                  this.props.ctx.client.viewAttachment(fileAttachment)
                  e.preventDefault()
                }}
              >
                {attachment.fileName}
              </Link>
              {this.state.isDeleting && (
                <CircularProgress
                  color='primary'
                  size={12}
                  role='progressbar'
                />
              )}
              {
                <TextField
                  style={{ marginTop: '20px' }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label={'Comment'}
                  value={this.state.comment}
                  onChange={(e) => this.setState({ comment: e.target.value })}
                  margin='dense'
                  multiline
                  disabled={this.disableComment()}
                />
              }
              {this.state.errorMessage && (
                <div className={this.props.classes.errorMessage}>
                  ({this.state.errorMessage})
                </div>
              )}
            </DialogContent>

            <DialogActions style={{ alignItems: 'flex-end' }}>
              {this.showDelete() && (
                <Button
                  style={{ color: '#F00' }}
                  onClick={this.onDelete}
                  disabled={this.state.isDeleting}
                >
                  {this.props.ctx.i18next.t('delete')}
                </Button>
              )}
              {!this.disableComment() && (
                <Button
                  color='primary'
                  onClick={() => {
                    if (this.props.onAddComment)
                      this.props.onAddComment(this.state.comment)
                  }}
                  disabled={this.state.isDeleting}
                >
                  {this.props.ctx.i18next.t('save')}
                </Button>
              )}
              <Button
                color='primary'
                onClick={this.onClose}
                disabled={this.state.isDeleting}
              >
                {this.props.ctx.i18next.t('close')}
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    }
  )
)
