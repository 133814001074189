import {
  differenceInMinutes,
  differenceInDays,
  differenceInHours,
  format,
  parseISO,
  isDate
} from 'date-fns'
import { CONFIG } from '../config'
import { utcToZonedTime } from 'date-fns-tz'

export function formatDate(
  fmt:
    | 'iso'
    | 'exif'
    | 'display_date'
    | 'display_datetime'
    | 'display_time'
    | 'iso_dateonly'
    | 'datetime-local'
    | 'time'
    | 'utc',
  date: Date | string,
  displayDateFormat?: string
): string {
  let newDate: Date
  if (isDate(date)) {
    newDate = date as Date
  } else {
    newDate = parseISO(date as string)
  }
  if (fmt == 'exif') {
    return format(newDate, 'yyyy:MM:dd HH:mm:ss')
  } else if (fmt == 'display_date') {
    if (displayDateFormat) return format(newDate, displayDateFormat)
    else return format(newDate, CONFIG.displayDateFormat)
  } else if (fmt == 'display_datetime') {
    if (displayDateFormat) return format(newDate, displayDateFormat)
    else return format(newDate, CONFIG.displayDateTimeFormat)
  } else if (fmt == 'display_time') {
    if (displayDateFormat) return format(newDate, displayDateFormat)
    else return format(newDate, CONFIG.displayTimeFormat)
  } else if (fmt == 'iso_dateonly') {
    return format(newDate, 'yyyy-MM-dd')
  } else if (fmt == 'datetime-local') {
    return format(newDate, "yyyy-MM-dd'T'HH:mm")
  } else if (fmt == 'utc') {
    return format(newDate, "yyyy-MM-dd'T'HH:mm:ssXX")
  } else if (fmt == 'time') {
    return format(newDate, 'HH:mm:ss')
  } else {
    return format(newDate, "yyyy-MM-dd'T'HH:mm:ss")
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getIsoOffset = () => {
  const now = new Date()
  const offset = -now.getTimezoneOffset() // Get timezone offset in minutes and negate it because JS returns it inverted
  const sign = offset >= 0 ? '+' : '-' // Determine the sign based on the offset value

  // Calculate absolute offset values for hours and minutes
  const absOffset = Math.abs(offset)
  const hours = Math.floor(absOffset / 60)
  const minutes = absOffset % 60

  // Pad single digit hours and minutes with leading zeros
  const paddedHours = String(hours).padStart(2, '0')
  const paddedMinutes = String(minutes).padStart(2, '0')

  // Construct and return the ISO 8601 offset string
  return `${sign}${paddedHours}${paddedMinutes}`
}

export function convertDDMMYYYYToLocalDate(apiDate: string): string {
  // Returns date string as a local date
  const datePart =
    apiDate.substr(6, 4) +
    '-' +
    apiDate.substr(3, 2) +
    '-' +
    apiDate.substr(0, 2)
  return format(parseISO(datePart), 'yyyy-MM-dd')
}

export function displayDateInZone(inputDate: string, timezone: string): string {
  const zonedDate = utcToZonedTime(inputDate, timezone)
  return formatDate('display_date', zonedDate)
}

export function getTimestamp(inputDate: string): string {
  const oldDate = parseISO(inputDate)
  const minutes = differenceInMinutes(new Date(), oldDate)
  if (minutes >= 2880) {
    return `${differenceInDays(new Date(), oldDate)} days ago`
  } else if (minutes >= 1440) {
    return ' 1 day ago'
  } else if (minutes >= 120) {
    return `${differenceInHours(new Date(), oldDate)} hours ago`
  } else if (minutes >= 60) {
    return ' 1 hour ago'
  } else if (minutes > 1) {
    return `${minutes} mins ago`
  } else if (minutes <= 1) {
    return ' just now'
  }
  return ''
}
