import * as React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DialogContentText from '@material-ui/core/DialogContentText'
import { OneplaceLibraryContext } from '../OneplaceLibraryProvider'

export interface IValidationErrorsProps {
  errorsShown: boolean
  errors: string[]
  onErrorsDismissed: () => void
}

export const ValidationErrors: React.FC<IValidationErrorsProps> = (props) => {
  const ctx = React.useContext(OneplaceLibraryContext)
  return (
    <Dialog open={props.errorsShown} onClose={props.onErrorsDismissed}>
      <DialogTitle>{ctx.i18next.t('validation_errors')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {ctx.i18next.t('please_correct_the_following')}
        </DialogContentText>
        <ul>
          {props.errors.map((error, errorIdx) => (
            <Typography
              key={errorIdx}
              component='li'
              style={{ display: 'list-item' }}
            >
              {error}
            </Typography>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onErrorsDismissed} color='primary'>
          {ctx.i18next.t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ValidationErrors
