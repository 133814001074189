//COPY OF controls.ts BUT ONLY USED BY QuestionforDDL
import CheckboxControl from '../../form_controls/CheckboxControl'
import DateControl from '../../form_controls/DateControl'
import DateTimeControl from '../../form_controls/DateTimeControl'
import NumberControl from '../../form_controls/NumberControl'
import PercentageControl from '../../form_controls/PercentageControl'
import SelectControl from '../../form_controls/SelectControl'
import SelectManyControl from '../../form_controls/SelectManyControl'
import TextControl from '../../form_controls/TextControl'
import TextAreaControl from '../../form_controls/TextAreaControl'
import SignatureControl from '../../form_controls/SignatureControl'
import TimerControl from '../../form_controls/TimerControl'
import DataGridControl from '../../form_controls/grids/DataGridControl'
import { IControlProps } from '../../form_controls/types'
import SubtotalControl from '../../form_controls/SubtotalControl'

export const fieldControls: {
  [questionType: string]: React.ComponentType<IControlProps>
} = {
  checkbox: CheckboxControl,
  date: DateControl,
  datetime: DateTimeControl,
  number: NumberControl,
  percentage: PercentageControl,
  select: SelectControl,
  select_many: SelectManyControl,
  text: TextControl,
  textarea: TextAreaControl as any,
  yesno: SelectControl,
  signature: SignatureControl,
  timer: TimerControl,
  datagrid: DataGridControl,
  information: () => null,
  subtotal: SubtotalControl
}
