import * as React from 'react'
import { IControlProps } from './types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import SignaturePad from 'signature_pad'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'

const SIGNED = 'Signed'

export interface ISignatureControlProps
  extends IOneplaceLibraryContextProp,
    IControlProps {
  mockSignaturePad?: SignaturePad
  // used on incident page
  showLabelForNewSignature?: boolean
}

export interface ISignatureControlState {
  dialogOpen: boolean
}

export interface ISignatureValue {
  value: '' | 'Signed'
  signatureData?: string
}

export const SIGNATURE_PAD_DELAY = 200

export default withOneplaceLibraryContext(
  class SignatureControl extends React.Component<
    ISignatureControlProps,
    ISignatureControlState
  > {
    signatureCanvas: React.RefObject<HTMLCanvasElement>
    signaturePad!: SignaturePad

    constructor(props: any) {
      super(props)
      this.onSave = this.onSave.bind(this)
      this.openDialog = this.openDialog.bind(this)
      this.initSignaturePad = this.initSignaturePad.bind(this)
      this.closeDialog = this.closeDialog.bind(this)
      this.clearCanvas = this.clearCanvas.bind(this)
      this.state = {
        dialogOpen: false
      }
      this.signatureCanvas = React.createRef()
    }

    onSave(): void {
      let newVal: ISignatureValue
      if (this.signaturePad.isEmpty()) {
        newVal = { value: '' }
      } else {
        newVal = {
          value: 'Signed',
          signatureData: this.signaturePad.toDataURL()
        }
      }
      this.props.form.setFieldValue(this.props.field.name, newVal)
      this.closeDialog()
    }

    closeDialog(): void {
      this.setState({ dialogOpen: false })
      if (this.signaturePad) {
        this.signaturePad.off()
      }
    }

    initSignaturePad(): void {
      if (this.props.mockSignaturePad) {
        this.signaturePad = this.props.mockSignaturePad
      } else {
        this.signaturePad = new SignaturePad(this.signatureCanvas.current!)
      }
      const value = this.getValue()
      if (value.value == SIGNED) {
        this.signaturePad.fromDataURL(value.signatureData!, { ratio: 1 })
      }
    }

    openDialog(): void {
      this.setState({ dialogOpen: true })
      // There is a short delay before the canvas is available
      setTimeout(() => {
        try {
          this.initSignaturePad()
        } catch (e) {
          console.error('Unable to mount signature pad.')
        }
      }, SIGNATURE_PAD_DELAY)
    }

    getValue(): ISignatureValue {
      return this.props.field.value || { value: '' }
    }

    clearCanvas(): void {
      this.signaturePad.clear()
    }

    render(): React.ReactNode {
      const value = this.getValue()

      if (this.props.gridCell) {
        return (
          <div
            style={{
              pointerEvents: this.props.disabled ? 'none' : 'auto'
            }}
          >
            {value.value == SIGNED && (
              <img
                src={value.signatureData}
                style={{
                  width: 140,
                  height: 72
                }}
              />
            )}
          </div>
        )
      } else {
        return (
          <div
            style={{
              marginTop: this.props.showLabelForNewSignature == true ? 20 : 0
            }}
          >
            <div>
              {value.value == '' && (
                <Button
                  variant='contained'
                  color='primary'
                  style={{ backgroundColor: '#888' }}
                  onClick={this.openDialog}
                  disabled={this.props.disabled}
                >
                  {this.props.ctx.i18next.t('add_signature')}
                </Button>
              )}
              {value.value == SIGNED && (
                <img
                  src={value.signatureData}
                  style={{
                    width: 175,
                    height: 90,
                    border: '1px solid #AAA'
                  }}
                  onClick={this.openDialog}
                />
              )}
            </div>
            <Dialog open={this.state.dialogOpen} onClose={this.closeDialog}>
              <DialogTitle id='form-dialog-title'>
                {this.props.ctx.i18next.t('signature')}
              </DialogTitle>
              <DialogContent>
                <div
                  style={{
                    border: '1px solid #000',
                    pointerEvents: this.props.disabled ? 'none' : 'auto'
                  }}
                >
                  <canvas
                    width={350}
                    height={180}
                    ref={this.signatureCanvas}
                  ></canvas>
                </div>
              </DialogContent>
              <DialogActions>
                <Button color='primary' onClick={this.closeDialog}>
                  {this.props.ctx.i18next.t('cancel')}
                </Button>
                {!this.props.disabled && (
                  <>
                    <Button
                      style={{ color: '#F00' }}
                      onClick={this.clearCanvas}
                    >
                      {this.props.ctx.i18next.t('clear')}
                    </Button>
                    <Button color='primary' onClick={this.onSave}>
                      {this.props.ctx.i18next.t('save')}
                    </Button>
                  </>
                )}
              </DialogActions>
            </Dialog>
          </div>
        )
      }
    }
  }
)
