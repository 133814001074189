import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import NotificationsIcon from '@material-ui/icons/Notifications'
import SmartphoneIcon from '@material-ui/icons/Smartphone'
import EmailIcon from '@material-ui/icons/Email'
import NewIcon from '@material-ui/icons/FiberNewRounded'
import {
  Avatar,
  Badge,
  createStyles,
  Dialog,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
  Grid,
  DialogContent,
  DialogActions,
  Button,
  Box
} from '@material-ui/core'
import {
  INotification,
  IMyNotificationsWrapper
} from '../../models/Notifications'
import { displayDateInZone, getTimestamp } from '../../utils/dates'
import { truncateText } from '../../utils/stringUtil'

interface INotificationDialogProps {
  fetchMyNotifications: (
    batchSize: number,
    newOffset: number
  ) => Promise<IMyNotificationsWrapper>
  timeZone: string
  unreadCount: number
  markAllNotificationsAsRead: () => Promise<boolean>
  clickLink: (notification: INotification) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      color: '#8d8d8d'
    },
    toolbar: {
      backgroundColor: 'transparent'
    },
    avatar: {
      backgroundColor: 'white',
      border: '0.3px solid #223d79',
      marginRight: 20
    },
    bell: {
      float: 'right',
      color: 'white !important'
    },
    unread_notification: {
      fontWeight: 'bold'
    },
    inline: {
      display: 'inline'
    },
    dialog: {
      position: 'absolute',
      right: 10,
      top: 5
    },
    footer: {
      backgroundColor: 'white'
    },
    entityDiv: {
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      color: '#223d79'
    },
    primaryHeading: {
      marginBottom: '10px',
      fontSize: '1.2em'
    },
    timestamp: {
      marginTop: '10px',
      float: 'right'
    },
    snackbar: {
      borderLeft: '4px solid #f50057',
      backgroundColor: 'white',
      color: 'black'
    },
    snackbar_icon: {
      fontFamily: 'oneplaceIcons',
      fontSize: '3rem !important',
      color: '#f50057'
    },
    snackbar_link: {
      color: 'black !important',
      textTransform: 'none'
    },
    menu_icon: {
      fontFamily: 'oneplaceIcons',
      fontSize: 24,
      marginRight: 10
    }
  })
)

export default function NotificationDialog(props: INotificationDialogProps) {
  const classes = useStyles()
  const batchSize = 10
  const [offset, setOffset] = useState(-1)
  const [isShowMore, setIsShowMore] = useState(true)
  const [show, setShow] = useState(false)
  const [notifications, setNotifications] = useState<INotification[]>([])

  const handleClose = () => {
    setShow(false)
  }

  const clickNotificationLink = (notification: INotification) => {
    props.clickLink(notification)
    setShow(false)
  }
  const onShowMore = async () => {
    const newOffset = offset + batchSize
    const notificationsList: IMyNotificationsWrapper =
      await props.fetchMyNotifications(batchSize, newOffset)
    setNotifications(notifications.concat(notificationsList.notifications))
    setOffset(newOffset)
    setIsShowMore(notificationsList.notifications.length >= batchSize)
  }

  const onMarkAllAsRead = async () => {
    const isSuccessful = await props.markAllNotificationsAsRead()
    if (isSuccessful) {
      setNotifications(
        notifications.map((n) => {
          n.recipient.status = 'read'
          return n
        })
      )
    }
  }

  const onShowNotificationCenter = async () => {
    if (offset < 1) {
      setOffset(0)

      const notificationsList: IMyNotificationsWrapper =
        await props.fetchMyNotifications(batchSize, 0)
      setNotifications(notificationsList.notifications)
      setIsShowMore(notificationsList.notifications.length >= batchSize)
    }
    setShow(true)
  }

  return (
    <>
      <Grid>
        <IconButton
          className={classes.bell}
          onClick={() => onShowNotificationCenter()}
          aria-label='notifications'
        >
          {props.unreadCount > 0 && (
            <Badge badgeContent={props.unreadCount} color='secondary'>
              <NotificationsIcon />
            </Badge>
          )}
          {props.unreadCount <= 0 && <NotificationsIcon />}
        </IconButton>
      </Grid>

      <Dialog
        onClose={handleClose}
        aria-labelledby='simple-dialog-title'
        open={show}
        classes={{
          paper: classes.dialog
        }}
        scroll='paper'
      >
        <DialogTitle
          style={{ backgroundColor: '#F50057', color: 'white' }}
          disableTypography={true}
          id='notification-dialog-title'
        >
          <h2>My Notifications</h2>
        </DialogTitle>
        <DialogContent dividers={true}>
          <List className={classes.root}>
            {notifications.map((n: INotification) => (
              <>
                <ListItem
                  button
                  onClick={() => clickNotificationLink(n)}
                  style={{ textDecoration: 'none' }}
                >
                  <Box display={{ xs: 'none', sm: 'block' }}>
                    <ListItemAvatar>
                      <Avatar
                        alt='notification type'
                        className={classes.avatar}
                      >
                        {n.type === 'email' && <EmailIcon color='primary' />}
                        {n.type === 'app' && <SmartphoneIcon color='primary' />}
                      </Avatar>
                    </ListItemAvatar>
                  </Box>
                  <ListItemText
                    primary={
                      <span className={classes.entityDiv}>
                        {!n.ticket && n.person && (
                          <>
                            <span className={classes.menu_icon}>
                              <span className='icon-1place-customer' />
                            </span>{' '}
                            <span className={classes.primaryHeading}>
                              {n.person.name}
                            </span>
                          </>
                        )}
                        {!n.ticket && n.franchisee && (
                          <>
                            <span className={classes.menu_icon}>
                              <span className='icon-1place-franchise' />
                            </span>{' '}
                            <span className={classes.primaryHeading}>
                              {n.franchisee.name}
                            </span>
                          </>
                        )}
                        {!n.ticket && n.site && (
                          <>
                            <span className={classes.menu_icon}>
                              <span className='icon-1place-site' />
                            </span>{' '}
                            <span className={classes.primaryHeading}>
                              {n.site.name}
                            </span>
                          </>
                        )}
                        {n.ticket && (
                          <>
                            <span className={classes.menu_icon}>
                              <span className='icon-1place-tickets' />
                            </span>{' '}
                            <span className={classes.primaryHeading}>
                              {n.site ? n.site.name : n.franchisee!.name}
                            </span>
                          </>
                        )}
                      </span>
                    }
                    secondary={
                      <Typography
                        component='span'
                        variant='body2'
                        className={
                          n.recipient.status === 'unread'
                            ? classes.unread_notification
                            : ''
                        }
                      >
                        <Grid container>
                          {n.customField && (
                            <>
                              <Grid item md={10} xs={12}>
                                {n.customField.name}:{' '}
                                {displayDateInZone(
                                  n.customField.dateValue,
                                  props.timeZone
                                )}{' '}
                                <br />
                              </Grid>
                              <Grid item xs={12}>
                                {n.content}
                              </Grid>
                            </>
                          )}
                          {n.ticket && (
                            <>
                              <Grid item xs={12}>
                                Subject: {n.ticket.subject}
                              </Grid>
                              <Grid item xs={12}>
                                Status: {n.ticket.status}
                              </Grid>
                              <Grid item xs={12}>
                                Due Date:{' '}
                                {displayDateInZone(
                                  n.ticket.dueDate,
                                  props.timeZone
                                )}
                              </Grid>
                              {n.ticket.newComment && (
                                <Grid item xs={12}>
                                  Comment:{' '}
                                  {truncateText(n.ticket.newComment, 50)}
                                </Grid>
                              )}
                            </>
                          )}
                          <Grid item xs={12}>
                            <div className={classes.timestamp}>
                              {getTimestamp(n.executionDateTime)}
                            </div>
                          </Grid>
                        </Grid>
                      </Typography>
                    }
                  />
                  {n.recipient.status === 'unread' && (
                    <span style={{ marginTop: '0' }}>
                      <NewIcon color='secondary' />
                    </span>
                  )}
                  {n.recipient.status === 'read' && (
                    <span style={{ marginTop: '0', marginRight: '20px' }}>
                      {' '}
                      &nbsp;
                    </span>
                  )}
                </ListItem>

                <Divider variant='inset' component='li' />
              </>
            ))}
          </List>
          {isShowMore && notifications.length > 0 && (
            <Grid
              container
              direction='row'
              justifyContent='space-evenly'
              alignItems='center'
            >
              <Grid item>
                <Button
                  size='small'
                  variant='outlined'
                  onClick={onShowMore}
                  color='primary'
                >
                  Show More
                </Button>
              </Grid>
            </Grid>
          )}
          {notifications.length === 0 && (
            <Typography variant='body1'>
              You don&#39;t have any notification.
            </Typography>
          )}
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Grid
            container
            direction='row'
            justifyContent='space-evenly'
            alignItems='center'
          >
            <Grid item>
              {notifications.length > 0 && (
                <Button
                  variant='outlined'
                  onClick={onMarkAllAsRead}
                  color='secondary'
                >
                  Mark All As Read
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                variant='outlined'
                onClick={handleClose}
                color='secondary'
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
