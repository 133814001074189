import * as React from 'react'
import Input from '@material-ui/core/Input'
import { IControlProps } from './types'
import { GRID_CELLPADDING, GRID_CELLFONTSIZE } from './grids/consts'
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'
import { TextareaAutosize } from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    oneplaceTextArea: {
      padding: '5px',
      marginTop: '16px',
      width: '100%',
      border: '1px solid rgba(0, 0, 0, 0.42)',
      fontFamily: theme.typography.fontFamily,
      '&:hover': {
        border: '2px solid',
        borderColor: theme.palette.primary.main
      },
      '&:focus': {
        border: '2px solid',
        borderColor: theme.palette.primary.main
      }
    }
  })

export interface ITextAreaControlProps
  extends IControlProps,
    WithStyles<typeof styles> {}
export default withStyles(styles)(
  class TextAreaControl extends React.Component<ITextAreaControlProps> {
    constructor(props: any) {
      super(props)
      this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event: any): void {
      this.props.form.setFieldValue(this.props.field.name, event.target.value)
    }

    render(): React.ReactNode {
      if (this.props.gridCell) {
        return (
          <div
            id={this.props.id}
            style={{ padding: GRID_CELLPADDING, fontSize: GRID_CELLFONTSIZE }}
          >
            {this.props.field.value || ''}
          </div>
        )
      } else {
        const TextArea = this.props.minRows ? TextareaAutosize : Input
        let extraProps = undefined
        if (this.props.minRows) {

          extraProps = {
            className:
              'MuiInputBase-input  ' + this.props.classes.oneplaceTextArea,
            minRows: this.props.minRows
          }
        }
        return (
          <TextArea
            id={this.props.id}
            fullWidth
            multiline
            value={this.props.field.value || ''}
            onChange={this.handleChange}
            disabled={this.props.disabled || false}
            {...extraProps}
          />
        )
      }
    }
  }
)
