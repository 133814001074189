import * as React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Downshift, { ControllerStateAndHelpers } from 'downshift'
import Paper from '@material-ui/core/Paper'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { Popper } from '@material-ui/core'

export interface IAutoCompleteOption {
  id: number
  name: string
}

export interface IAutoCompleteProps {
  placeholder: string
  options: IAutoCompleteOption[]
  selectedItem: IAutoCompleteOption | null
  onChange: (
    selectedItem: IAutoCompleteOption | null,
    stateAndHelpers: any
  ) => void
  fetchEntities?(value: string): Promise<IAutoCompleteOption[]>
}

export interface IAutoCompleteState {
  options: IAutoCompleteOption[]
}

function renderSuggestion(props: any) {
  const isHighlighted = props.highlightedIndex === props.index
  const isSelected = props.selectedItem
    ? props.selectedItem.id == props.suggestion.id
    : false
  const style: any = {
    fontWeight: isSelected ? 500 : 400,
    whiteSpace: 'normal'
  }
  if (isSelected) {
    style.background = 'rgba(0, 0, 0, 0.14)'
  }
  return (
    <MenuItem
      {...props.itemProps}
      key={props.suggestion.id}
      selected={isHighlighted}
      component='div'
      style={style}
    >
      {props.suggestion.name}
    </MenuItem>
  )
}

function getSuggestions(
  options: IAutoCompleteOption[],
  inputValue: string
): IAutoCompleteOption[] {
  let count = 0
  return options.filter((option) => {
    const keep =
      (!inputValue ||
        option.name.toLowerCase().includes(inputValue.toLowerCase())) &&
      count < 20
    if (keep) {
      count += 1
    }
    return keep
  })
}

const AutoComplete = class extends React.Component<
  IAutoCompleteProps,
  IAutoCompleteState
> {
  static displayName = 'AutoComplete'
  inputField: React.RefObject<HTMLInputElement>
  constructor(props: IAutoCompleteProps) {
    super(props)
    this.inputField = React.createRef()
    this.state = {
      options: props.options
    }
    this.inputOnChange = this.inputOnChange.bind(this)
  }
  // onChange method for the input field

  async inputOnChange(
    inputValue: string,
    _stateAndHelpers: ControllerStateAndHelpers<IAutoCompleteOption>
  ): Promise<void> {
    if (!inputValue) {
      return
    }
    if (this.props.fetchEntities) {
      const result = await this.props.fetchEntities(inputValue)
      this.setState({ options: result })
    } else {
      const result = getSuggestions(this.props.options, inputValue)
      this.setState({ options: result })
    }
  }
  render() {
    return (
      <Downshift
        selectedItem={this.props.selectedItem}
        itemToString={(item: IAutoCompleteOption | null) =>
          (item && item.name) || ''
        }
        onChange={this.props.onChange}
        onInputValueChange={this.inputOnChange}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          //     inputValue,
          highlightedIndex,
          selectedItem
        }) => {
          return (
            <div style={{ position: 'relative' }}>
              <Input
                type='search'
                placeholder={this.props.placeholder}
                fullWidth={true}
                startAdornment={
                  <InputAdornment position='start' style={{ color: '#BBB' }}>
                    <SearchIcon />
                  </InputAdornment>
                }
                ref={this.inputField}
                {...getInputProps()}
              />

              <Popper
                disablePortal
                style={{
                  width: this.inputField?.current?.clientWidth,
                  zIndex: 1510
                }}
                open={isOpen}
                anchorEl={this.inputField.current}
                placement='bottom-start'
                modifiers={{
                  preventOverflow: {
                    enable: true,
                    escapeWithReference: false
                  },
                  flip: {
                    enabled: true,
                    behavior: []
                  }
                }}
              >
                <Paper square>
                  {this.state.options.map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex: highlightedIndex,
                      selectedItem: selectedItem
                    })
                  )}
                </Paper>
              </Popper>
            </div>
          )
        }}
      </Downshift>
    )
  }
}

export default AutoComplete
