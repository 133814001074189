/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as i18next from 'i18next'
import { getI18nConfig } from '../settings/getI18nConfig'

export interface Ii18nHelper {
  initialise(): Promise<void>
  t(key: string, values?: any): i18next.TFunctionResult
  changeLang(lang: string): Promise<void>
  addTranslations(ns: string, messages: any): void
  onUpdate: () => void
  instance: () => i18next.i18n
}

export class I18nHelper implements Ii18nHelper {
  _instance: i18next.i18n = null as any
  _t: i18next.TFunction = null as any
  onUpdate: () => void = null as any

  initialise(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this._instance) {
        throw new Error('i18n already initialised!')
      }
      this._instance = i18next.createInstance()
      this._instance.init(getI18nConfig(), (err, t) => {
        if (err) {
          reject(err)
        } else {
          this._t = t
          resolve()
        }
      })
    })
  }

  t = (key: string, values?: any) => {
    return this._t(key, values)
  }

  async changeLang(lang: string) {
    return new Promise<void>((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this._instance.changeLanguage(lang, (err, _t) => {
        if (err) {
          reject(err)
        } else {
          if (this.onUpdate) {
            this.onUpdate()
          }
          resolve()
        }
      })
    })
  }

  addTranslations(ns: string, messages: any) {
    this._instance.addResourceBundle(this._instance.language, ns, messages)
    this.onUpdate()
  }

  mockT() {
    this._t = (key: string | string[], values?: any) => {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return values ? key + ' ' + JSON.stringify(values) : key
    }
  }
  instance() {
    return this._instance
  }
}

export const i18n = new I18nHelper()
