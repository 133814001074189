import React, { useState, useEffect } from 'react'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'

import { takeFromCamera } from './utils/cordovaPhotos'
import { IPhoto } from '../..'
import { logError } from '../../logging'

export interface ICordovaCameraProps extends IOneplaceLibraryContextProp {
  open: boolean
  onPhotoTaken: (photo: IPhoto) => void
}

export const CordovaCamera = withOneplaceLibraryContext(
  (props: ICordovaCameraProps) => {
    const [cameraOpen, setCameraOpen] = useState<boolean>(false)

    const takePhotoFromCamera = async (): Promise<void> => {
      try {
        const settings = await props.ctx.client.getSettings()
        const photo = await takeFromCamera(
          settings.addPhotosToGallery,
          props.ctx.imageStorage,
          props.ctx.env
        )
        if (photo) {
          props.onPhotoTaken(photo)
        }
      } catch (e) {
        logError(e, 'Error while processing image from camera')
      }
    }

    useEffect(() => {
      async function takePhoto() {
        await takePhotoFromCamera()
      }

      //we activate camera when prop changes from false to true
      if (!cameraOpen && props.open) {
        takePhoto()
      }
      setCameraOpen(props.open)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open])

    return <></>
  }
)
