import { IConfig } from './types'

// Test Config
const CONFIG: IConfig = {
  displayDateFormat: 'dd/MM/yyyy', // v1 date-fns format 'DD/MM/YYYY',
  displayDateTimeFormat: 'dd/MM/yyyy hh:mm:ss a', // v2 date-fns format 'DD/MM/YYYY hh:mm a',
  displayTimeFormat: 'hh:mm a',
  imageMaxResolution: 1024,
  imageCompression: 0.5,
  imageThumbnailMaxResolution: 100,
  imageThumbnailCompression: 0.8,
  imageMultipleMaxNumber: 10, // for multiple upload
  imageMultipleMaxSize: 30, //MB size for multiple upload
  attachmentMaxSize: 25 //MB size
}

export { CONFIG }
