import * as React from 'react'
import Input from '@material-ui/core/Input'
import { IControlProps } from './types'
import { COLOURS } from './colours'
import { GRID_CELLPADDING, GRID_CELLFONTSIZE } from './grids/consts'

export default class NumberControl extends React.Component<IControlProps> {
  constructor(props: any) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event: any) {
    this.props.form.setFieldValue(this.props.field.name, event.target.value)
  }

  render() {
    let value = this.props.field.value
    if (typeof value == 'undefined' || value === null) {
      value = ''
    }

    const inputStyle: React.CSSProperties = { paddingLeft: 4 }
    const cellStyle: React.CSSProperties = {
      padding: GRID_CELLPADDING,
      fontSize: GRID_CELLFONTSIZE
    }
    if (this.props.colourRules && value !== '') {
      const match = this.props.colourRules.find(
        (rule) =>
          (rule.lowValue === null || Number(value) > Number(rule.lowValue)) &&
          (rule.highValue === null || Number(value) <= Number(rule.highValue))
      )
      if (match && COLOURS[match.color]) {
        inputStyle.background = COLOURS[match.color].background
        inputStyle.color = COLOURS[match.color].foreground
        cellStyle.background = COLOURS[match.color].background
        cellStyle.color = COLOURS[match.color].foreground
      }
    }

    if (this.props.gridCell) {
      return (
        <div id={this.props.id} style={cellStyle}>
          {value}
        </div>
      )
    } else {
      return (
        <Input
          id={this.props.id}
          fullWidth
          type='number'
          value={value}
          onChange={this.handleChange}
          disabled={this.props.disabled || false}
          style={inputStyle}
        />
      )
    }
  }
}
