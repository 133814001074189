import React, { useState, useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography
} from '@material-ui/core'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'
import Camera, { FACING_MODES } from 'react-html5-camera-photo'
import { processPhoto } from './utils/processPhoto'
import { convertBase64Image, getRandomFileName } from './utils/imageUtils'
import { IPhoto } from '../..'
import { logError } from '../../logging'

export interface IHtmlCameraProps extends IOneplaceLibraryContextProp {
  open: boolean
  onPhotoTaken: (photo: IPhoto) => void
  onCloseCamera: () => void
}

export const HtmlCamera = withOneplaceLibraryContext(
  (props: IHtmlCameraProps) => {
    const [cameraOpen, setCameraOpen] = useState<boolean>(false)

    useEffect(() => {
      setCameraOpen(props.open)
    }, [props.open])

    const handleTakePhoto = async (dataUri: string) => {
      try {
        const parts = dataUri.split(';base64,')
        const photo = await processPhoto(
          convertBase64Image(parts[1]).file,
          getRandomFileName() + '.png',
          props.ctx.imageStorage
        )
        photo.attachmentType = 'photo'
        photo.source = 'camera'
        props.onPhotoTaken(photo)
      } catch (e) {
        logError(e, 'Error while processing uploaded image')
      }
    }

    return (
      <Dialog
        open={cameraOpen}
        aria-labelledby='form-dialog-title'
        fullWidth={false}
        maxWidth='md'
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => {
                //setCameraOpen(false) parent component controls camera visibility
                props.onCloseCamera()
              }}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h6'>Camera</Typography>
          </Toolbar>
        </AppBar>

        <Camera
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          isFullscreen={false}
          onTakePhoto={handleTakePhoto}
          isImageMirror={false}
        />
      </Dialog>
    )
  }
)
