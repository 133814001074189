import {
  IImageStorage,
  ImageStorageType,
  STORED_IMAGE_PREFIX
} from '../data_sources/imagestorage/imageStorage'
import { MockBlob } from '../__mocks__/mock_filereader'

export class MockImageStorage implements IImageStorage {
  mockBlob = new MockBlob({
    data: new Blob(),
    type: 'png',
    size: 2048
  })
  _mocks = {
    storeImage: jest.fn((_data: Blob) =>
      Promise.resolve(STORED_IMAGE_PREFIX + 'storageUrl for: mock_image_data')
    ),
    getImageObjectUrl: jest.fn((url: string, _reload?: boolean) =>
      Promise.resolve('objectUrl for: ' + url)
    ),
    getImageData: jest.fn((_url: string) =>
      Promise.resolve(this.mockBlob.file.data!)
    ),
    makePermenant: jest.fn((url: string) =>
      Promise.resolve('permenant url for: ' + url)
    ),
    removeImage: jest.fn(),
    revokeObjectUrls: jest.fn()
  }

  storeImage(imageData: Blob, _storageType: ImageStorageType): Promise<string> {
    return this._mocks.storeImage(imageData)
  }
  getImageObjectUrl(localImageUrl: string, reload?: boolean): Promise<string> {
    return this._mocks.getImageObjectUrl(localImageUrl, reload)
  }
  getImageData(localImageUrl: string): Promise<Blob> {
    return this._mocks.getImageData(localImageUrl)
  }
  makePermenant(tempImageUrl: string): Promise<string> {
    return this._mocks.makePermenant(tempImageUrl)
  }
  removeImage(localImageUrl: string): Promise<void> {
    return this._mocks.removeImage(localImageUrl)
  }
  revokeObjectUrls(): void {
    return this._mocks.revokeObjectUrls()
  }
}
