import * as React from 'react'
import { PhotoWrapper } from './PhotoWrapper'
import { IPhoto } from '../../models/Checklist'
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'

const styles = (theme: Theme) =>
  createStyles({
    commentLinkStyle: {
      color: theme.palette.primary.light
    },
    image_comment_badge: {
      position: 'relative',
      display: 'inline-block'
    },
    badge: {
      position: 'absolute',
      right: 1,
      top: 1,
      background: '#f50057',
      textAlign: 'center',
      borderRadius: '30px 30px 30px 30px',
      color: 'white',
      fontSize: 10,
      fontFamily: 'Roboto, Helvetica , Arial, sans-serif'
    },
    badge_one_digit: {
      padding: '3px 6px 3px 6px'
    },
    badge_two_digits: {
      padding: '3px 3px 3px 3px'
    }
  })

export interface IPhotoThumbnailProps extends WithStyles<typeof styles> {
  src: string
  onClick: (event: any) => void
  photo?: IPhoto | null
}

export interface IPhotoThumbnailState {
  photoCommentDialogOpen: boolean
}

export const PhotoThumbnail = withStyles(styles)(
  class extends React.Component<IPhotoThumbnailProps, IPhotoThumbnailState> {
    static displayName = 'PhotoThumbnail'
    constructor(props: any) {
      super(props)
    }
    render() {
      const photoCount = this.props.photo && this.props.photo!.comments ? this.props.photo!.comments
                              .filter((c1) => c1.action!='delete').length : 0;

      return (
        <div>
          <PhotoWrapper>
            <div
              onClick={this.props.onClick}
              className={this.props.classes.image_comment_badge}
            >
              <img
                src={this.props.src}
                style={{ objectFit: 'cover' }}
                width={60}
                height={60}
              />
              {this.props.photo &&
                this.props.photo.comments &&
                photoCount > 0 && (
                  <span
                    className={
                      photoCount > 9
                        ? this.props.classes.badge_two_digits
                        : this.props.classes.badge_one_digit +
                          ' ' +
                          this.props.classes.badge
                    }
                  >
                    {photoCount}
                  </span>
                )}
            </div>
          </PhotoWrapper>
        </div>
      )
    }
  }
)
