import * as React from 'react'
import ReactTable, { Column } from 'react-table'
import { IControlProps } from '../types'
import { IDataGridFieldProps } from '../../../models/Checklist'
import Typography from '@material-ui/core/Typography'
import { Parser, Expression } from 'expr-eval'
import { GRID_CELLPADDING, GRID_CELLFONTSIZE } from './consts'
import { roundDecimal } from '../../../utils'

export type GridData = string[][]

export interface IDataGridControlProps extends IControlProps {
  dataGrid: IDataGridFieldProps
  dateTimeFormat: string
  dateFormat: string
}

export interface IDataGridState {
  data: GridData
}

// Attempted Fix for <input> scrolling on iOS
// https://stackoverflow.com/questions/25596960/issues-with-touch-scroll-on-ios-when-focusing-inputs
//
// function onTouchStart(event: any) {
//     event.target.style.pointerEvents = 'auto';
// }
// function onTouchMove(event: any) {
//     event.target.style.pointerEvents = 'none';
// }
// function onTouchEnd(event: any) {
//     const target = event.target;
//     setTimeout(() => {
//         target.style.pointerEvents = 'none';
//     }, 0);
// }

export default class DataGridControl extends React.Component<
  IDataGridControlProps,
  IDataGridState
> {
  columns: Column[]
  formulas: {
    [offset: number]: {
      expr: Expression
    }
  } | null

  constructor(props: any) {
    super(props)

    this.editableCell = this.editableCell.bind(this)
    this.onGridChanged = this.onGridChanged.bind(this)

    this.state = {
      data: this.props.field.value
    }

    // pre-evaluate formulas
    this.formulas = null
    const formulaProp = this.props.dataGrid.formulas
    if (formulaProp && formulaProp.length) {
      this.formulas = {}
      const parser = new Parser()
      formulaProp.forEach((formula) => {
        // Strip off leading '=' - might need to make this a bit more cleverer
        const exprText = formula.formula.toLowerCase().substr(1)
        // Store expression
        this.formulas![formula.offset] = {
          expr: parser.parse(exprText)
        }
      })
    }

    // configure columns
    this.columns = this.props.dataGrid.columnLabels.map(
      (col): Column => {
        const header = (
          <div
            style={{
              height: '3.2rem',
              whiteSpace: 'pre-wrap',
              padding: 5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div>{col.label}</div>
          </div>
        )
        const formula = this.formulas && this.formulas[col.offset]
        if (formula) {
          return {
            Header: header,
            accessor: String(col.offset),
            minWidth: 150,
            Cell: (cellInfo: any): React.ReactNode => {
              return (
                <div
                  style={{
                    padding: GRID_CELLPADDING,
                    fontSize: GRID_CELLFONTSIZE,
                    color: '#888'
                  }}
                >
                  {this.state.data[cellInfo.index + 1][cellInfo.column.id] ||
                    ''}
                </div>
              )
            }
          }
        } else {
          return {
            Header: header,
            accessor: String(col.offset),
            minWidth: 150,
            Cell: this.editableCell
          }
        }
      }
    )

    if (this.props.dataGrid.showRowLabels) {
      // First column row labels
      this.columns.unshift({
        Header: '',
        accessor: '0',
        minWidth: 150,
        Cell: (cellInfo) => (
          <div
            style={{
              padding: GRID_CELLPADDING,
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {cellInfo.value || ''}
          </div>
        )
      })
    }
  }

  onCellUpdated(cellInfo: any, event: any): void {
    const rowIndex = cellInfo.index + 1
    // this.state.data[rowIndex][cellInfo.column.id] = event.target.value
    const cellData: GridData = this.state.data
    cellData[rowIndex][cellInfo.column.id] = event.target.value
    this.setState({ data: cellData })

    if (this.formulas) {
      // Re-evaluate row calculations
      const rowData = this.state.data[rowIndex]
      const exprData: {
        [col: string]: number
      } = {}
      rowData.slice(1).forEach((cellVal, colIdx) => {
        exprData[String.fromCharCode(97 + colIdx)] = Number(cellVal)
      })
      // Update all calculated cells on the row
      this.props.dataGrid.formulas.forEach((formula) => {
        const expr = this.formulas![formula.offset].expr
        let value = expr.evaluate(exprData)
        if (!isNaN(value)) {
          // fixed js floating number rounding problem
          value = roundDecimal(value, 2)
        }
        // this.state.data[rowIndex][formula.offset] = String(value)
        const cellData: GridData = this.state.data
        cellData[rowIndex][formula.offset] = String(value)
        this.setState({ data: cellData })
      })
    }

    // Re-render
    this.setState({ data: this.state.data })
  }

  onGridChanged(): void {
    this.props.form.setFieldValue(this.props.field.name, this.state.data)
  }

  editableCell(cellInfo: any): React.ReactNode {
    return (
      <input
        type='text'
        style={{
          border: 0,
          width: '100%',
          height: '100%',
          padding: GRID_CELLPADDING,
          fontSize: GRID_CELLFONTSIZE,
          backgroundColor: 'transparent',
          textOverflow: 'ellipsis'
        }}
        onChange={this.onCellUpdated.bind(this, cellInfo)}
        onBlur={this.onGridChanged}
        value={this.state.data[cellInfo.index + 1][cellInfo.column.id] || ''}
      />
    )
  }

  render(): React.ReactNode {
    const data = this.state.data.slice(1)
    return (
      <Typography component='div'>
        <ReactTable
          columns={this.columns}
          data={data}
          sortable={false}
          showPagination={false}
          minRows={0}
          resizable={false}
        />
      </Typography>
    )
  }
}
