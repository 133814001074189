import { IChecklistField, IDataGridFieldProps } from '../../../models/Checklist'
import { ISignatureValue } from '../../form_controls/SignatureControl'

export interface IFieldValueConverter {
  fieldToControlValue: (field: IChecklistField) => any
  controlValueToField: (value: any, field: IChecklistField) => void
}

const jsonEncodedValueConverter: IFieldValueConverter = {
  fieldToControlValue(field: IChecklistField) {
    return field.value ? JSON.parse(field.value) : null
  },
  controlValueToField(value: any, field: IChecklistField) {
    field.value = JSON.stringify(value)
  }
}

const fieldValueConverters: { [fType: string]: IFieldValueConverter } = {
  select_many: {
    fieldToControlValue(field) {
      return field.values
    },
    controlValueToField(value, field) {
      field.values = value
    }
  },
  signature: {
    fieldToControlValue(field): ISignatureValue {
      return {
        value: field.value || '',
        signatureData: field.signatureData
      }
    },
    controlValueToField(value: ISignatureValue, field) {
      field.value = value.value
      field.signatureData = value.signatureData
    }
  },
  timer: jsonEncodedValueConverter,
  datagrid: jsonEncodedValueConverter,
  dynamicdatagrid: {
    fieldToControlValue(field): IDataGridFieldProps {
      return field.dataGrid!
    },
    controlValueToField(value: IDataGridFieldProps, field) {
      field.dataGrid = value
    }
  },
  fixeddatagrid: {
    fieldToControlValue(field): IDataGridFieldProps {
      return field.dataGrid!
    },
    controlValueToField(value: IDataGridFieldProps, field) {
      field.dataGrid = value
    }
  },
  dynamicdatalist: {
    fieldToControlValue(field): IDataGridFieldProps {
      return field.dataGrid!
    },
    controlValueToField(value: IDataGridFieldProps, field) {
      field.dataGrid = value
    }
  }
}

// TODO: We should have integration tests over these

export function fieldToControlValue(field: IChecklistField): any {
  if (field.type in fieldValueConverters) {
    return fieldValueConverters[field.type].fieldToControlValue(field)
  } else {
    return field.value
  }
}

export function controlValueToField(
  newValue: any,
  field: IChecklistField
): void {
  if (field.type in fieldValueConverters) {
    fieldValueConverters[field.type].controlValueToField(newValue, field)
  } else {
    field.value = newValue
  }
}

export function isDataGridComponent(question: IChecklistField): boolean {
  return (
    question.type === 'datagrid' ||
    question.type === 'dynamicdatagrid' ||
    question.type === 'dynamicdatalist' ||
    question.type === 'fixeddatagrid'
  )
}
