export class MockFile {
  constructor(
    public file: {
      name: string
      type: string
      size: number
      data?: string
      error?: any
    }
  ) {
    Object.assign(this, file)
  }
}

export class MockBlob {
  constructor(
    public file: {
      type: string
      size: number
      data?: Blob
      error?: any
    }
  ) {
    Object.assign(this, file)
  }
}

export class MockFileReader {
  onload?: (e: any) => void
  onerror?: () => void
  error?: any
  readAsDataURL(file: MockFile): void {
    if (this.onload) {
      const onloadEvent: any = {
        target: {}
      }
      if (file.file.data) {
        onloadEvent.target.result = file.file.data
        onloadEvent.target.type = file.file.type
      }
      if (file.file.error && this.onerror) {
        this.error = file.file.error
        this.onerror()
      } else {
        this.onload(onloadEvent)
      }
    }
  }
}
