import * as React from 'react'
import { AddAttachmentButtonWeb } from './AddAttachmentButtonWeb'
import { IAttachment } from '../../models/Checklist'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'

export interface IAddAttachmentButtonProps extends IOneplaceLibraryContextProp {
  className: string
  controlId: string
  onAttachmentsSelected: (attachments: IAttachment[]) => void
  style?: React.CSSProperties
  platform: string
  standalone?: false
}

export const AddAttachmentButton = withOneplaceLibraryContext(
  class extends React.Component<IAddAttachmentButtonProps> {
    static displayName = 'AddAttachmentButton'
    render() {
      return (
        <AddAttachmentButtonWeb
          {...(this.props as IAddAttachmentButtonProps)}
        />
      )
    }
  }
)
export default AddAttachmentButton
