import * as React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { ISelectControlProps } from './SelectControl'
import { GRID_CELLPADDING, GRID_CELLFONTSIZE } from './grids/consts'
import Chip from '@material-ui/core/Chip'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'

export default class SelectManyControl extends React.Component<ISelectControlProps> {
  constructor(props: any) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event: any) {
    this.props.form.setFieldValue(this.props.field.name, event.target.value)
  }

  render() {
    const options = this.props.options || []
    const value = this.props.field.value || []
    if (this.props.gridCell) {
      return (
        <div
          id={this.props.id}
          style={{ padding: GRID_CELLPADDING, fontSize: GRID_CELLFONTSIZE }}
        >
          {value.join(', ')}
        </div>
      )
    } else {
      return (
        <Select
          id={this.props.id}
          multiple
          fullWidth
          value={value}
          onChange={this.handleChange}
          renderValue={(selected: any) => (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {selected.map((optionValue: string) => (
                <Chip
                  key={optionValue}
                  label={
                    options.find((option) => option.value == optionValue)!.label
                  }
                  style={{ margin: 4 }}
                />
              ))}
            </div>
          )}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} disabled={false}>
              <Checkbox
                color='primary'
                checked={
                  value.find(
                    (optionValue: string) => optionValue == option.value
                  ) != null
                }
                disabled={false}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      )
    }
  }
}
