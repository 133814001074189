import { IIDAndName } from './Entity'
import { IControlProps } from '../components/form_controls/types'
import SelectControl from '../components/form_controls/SelectControl'
import TextControl from '../components/form_controls/TextControl'
import CheckboxControl from '../components/form_controls/CheckboxControl'
import DateControl from '../components/form_controls/DateControl'
import DateTimeControl from '../components/form_controls/DateTimeControl'
import TimeControl from '../components/form_controls/TimeControl'
import PhotoControl from '../components/form_controls/PhotoControl'
import SelectManyControl from '../components/form_controls/SelectManyControl'
import TextAreaControl from '../components/form_controls/TextAreaControl'
import SignatureControl from '../components/form_controls/SignatureControl'
import * as React from 'react'
import Typography from '@material-ui/core/Typography'
import { IIntegrationFieldMapping } from './Integration'

export type CustomFieldType =
  | 'STRING'
  | 'TEXT_AREA'
  | 'DATE'
  | 'DATETIME'
  | 'TIME'
  | 'NUMERIC'
  | 'BOOLEAN'
  | 'SELECT'
  | 'PHOTO'
  | 'SIGNATURE'
  | 'INFORMATION'

  // Extras (not in API)
  | 'SELECT:STR'

export interface ICustomFieldMeta {
  name: string
  type: CustomFieldType
  label: string
  mandatory?: boolean
  helpInfoLabel?: string // Use to look up related helpInfo by label
  visiField?: string
  otherField?: string
  selectionList?: string
  selectMany?: boolean
  readonly?: boolean
  templateData?: string
  showBlankOption?: boolean
  dateFormat?: string
  dateTimeFormat?: string
  timeFormat?: string
  bgColor?: string
  markdownData?: string
}

export interface ICustomField {
  id: null | number
  customFieldId: number
  name: string
  value: any
  type: CustomFieldType
  incidentDetailTab?: boolean // false = investigation tab
  selectMany: boolean
  selectedOptionsList: any[]
  allOptionsList: IIDAndName[]
  imgData: string | null
  templateData?: string
  comment: null
  mandatory: boolean
  dateFormat?: string
  dateTimeFormat?: string
  timeFormat?: string
  readonly?: boolean
  integrationFieldMapping?: IIntegrationFieldMapping
  bgColor?: string
  markdownData?: string
}

export const InformationControl = (props: IControlProps) =>
  React.createElement(
    Typography,
    { style: { marginTop: 16, whiteSpace: 'pre-line' } },
    props.field.value
  )

/**
 * SELECT - value stored as an object with an id, e.g. { id: 123 }
 * SELECT_STRING - value stored as a string (e.g. "bob")
 */
export function getControlForField(field: ICustomFieldMeta) {
  if (field.type == 'SELECT' && field.selectMany) {
    return SelectManyControl
  }
  switch (field.type) {
    case 'SELECT':
    case 'SELECT:STR':
      return SelectControl
    case 'STRING':
      return TextControl
    case 'TEXT_AREA':
      return TextAreaControl
    case 'DATE':
      return DateControl
    case 'DATETIME':
      return DateTimeControl
    case 'TIME':
      return TimeControl
    case 'BOOLEAN':
      return CheckboxControl
    case 'SIGNATURE':
      return SignatureControl
    case 'PHOTO':
      return PhotoControl
    case 'INFORMATION':
      return InformationControl
    default:
      return TextControl
  }
}

export interface IHelpInfo {
  helpType: string
  label: string // use to map to field
  description: string
  helpLinks: [
    {
      link: string
      type: string
      id: number
    }
  ]
  id: number
}
