import { IQuestionIconOptions } from '../../../models/Checklist'

export const ITEM_HEIGHT = 54
export const MAX_ICONS = 3
export function getMenuIconCount(
  questionIconOptions: IQuestionIconOptions
): number {
  let cnt = 0
  const maxIconLimit = MAX_ICONS + 1
  if (questionIconOptions.helpImageIcon) {
    cnt++
    if (cnt < maxIconLimit) {
      questionIconOptions.helpImageIconShow = true
    }
  }
  if (questionIconOptions.helpTextIcon) {
    cnt++
    if (cnt < maxIconLimit) {
      questionIconOptions.helpTextIconShow = true
    }
  }
  if (questionIconOptions.commentIcon) {
    cnt++
    if (cnt < maxIconLimit) {
      questionIconOptions.commentIconShow = true
    }
  }
  if (questionIconOptions.photoIcon) {
    cnt++
    if (cnt < maxIconLimit) {
      questionIconOptions.photoIconShow = true
    }
  }
  if (questionIconOptions.attachmentIcon) {
    cnt++
    if (cnt < maxIconLimit) {
      questionIconOptions.attachmentIconShow = true
    }
  }

  return cnt
}
export function hasHelp(help: any): boolean {
  if (help) {
    if (Array.isArray(help)) {
      return help.length > 0
    } else {
      return true
    }
  } else {
    return false
  }
}
export type IconStatusType = 'mandatory' | 'completed' | 'not_done'

export function menuIconClass(
  commentStatus: IconStatusType,
  photoStatus: IconStatusType,
  attachmentStatus: IconStatusType
): IconStatusType {
  if (
    commentStatus == 'mandatory' ||
    photoStatus == 'mandatory' ||
    attachmentStatus == 'mandatory'
  ) {
    return 'mandatory'
  } else if (
    commentStatus == 'completed' ||
    photoStatus == 'completed' ||
    attachmentStatus == 'completed'
  ) {
    return 'completed'
  } else {
    return 'not_done'
  }
}
