import * as React from 'react'
import {
  IChecklistGroup,
  IGroupRenderProps,
  IChecklistTicketJson,
  IChecklistField,
  IFieldRenderProps,
  IPhoto
} from '../../models/Checklist'
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'
import Question from './Question'
import Card from '@material-ui/core/Card'

import Typography from '@material-ui/core/Typography'
import { Gallery } from '../photos/Gallery'
import Button from '@material-ui/core/Button'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'
import { withTabsContext, ITabsContextProp } from '../common/Tabs'
const styles = (_theme: Theme) =>
  createStyles({
    galleryWrapper: {
      margin: '12px 0',
      padding: '12px 24px'
    },
    dividerWrapper: {
      marginTop: 16,
      marginBottom: 16
    },
    galleryHeading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  })

const BATCH_RENDER_COUNT = 10
const BATCH_RENDER_DELAY = 100

export interface IQuestionGroupProps
  extends WithStyles<typeof styles>,
    ITabsContextProp,
    IOneplaceLibraryContextProp {
  groupIndex: number
  group: IChecklistGroup
  renderProps: IGroupRenderProps
  questionsNeedRefresh: boolean
  onFieldChange: (groupIndex: number, fieldId: number) => void
  onTicketPreset: (
    question: IChecklistField | null,
    preset: IChecklistTicketJson
  ) => void
  validateConditionalQuestion?: (
    newValue: string,
    question: IChecklistField | null
  ) => void
  dateTimeFormat: string
  dateFormat: string
  hideGroupPhotos: boolean
  hideQuestionNumber?: boolean
  fullName?: string
  onDeletePhoto?: (photo: IPhoto) => void
  allowSavedPhotoDeletion?: boolean
  allowPhotosFromGallery?: boolean
  disableTabLinks?: boolean
}

export interface IQuestionGroupState {
  groupIdx: number
  questionsRendered: number
}

export default withStyles(styles)(
  withOneplaceLibraryContext(
    withTabsContext(
      class QuestionGroup extends React.Component<
        IQuestionGroupProps,
        IQuestionGroupState
      > {
        public static defaultProps = {
          allowSavedPhotoDeletion: false
        }
        timer: any = null
        stillLoading = false

        constructor(props: any) {
          super(props)
          this.state = {
            groupIdx: this.props.groupIndex,
            questionsRendered: 0
          }
        }

        static getDerivedStateFromProps(
          props: IQuestionGroupProps,
          state: IQuestionGroupState
        ) {
          if (state.groupIdx != props.groupIndex) {
            return {
              groupIdx: props.groupIndex,
              questionsRendered: 0
            }
          }
          return null
        }

        shouldComponentUpdate(
          nextProps: IQuestionGroupProps,
          nextState: IQuestionGroupState
        ) {
          if (nextProps.groupIndex != this.props.groupIndex) {
            return true
          } else if (
            nextState.questionsRendered != this.state.questionsRendered
          ) {
            return true
          } else if (nextProps.questionsNeedRefresh) {
            // added questionsNeedRefresh flag for rendering conditional question changes
            // questionsRendered number check above is not enough because it's possible questions on the page are changing
            // but the total number remain the same (e.g: two are hidden and another two show)
            return true
          } else if (nextProps.disableTabLinks != this.props.disableTabLinks) {
            return true
          } else if (nextProps.hideGroupPhotos !== this.props.hideGroupPhotos) {
            return true
          }
          return false
        }

        componentWillUnmount() {
          if (this.timer) {
            clearTimeout(this.timer)
          }
        }

        componentDidUpdate() {
          if (this.props.tabs) {
            if (this.stillLoading) {
              this.props.tabs.notifyUpdate()
            } else {
              // make sure a scroll is applied if needed
              this.props.tabs.notifyUpdate(true)
            }
          }
        }

        render() {
          //console.log('group render', Date.now())
          if (!this.props.group.photos) {
            this.props.group.photos = []
          }

          const renderFields: IChecklistField[] = []
          const fieldRenderProps: IFieldRenderProps[] = []
          this.props.group.fields.forEach((field, fieldIdx) => {
            const renderProps = this.props.renderProps.fields[fieldIdx]
            if (!renderProps.hidden) {
              renderFields.push(field)
              fieldRenderProps.push(renderProps)
            }
          })

          this.stillLoading = false
          if (this.state.questionsRendered < renderFields.length) {
            this.stillLoading = true
            const newCount = Math.min(
              this.state.questionsRendered + BATCH_RENDER_COUNT,
              renderFields.length
            )
            this.timer = setTimeout(() => {
              //console.log('new render count', newCount)
              this.setState({
                questionsRendered: newCount
              })
            }, BATCH_RENDER_DELAY)
          }
          return (
            <div key={this.props.groupIndex} role='list'>
              {renderFields
                .slice(0, this.state.questionsRendered)
                .map((field, idx) => (
                  <Question
                    key={idx}
                    cardKey={idx}
                    groupIndex={this.props.groupIndex}
                    question={field}
                    dateTimeFormat={this.props.dateTimeFormat}
                    dateFormat={this.props.dateFormat}
                    fullName={this.props.fullName}
                    imageStorage={this.props.ctx.imageStorage}
                    renderProps={fieldRenderProps[idx]}
                    onFieldChange={this.props.onFieldChange}
                    onTicketPreset={this.props.onTicketPreset}
                    validateConditionalQuestion={
                      this.props.validateConditionalQuestion
                    }
                    hideQuestionNumber={this.props.hideQuestionNumber}
                    onDeletePhoto={this.props.onDeletePhoto}
                    allowSavedPhotoDeletion={this.props.allowSavedPhotoDeletion}
                    allowPhotosFromGallery={this.props.allowPhotosFromGallery}
                  />
                ))}
              {this.stillLoading && this.state.questionsRendered > 0 && (
                <Typography style={{ padding: 12 }}>Loading...</Typography>
              )}
              {!this.stillLoading && (
                <div>
                  {!this.props.hideGroupPhotos && (
                    <Card className={this.props.classes.galleryWrapper}>
                      <Gallery
                        galleryId={`group${this.props.groupIndex}`}
                        photos={this.props.group.photos}
                        imageStorage={this.props.ctx.imageStorage}
                        onPhotosUpdated={() =>
                          this.props.onFieldChange(this.props.groupIndex, 0)
                        }
                        isMultipleComment={true}
                        fullName={this.props.fullName}
                        onDeletePhoto={this.props.onDeletePhoto}
                        allowSavedPhotoDeletion={
                          this.props.allowSavedPhotoDeletion
                        }
                        allowPhotosFromGallery={
                          this.props.allowPhotosFromGallery
                        }
                      />
                    </Card>
                  )}
                  {this.props.tabs && (
                    <div style={{ textAlign: 'right', marginBottom: 24 }}>
                      <Button
                        variant='contained'
                        color='primary'
                        style={{ marginRight: 12 }}
                        disabled={
                          this.props.tabs.previousTabDisabled ||
                          this.props.disableTabLinks
                        }
                        onClick={this.props.tabs.previousTab}
                      >
                        {this.props.ctx.i18next.t('previous')}
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        style={{ marginRight: 12 }}
                        disabled={
                          this.props.tabs.nextTabDisabled ||
                          this.props.disableTabLinks
                        }
                        onClick={this.props.tabs.nextTab}
                      >
                        {this.props.ctx.i18next.t('next')}
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        }
      }
    )
  )
)
