import * as React from 'react'
import ReactTable, { Column } from 'react-table'
import { IControlProps } from '../types'
import { IDataGridFieldProps, IChecklistField } from '../../../models/Checklist'
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CommentIcon from '@material-ui/icons/Comment'
import PhotoIcon from '@material-ui/icons/PhotoCamera'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { GRID_CELLPADDING } from './consts'
import { fieldControls } from '../../checklists/utils/basicControls'
import { fieldToControlValue } from '../../checklists/utils/controlValues'
import { fieldToControlProps } from '../../checklists/utils/controlProps'
import { UnsupportedControl } from '../UnsupportedControl'
import { DeepPartial } from '../../../types'
import {
  GridFieldDialog,
  IGridFieldDialogButtons,
  FieldPostSaveAction
} from './GridFieldDialog'
import {
  getDialogButtonsEnabbled,
  getPreviousCellIdx,
  getNextCellIdx
} from './utils'
import { hasAttachments, hasPhotos } from '../../checklists/utils/attachments'

const styles = (theme: Theme) =>
  createStyles({
    mandatoryQuestion: {
      color: theme.palette.secondary.main
    }
  })

export interface IFixedDataGridControlProps
  extends IControlProps,
    WithStyles<typeof styles> {}

export interface IDataGridState {
  fieldDialogOpen: boolean
  fieldDialogTitle?: string
  fieldDialogRowIdx?: number
  fieldDialogColIdx?: number
  fieldDialogField?: IChecklistField
  fieldDialogFieldDef?: IChecklistField
  fieldDialogButtons?: IGridFieldDialogButtons
}

const SUPPORTED_FIELD_TYPES = [
  'checkbox',
  'select',
  'yesno',
  'number',
  'subtotal'
]

export default withStyles(styles)(
  class FixedDataGridControl extends React.Component<
    IFixedDataGridControlProps,
    IDataGridState
  > {
    columns: Column[]

    constructor(props: any) {
      super(props)

      this.fieldCellOnClick = this.fieldCellOnClick.bind(this)
      this.fieldCell = this.fieldCell.bind(this)
      this.fieldDialogOnSave = this.fieldDialogOnSave.bind(this)
      this.fieldDialogOnClose = this.fieldDialogOnClose.bind(this)
      this.onDataUpdated = this.onDataUpdated.bind(this)

      this.state = {
        fieldDialogOpen: false
      }

      // configure columns
      const dataGrid = this.getValue()
      this.columns = dataGrid.columnLabels.map(
        (col, colIdx): Column => {
          const header = (
            <div
              style={{
                height: '3.2rem',
                whiteSpace: 'pre-wrap',
                padding: 5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div role='columnlabel'>{col.label}</div>
            </div>
          )
          return {
            Header: header,
            accessor: String(colIdx),
            minWidth: 150,
            Cell: this.fieldCell
          }
        }
      )
      this.columns.unshift({
        Header: '',
        minWidth: 150,
        Cell: (cellInfo) => {
          const rowIdx = cellInfo.index
          const field: IChecklistField = dataGrid.rowLabels[rowIdx].field!
          const labelClass = field.mandatory
            ? this.props.classes.mandatoryQuestion
            : ''
          return (
            <div
              style={{
                padding: GRID_CELLPADDING,
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              className={labelClass}
              role='rowlabel'
            >
              {field.label}
            </div>
          )
        }
      })
    }

    getValue(): IDataGridFieldProps {
      return this.props.field.value
    }

    fieldCellOnClick(rowIdx: number, colIdx: number) {
      const grid = this.getValue()
      const data = grid.values!
      const field = data[rowIdx][colIdx]
      const fieldDef = grid.rowLabels[rowIdx].field!
      const col = grid.columnLabels[colIdx]
      const buttons = getDialogButtonsEnabbled({
        rowCount: data.length,
        colCount: data[0].length,
        rowIdx,
        colIdx
      })
      console.log('new field value', field.value)
      this.setState({
        fieldDialogOpen: true,
        fieldDialogTitle: col.label,
        fieldDialogRowIdx: rowIdx,
        fieldDialogColIdx: colIdx,
        fieldDialogField: field,
        fieldDialogFieldDef: fieldDef,
        fieldDialogButtons: buttons
      })
    }

    fieldCell(cellInfo: any) {
      const grid = this.getValue()
      const data = grid.values!
      const rowIdx = Number(cellInfo.index)
      const colIdx = Number(cellInfo.column.id)
      const field = data[rowIdx][colIdx]
      const fieldDef = grid.rowLabels[rowIdx].field!

      let Control: React.ComponentType<IControlProps>
      if (!SUPPORTED_FIELD_TYPES.includes(field.type)) {
        Control = UnsupportedControl
      } else {
        Control = fieldControls[field.type]
        if (!Control) {
          Control = UnsupportedControl
        }
      }

      const controlId = `Q${field.id}_${rowIdx}_${colIdx}`
      const controlProps: DeepPartial<IControlProps> = {
        field: {
          name: controlId,
          value: fieldToControlValue(field)
        },
        gridCell: true,
        id: controlId
      }

      // Add field-specific control properties
      fieldToControlProps(fieldDef, controlProps)

      return (
        <div
          id={controlId + '_wrapper'}
          style={{
            height: '100%',
            minHeight: 26,
            cursor: 'pointer',
            display: 'flex'
          }}
          onClick={this.fieldCellOnClick.bind(this, rowIdx, colIdx)}
        >
          <div
            style={{
              flex: '1 1',
              overflow: 'hidden'
            }}
          >
            <Control {...(controlProps as IControlProps)} />
          </div>
          {(field.comment || field.photos) && (
            <div style={{ marginTop: 5, marginRight: 3, color: '#AAA' }}>
              {field.comment && <CommentIcon />}
              {hasPhotos(field.photos!) && <PhotoIcon />}
              {hasAttachments(field.photos!) && <AttachFileIcon />}
            </div>
          )}
        </div>
      )
    }

    fieldDialogOnSave(
      fieldRowIdx: number,
      fieldColIdx: number,
      field: IChecklistField,
      action: FieldPostSaveAction
    ) {
      const data = this.getValue().values!
      console.log('onGridSave', fieldRowIdx, fieldColIdx, field, action)
      data[fieldRowIdx][fieldColIdx] = field
      this.onDataUpdated(data)

      const rowCount = data.length
      const colCount = data[0].length

      if (action == 'previous') {
        const { rowIdx, colIdx } = getPreviousCellIdx({
          rowCount,
          colCount,
          rowIdx: fieldRowIdx,
          colIdx: fieldColIdx
        })
        this.fieldCellOnClick(rowIdx, colIdx)
      } else if (action == 'next') {
        const { rowIdx, colIdx } = getNextCellIdx({
          rowCount,
          colCount,
          rowIdx: fieldRowIdx,
          colIdx: fieldColIdx
        })
        this.fieldCellOnClick(rowIdx, colIdx)
      } else {
        this.setState({ fieldDialogOpen: false })
      }
    }

    fieldDialogOnClose() {
      this.setState({ fieldDialogOpen: false })
    }

    onDataUpdated(newData: IChecklistField[][]) {
      const dataGrid = this.getValue()
      const data = [...newData]
      dataGrid.values = data
      this.props.form.setFieldValue(this.props.field.name, dataGrid)
    }

    render() {
      const data = this.getValue().values!
      return (
        <Typography component='div'>
          <ReactTable
            columns={this.columns}
            data={data}
            sortable={false}
            showPagination={false}
            minRows={0}
          />
          {this.state.fieldDialogOpen && (
            <GridFieldDialog
              isOpen={this.state.fieldDialogOpen}
              title={this.state.fieldDialogTitle!}
              rowIdx={this.state.fieldDialogRowIdx!}
              colIdx={this.state.fieldDialogColIdx!}
              field={this.state.fieldDialogField!}
              fieldDef={this.state.fieldDialogFieldDef!}
              buttonsEnabled={this.state.fieldDialogButtons!}
              imageStorage={this.props.imageStorage!}
              onSave={this.fieldDialogOnSave}
              onClose={this.fieldDialogOnClose}
              onTicketPreset={this.props.onTicketPreset}
              parentId={this.props.fieldId}
              parentType='fixeddatagrid'
              onDeletePhoto={this.props.onDeletePhoto}
              allowSavedPhotoDeletion={this.props.allowSavedPhotoDeletion}
              allowPhotosFromGallery={this.props.allowPhotosFromGallery}
            />
          )}
        </Typography>
      )
    }
  }
)
