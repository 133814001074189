import React from 'react'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'

import { IPhoto } from '../..'
import { CordovaCamera } from './CordovaCamera'
import { HtmlCamera } from './HtmlCamera'

export interface ICameraProps extends IOneplaceLibraryContextProp {
  open: boolean
  onPhotoTaken: (photo: IPhoto) => void
  onCloseCamera: () => void //only works for html camera
}

/**
 * The HTML camera activate while open is true while the native camera
 * works when we change open to true. This avoid consequent rerender
 * of the component to activate the camera again.
 */
export const Camera = withOneplaceLibraryContext((props: ICameraProps) => {
  return props.ctx.env.platform === 'cordova' &&
    props.ctx.env.os !== 'browser' ? (
    <CordovaCamera onPhotoTaken={props.onPhotoTaken} open={props.open} />
  ) : (
    <HtmlCamera
      open={props.open}
      onPhotoTaken={props.onPhotoTaken}
      onCloseCamera={props.onCloseCamera}
    />
  )
})
