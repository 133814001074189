import * as React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import IconButton from '@material-ui/core/IconButton'
import CommentIcon from '@material-ui/icons/Comment'
import HelpIcon from '@material-ui/icons/Help'
import HelpImageIcon from '@material-ui/icons/Image'
import Collapse from '@material-ui/core/Collapse'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import {
  IChecklistField,
  IPhoto,
  IChecklistTicketJson,
  IAttachment,
  IQuestionIconOptions
} from '../../../models/Checklist'
import { fieldControls } from '../../checklists/utils/basicControls'
import {
  fieldToControlValue,
  controlValueToField
} from '../../checklists/utils/controlValues'
import { fieldToControlProps } from '../../checklists/utils/controlProps'
import { UnsupportedControl } from '../UnsupportedControl'
import { IControlProps } from '../types'
import { PhotoDialog } from '../../photos/PhotoDialog'
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'
import { DeepPartial } from '../../../types'
import { deepCopy } from '../../../utils'
import { IImageStorage } from '../../../data_sources/imagestorage/imageStorage'
import { PhotosField } from '../../photos/PhotosField'
import { presetTicketMatch } from '../../checklists/utils/shouldShowQuestionTicket'
import { HelpImages } from '../../checklists/HelpImages'
import {
  Typography,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core'
import { BasicDialog } from '../../common/BasicDialog'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../../OneplaceLibraryProvider'
import AddPhotoButton from '../../photos/AddPhotoButton'
import { isAllowed, isMandatory } from '../../checklists/utils/accessoryFields'
import AddAttachmentButton from '../../attachments/AddAttachmentButton'
import { AttachmentsField } from '../../attachments/AttachmentsField'
import AttachmentDialog from '../../attachments/AttachmentDialog'
import { AttachmentsUploadDialog } from '../../attachments/AttachmentsUploadDialog'
import {
  hasAttachments,
  hasPhotos,
  createPhotoAttachment
} from '../../checklists/utils/attachments'
import {
  IconStatusType,
  hasHelp,
  getMenuIconCount,
  menuIconClass,
  ITEM_HEIGHT,
  MAX_ICONS
} from '../../checklists/utils/questionIcons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { makePhotosPermanent } from '../../photos/utils/imageUtils'
import { Camera } from '../../photos/Camera'

const styles = (theme: Theme) =>
  createStyles({
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    actionsContainer: {
      display: 'flex',
      maxWidth: '150px',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    },
    mandatoryQuestion: {
      color: theme.palette.secondary.main
    },
    moreVertHasContent: {
      margin: '0 12px',
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
      '&:hover': { backgroundColor: theme.palette.primary.light, color: '#fff' }
    },
    moreVertMandatoryContent: {
      margin: '0 12px',
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: '#fff'
      }
    },
    moreVert: {
      margin: '0 12px',
      backgroundColor: theme.palette.action.active,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.action.active,
        color: '#fff'
      }
    },
    nonMandatoryQuestion: {
      color: '#000'
    },
    hasContent: {
      color: theme.palette.primary.light
    },
    mandatoryContent: {
      color: theme.palette.secondary.main
    },
    dialogHeader: {
      fontSize: '1.3125rem',
      fontWeight: 500
    },
    questionWrapper: {
      display: 'flex',
      margin: '12px 0',
      padding: '0 24px 12px'
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: '1 1 auto'
    },
    cover: {
      flex: '0 0 51px'
    }
  })

export interface IGridFieldDialogButtons {
  previous: boolean
  next: boolean
  addRow: boolean
}

export type FieldPostSaveAction = 'next' | 'previous' | 'add_row' | 'none'

export interface IGridFieldDialogProps
  extends WithStyles<typeof styles>,
    IOneplaceLibraryContextProp {
  isOpen: boolean
  title: string
  rowIdx: number
  colIdx: number
  field: IChecklistField
  fieldDef: IChecklistField
  buttonsEnabled: IGridFieldDialogButtons
  imageStorage: IImageStorage
  onSave: (
    rowIdx: number,
    colIdx: number,
    field: IChecklistField,
    action: FieldPostSaveAction
  ) => void
  onClose: () => void
  onTicketPreset?: (
    question: IChecklistField | null,
    preset: IChecklistTicketJson
  ) => void
  dateTimeFormat?: string
  fullName?: string
  parentId?: number
  parentType?: string
  onDeletePhoto?: (photo: IPhoto) => void
  allowSavedPhotoDeletion?: boolean
  allowPhotosFromGallery?: boolean
}

export interface IGridFieldDialogState {
  field: IChecklistField
  rowIdx: number
  colIdx: number
  value: any
  commentShown: boolean
  comment: string
  photoDialogMode: 'new' | 'edit'
  photoDialogPhoto: IPhoto | null
  photoDialogOpen: boolean
  helpDialogOpen: boolean
  helpDialogTitle: string
  helpDialogContent: React.ReactElement<any>
  helpImagesOpen: boolean
  helpImagesList: Array<{ url: string }>
  attachmentDialogAttachment: IPhoto | null
  attachmentDialogOpen: boolean
  selectedAttachments: IAttachment[]
  attachmentsUploadDialogOpen: boolean
  iconMenuAnchor: any
  cameraOpen: boolean
}

export const GridFieldDialog = withStyles(styles)(
  withOneplaceLibraryContext(
    class extends React.Component<
      IGridFieldDialogProps,
      IGridFieldDialogState
    > {
      static displayName = 'GridFieldDialog'
      constructor(props: any) {
        super(props)
        this.state = {} as IGridFieldDialogState
        this.setFieldValue = this.setFieldValue.bind(this)
        this.onOK = this.onOK.bind(this)
        this.onNext = this.onNext.bind(this)
        this.onPrevious = this.onPrevious.bind(this)
        this.onAddRow = this.onAddRow.bind(this)
        this.onChangeComment = this.onChangeComment.bind(this)
        this.onCommentIconClicked = this.onCommentIconClicked.bind(this)
        this.onPhotoUploaded = this.onPhotoUploaded.bind(this)
        this.onPhotoSaved = this.onPhotoSaved.bind(this)
        this.onPhotoDeleted = this.onPhotoDeleted.bind(this)
        this.onPhotoClicked = this.onPhotoClicked.bind(this)
        this.onPhotoDialogClosed = this.onPhotoDialogClosed.bind(this)
        this.onMenuItemClick = this.onMenuItemClick.bind(this)
        this.onMenuItemClose = this.onMenuItemClose.bind(this)
      }

      static getDerivedStateFromProps(
        props: IGridFieldDialogProps,
        state: IGridFieldDialogState
      ): IGridFieldDialogState | null {
        if (
          !state.field ||
          state.field.id != props.field.id ||
          state.rowIdx != props.rowIdx ||
          state.colIdx != props.colIdx
        ) {
          return {
            iconMenuAnchor: null,
            field: deepCopy(props.field),
            rowIdx: props.rowIdx,
            colIdx: props.colIdx,
            value: fieldToControlValue(props.field),
            commentShown: Boolean(props.field.comment),
            comment: props.field.comment,
            photoDialogMode: 'new',
            photoDialogPhoto: null,
            photoDialogOpen: false,
            helpDialogOpen: false,
            helpDialogTitle: '',
            helpDialogContent: null as any,
            helpImagesOpen: false,
            helpImagesList: [],
            attachmentDialogAttachment: null,
            attachmentDialogOpen: false,
            selectedAttachments: [],
            attachmentsUploadDialogOpen: false,
            cameraOpen: false
          }
        }
        return null
      }

      setFieldValue(_fieldName: string, newValue: any): void {
        const field = this.state.field
        controlValueToField(newValue, field)
        this.setState({
          field,
          value: newValue
        })
        const match = presetTicketMatch(this.props.fieldDef, field.value)
        if (match) {
          this.props.onTicketPreset!(this.state.field, match)
        }
      }

      onOK(): void {
        this.props.onSave(
          this.props.rowIdx,
          this.props.colIdx,
          this.state.field,
          'none'
        )
      }

      onNext(): void {
        this.props.onSave(
          this.props.rowIdx,
          this.props.colIdx,
          this.state.field,
          'next'
        )
      }

      onPrevious(): void {
        this.props.onSave(
          this.props.rowIdx,
          this.props.colIdx,
          this.state.field,
          'previous'
        )
      }

      onAddRow(): void {
        this.props.onSave(
          this.props.rowIdx,
          this.props.colIdx,
          this.state.field,
          'add_row'
        )
      }

      onCommentIconClicked(): void {
        this.setState((state) => ({
          commentShown: !state.commentShown,
          iconMenuAnchor: null
        }))
      }

      onChangeComment(event: any): void {
        const comment = event.target.value
        const field = this.state.field
        field.comment = comment
        this.setState({ field, comment })
      }

      ///////////// Attachment Methods /////////////
      onAttachmentsSelected = (attachments: IAttachment[]) => {
        this.setState(
          {
            selectedAttachments: attachments,
            attachmentsUploadDialogOpen: true,
            iconMenuAnchor: null
          },
          () => {
            this.state.selectedAttachments
              .filter((att) => att.status === null)
              .forEach((att) => this.saveAttachment(att))
          }
        )
      }

      saveAttachment = (attachment: IAttachment) => {
        if (
          attachment.status === 'Saving' ||
          attachment.status === 'Saved' ||
          attachment.localObjectUrl === null
        ) {
          return
        }

        attachment.status = 'Saving'
        attachment.forEntity = 'checklist'
        attachment.fieldId = this.props.parentId
        attachment.colIdx =
          this.props.colIdx + (this.props.parentType == 'fixeddatagrid' ? 2 : 1)
        attachment.rowIdx = this.props.rowIdx + 1

        this.updateAttachment(attachment)

        this.props.ctx.client
          .saveAttachment(attachment)
          .then((response) => {
            if (attachment.status !== 'Canceled') {
              attachment.uuid = response.UUID
              attachment.url = response.url
              attachment.status = 'Saved'
              this.addSavedAttachmentToField(attachment)
              this.revokeURLForAttachment(attachment.localObjectUrl)
            }
          })
          .catch((error) => {
            console.error(
              'Error uploading attachment: ' + JSON.stringify(error)
            )
            if (attachment.status !== 'Canceled') {
              attachment.status = 'Error'
            }
          })
          .finally(() => {
            this.updateAttachment(attachment)
          })
      }

      addSavedAttachmentToField(attachment: IAttachment) {
        const field = this.state.field
        if (field.photos === undefined || field.photos === null) {
          field.photos = []
        }
        field.photos.push(createPhotoAttachment(attachment))
      }

      onRetryUpload = (attachment: IAttachment) => {
        this.saveAttachment(attachment)
      }

      onCancelUpload = (attachment: IAttachment) => {
        attachment.status = 'Canceled'
        this.revokeURLForAttachment(attachment.localObjectUrl)
        this.updateAttachment(attachment)
      }

      onAttachmentClicked = (attachment: IPhoto) => {
        if (attachment.url) {
          this.setState({
            attachmentDialogAttachment: attachment,
            attachmentDialogOpen: true
          })
        }
      }

      onAttachmentDeleted = (attachment: IAttachment) => {
        const field = this.state.field
        if (!field.photos) {
          return
        }
        const idx = field.photos.findIndex(
          (att) =>
            (attachment.uuid && attachment.uuid === att.uuid) ||
            (attachment.id && attachment.id === att.id)
        )
        if (idx !== -1) {
          field.photos.splice(idx, 1)
          if (attachment.id && attachment.id > 0) {
            this.props.onDeletePhoto?.(createPhotoAttachment(attachment))
          }
        }
        this.setState({
          attachmentDialogAttachment: null,
          attachmentDialogOpen: false
        })
      }

      onAttachmentDialogClosed = () => {
        this.setState({
          attachmentDialogAttachment: null,
          attachmentDialogOpen: false
        })
      }

      onAttachmentsUploadDialogClosed = () => {
        //make sure we revoke all object url
        this.state.selectedAttachments.forEach((att) =>
          this.revokeURLForAttachment(att.localObjectUrl)
        )
        this.setState({
          selectedAttachments: [],
          attachmentsUploadDialogOpen: false
        })
      }

      updateAttachment = (attachment: IAttachment) => {
        this.state.selectedAttachments.forEach((att, index) => {
          if (att.localObjectUrl === attachment.localObjectUrl) {
            const attachmentsCopy = [...this.state.selectedAttachments] //array copy
            /* we don't copy the object because having a
            reference to it allows us to easily and quickly 'cancel' the upload*/
            attachmentsCopy[index] = attachment
            this.setState({ selectedAttachments: attachmentsCopy })
          }
        })
      }

      onAttachmentCommentAdded = (newComment: string) => {
        const field = this.state.field
        if (!field.photos) return

        const attachment = this.state.attachmentDialogAttachment!
        const photo = field.photos.find(
          (currentPhoto) =>
            (attachment.uuid && attachment.uuid === currentPhoto.uuid) ||
            (attachment.id && attachment.id === currentPhoto.id)
        )

        if (photo) {
          photo.comment = newComment
          this.onAttachmentDialogClosed()
        }
      }

      revokeURLForAttachment = (url: string | null | undefined) => {
        if (url) {
          URL.revokeObjectURL(url)
        }
      }
      ///////////// End Attachment Methods /////////////

      async onPhotoUploaded(photos: IPhoto[]) {
        // if user selected only one photo, we show preview and option to add comment
        if (photos.length === 1) {
          this.setState({
            photoDialogMode: 'new',
            photoDialogPhoto: photos[0],
            photoDialogOpen: true,
            iconMenuAnchor: null,
            cameraOpen: false //reset flag (in case we're using native)
          })
        }
        // otherwise we just add all photos
        else {
          await makePhotosPermanent(
            photos,
            this.props.imageStorage,
            this.props.ctx.env.os == 'windows'
          )
          const currPhotos = this.state.field.photos || []
          currPhotos.push(...photos)
          this.setState({
            field: { ...this.state.field, photos: [...currPhotos] }
          })
          this.forceUpdate()
        }
      }

      onPhotoSaved(photo: IPhoto, takeAnotherPhoto: boolean): void {
        if (this.state.photoDialogMode == 'new') {
          const field = this.state.field
          if (!field.photos) {
            field.photos = []
            this.setState({
              field
            })
          }
          field.photos.push(photo)
          this.setState({
            field
          })
        }
        this.setState({
          photoDialogOpen: false,
          cameraOpen: takeAnotherPhoto
        })
      }

      onPhotoDeleted(photo: IPhoto): void {
        if (this.state.field.photos) {
          const photoIdx = this.state.field.photos.findIndex(
            (storedPhoto) => storedPhoto.url == photo.url
          )
          if (photoIdx > -1) {
            // TODO: Delete photo from storage
            this.state.field.photos.splice(photoIdx, 1)
            this.props.onDeletePhoto?.(photo)
          }
        }
        this.setState({
          photoDialogOpen: false
        })
      }

      onPhotoClicked(photo: IPhoto): void {
        this.setState({
          photoDialogMode: 'edit',
          photoDialogPhoto: photo,
          photoDialogOpen: true
        })
      }

      onPhotoDialogClosed(): void {
        this.setState({
          photoDialogPhoto: null,
          photoDialogOpen: false
        })
      }

      onHelpIconClicked = (): void => {
        const field = this.props.field
        const helpDialogTitle = field.header || ''
        const helpDescription = field.description || ''

        const HelpLink = (props: { url: string }) => {
          if (this.props.ctx.env.platform == 'cordova') {
            return (
              <a
                href=''
                onClick={(e) => {
                  e.preventDefault()
                  cordova.InAppBrowser.open(props.url, '_system')
                }}
              >
                {props.url}
              </a>
            )
          } else {
            return (
              <a href={props.url} target='_blank' rel='noopener noreferrer'>
                {props.url}
              </a>
            )
          }
        }

        this.setState({
          helpDialogOpen: true,
          helpDialogTitle,
          helpDialogContent: (
            <Typography component='div' style={{ whiteSpace: 'pre-line' }}>
              {helpDescription}
              {field.helpLinks && field.helpLinks.length && (
                <>
                  <p>
                    <b>Links:</b>
                  </p>
                  <ul>
                    {field.helpLinks.map((link) => (
                      <li key={link.id}>
                        <HelpLink url={link.link} />
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Typography>
          )
        })
        if (this.state.iconMenuAnchor) {
          this.onMenuItemClose()
        }
      }

      onHelpImagesIconClicked = (): void => {
        const field = this.props.field
        if (field.helpImages) {
          this.setState({
            helpImagesOpen: true,
            helpImagesList: field.helpImages
          })
        }
        if (this.state.iconMenuAnchor) {
          this.onMenuItemClose()
        }
      }

      onHelpDialogClosed = (): void => {
        this.setState({ helpDialogOpen: false })
      }

      onHelpImagesClosed = (): void => {
        this.setState({ helpImagesOpen: false })
      }

      onMenuItemClick(event: any): void {
        this.setState({ iconMenuAnchor: event.currentTarget })
      }

      onMenuItemClose(): void {
        this.setState({ iconMenuAnchor: null })
      }

      render(): React.ReactNode {
        let Control = fieldControls[this.props.field.type]
        if (!Control) {
          Control = UnsupportedControl
        }
        const controlId = 'Q' + this.props.field.id
        const controlProps: DeepPartial<IControlProps> = {
          field: {
            name: controlId,
            value: this.state.value
          },
          form: {
            setFieldValue: this.setFieldValue
          },
          id: controlId
        }
        const field = this.state.field

        // Add field-specific control properties
        fieldToControlProps(this.props.fieldDef, controlProps)

        const labelClass = field.mandatory
          ? this.props.classes.mandatoryQuestion
          : ''

        const commentStatus: IconStatusType = this.state.comment
          ? 'completed'
          : isMandatory(field.commentOption)
          ? 'mandatory'
          : 'not_done'

        const photoStatus: IconStatusType = hasPhotos(field.photos!)
          ? 'completed'
          : isMandatory(field.photoOption)
          ? 'mandatory'
          : 'not_done'

        const attachmentStatus: IconStatusType = hasAttachments(field.photos!)
          ? 'completed'
          : isMandatory(field.attachmentOption)
          ? 'mandatory'
          : 'not_done'

        const questionIconOptions: IQuestionIconOptions = {
          commentIcon: isAllowed(this.props.field.commentOption),
          commentIconClass:
            commentStatus == 'completed'
              ? this.props.classes.hasContent
              : commentStatus == 'mandatory'
              ? this.props.classes.mandatoryContent
              : '',
          commentIconShow: false,
          photoIcon: isAllowed(this.props.field.photoOption),
          photoIconClass:
            photoStatus == 'completed'
              ? this.props.classes.hasContent
              : photoStatus == 'mandatory'
              ? this.props.classes.mandatoryContent
              : '',
          photoIconShow: false,
          attachmentIcon: isAllowed(this.props.field.attachmentOption),
          attachmentIconClass:
            attachmentStatus == 'completed'
              ? this.props.classes.hasContent
              : attachmentStatus == 'mandatory'
              ? this.props.classes.mandatoryContent
              : '',
          attachmentIconShow: false,
          helpImageIcon: hasHelp(field.helpImages),
          helpImageIconShow: false,
          helpTextIcon:
            hasHelp(field.header) ||
            hasHelp(field.description) ||
            hasHelp(field.helpLinks),
          helpTextIconShow: false,
          menuIconClass: ''
        }
        const menuCount = getMenuIconCount(questionIconOptions)
        const isMenu = menuCount > MAX_ICONS
        const isMenuStatus = menuIconClass(
          commentStatus,
          photoStatus,
          attachmentStatus
        )
        const isMenuClass =
          isMenuStatus == 'completed'
            ? this.props.classes.moreVertHasContent
            : isMenuStatus == 'mandatory'
            ? this.props.classes.moreVertMandatoryContent
            : this.props.classes.moreVert

        //show   menu icon  or 2 table list
        const iconsOrMenu = isMenu ? (
          <div className={this.props.classes.cover}>
            <IconButton
              size='small'
              id={'menuIcon'}
              onClick={this.onMenuItemClick}
              className={isMenuClass}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={this.state.iconMenuAnchor}
              open={Boolean(this.state.iconMenuAnchor)}
              onClose={this.onMenuItemClose}
              PaperProps={{
                style: {
                  /*Photo icon option has two items and we can't change the
                  menuCount because this would affect the menu render condition*/
                  minHeight:
                    ITEM_HEIGHT *
                    (questionIconOptions.photoIcon ? menuCount + 1 : menuCount)
                }
              }}
            >
              {questionIconOptions.helpImageIcon && (
                <MenuItem
                  onClick={this.onHelpImagesIconClicked}
                  id={'help_image_button'}
                >
                  <ListItemIcon>
                    <HelpImageIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={this.props.ctx.i18next.t('question_help_image')}
                  />
                </MenuItem>
              )}
              {questionIconOptions.helpTextIcon && (
                <MenuItem id={'help_button'} onClick={this.onHelpIconClicked}>
                  <ListItemIcon>
                    <HelpIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={this.props.ctx.i18next.t('question_help')}
                  />
                </MenuItem>
              )}

              {questionIconOptions.photoIcon && (
                <AddPhotoButton
                  env={this.props.ctx.env}
                  controlId={controlId}
                  className={questionIconOptions.photoIconClass}
                  imageStorage={this.props.imageStorage}
                  onPhotoUploaded={this.onPhotoUploaded}
                  standalone={false}
                  allowPhotosFromGallery={this.props.allowPhotosFromGallery}
                />
              )}
              {questionIconOptions.commentIcon && (
                <MenuItem
                  className={questionIconOptions.commentIconClass}
                  id={'comment_button'}
                  onClick={this.onCommentIconClicked}
                >
                  <ListItemIcon
                    className={questionIconOptions.commentIconClass}
                  >
                    <CommentIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={this.props.ctx.i18next.t('question_comment')}
                  />
                </MenuItem>
              )}
              {questionIconOptions.attachmentIcon && (
                <AddAttachmentButton
                  platform={this.props.ctx.env.platform}
                  controlId={controlId}
                  className={questionIconOptions.attachmentIconClass}
                  onAttachmentsSelected={this.onAttachmentsSelected}
                  standalone={false}
                />
              )}
            </Menu>
          </div>
        ) : (
          <div className={this.props.classes.cover}>
            {questionIconOptions.helpImageIconShow && (
              <IconButton
                id={'help_image_button'}
                onClick={this.onHelpImagesIconClicked}
              >
                <HelpImageIcon />
              </IconButton>
            )}
            {questionIconOptions.helpTextIconShow && (
              <IconButton id={'help_button'} onClick={this.onHelpIconClicked}>
                <HelpIcon />
              </IconButton>
            )}
            {questionIconOptions.photoIconShow && (
              <AddPhotoButton
                env={this.props.ctx.env}
                controlId={controlId}
                className={questionIconOptions.photoIconClass}
                imageStorage={this.props.imageStorage}
                onPhotoUploaded={this.onPhotoUploaded}
                allowPhotosFromGallery={this.props.allowPhotosFromGallery}
              />
            )}
            {questionIconOptions.commentIconShow && (
              <IconButton
                id={'comment_button'}
                className={questionIconOptions.commentIconClass}
                onClick={this.onCommentIconClicked}
              >
                <CommentIcon />
              </IconButton>
            )}
            {questionIconOptions.attachmentIconShow && (
              <AddAttachmentButton
                platform={this.props.ctx.env.platform}
                controlId={controlId}
                className={questionIconOptions.attachmentIconClass}
                onAttachmentsSelected={this.onAttachmentsSelected}
              />
            )}
          </div>
        )
        return (
          <div>
            <Dialog
              open={this.props.isOpen}
              onClose={this.props.onClose}
              aria-labelledby='form-dialog-title'
              disableBackdropClick={true}
              PaperProps={{
                style: {
                  width: '90%',
                  maxWidth: 700
                }
              }}
            >
              <DialogTitle disableTypography={true} id='form-dialog-title'>
                <div className={this.props.classes.labelContainer}>
                  <Typography
                    component='h2'
                    className={this.props.classes.dialogHeader}
                  >
                    {this.props.title}
                  </Typography>
                </div>
              </DialogTitle>
              <DialogContent className={this.props.classes.questionWrapper}>
                <div className={this.props.classes.details}>
                  <FormControl fullWidth>
                    <div className={this.props.classes.labelContainer}>
                      <FormLabel htmlFor={controlId} className={labelClass}>
                        {field.label}
                      </FormLabel>
                    </div>
                    <div>
                      <Control {...(controlProps as IControlProps)} />
                    </div>
                  </FormControl>
                  <Collapse in={this.state.commentShown}>
                    <FormControl fullWidth style={{ marginTop: 16 }}>
                      <InputLabel shrink={true}>
                        {this.props.ctx.i18next.t('comment')}
                      </InputLabel>
                      <Input
                        id={controlId + '-comment'}
                        fullWidth
                        multiline
                        value={this.state.comment}
                        onChange={this.onChangeComment}
                      />
                    </FormControl>
                  </Collapse>
                  {hasPhotos(field.photos!) && (
                    <PhotosField
                      photos={field.photos!}
                      onPhotoClicked={this.onPhotoClicked}
                    />
                  )}
                  {hasAttachments(field.photos!) && (
                    <AttachmentsField
                      attachments={field.photos!}
                      onAttachmentClicked={this.onAttachmentClicked}
                    />
                  )}
                  {this.state.photoDialogOpen && (
                    <PhotoDialog
                      isOpen={this.state.photoDialogOpen}
                      mode={this.state.photoDialogMode}
                      photo={this.state.photoDialogPhoto!}
                      imageStorage={this.props.imageStorage}
                      onClose={this.onPhotoDialogClosed}
                      onSave={this.onPhotoSaved}
                      onDelete={this.onPhotoDeleted}
                      isMultipleComment={true}
                      fullName={this.props.fullName}
                      dateTimeFormat={this.props.dateTimeFormat}
                      allowSavedPhotoDeletion={
                        this.props.allowSavedPhotoDeletion
                      }
                    />
                  )}
                  {this.state.attachmentDialogOpen && (
                    <AttachmentDialog
                      attachmentEntity='checklist'
                      isOpen={this.state.attachmentDialogOpen}
                      attachment={this.state.attachmentDialogAttachment!}
                      onClose={this.onAttachmentDialogClosed}
                      onDelete={this.onAttachmentDeleted}
                      onAddComment={this.onAttachmentCommentAdded}
                    />
                  )}
                  {this.state.attachmentsUploadDialogOpen && (
                    <AttachmentsUploadDialog
                      isOpen={this.state.attachmentsUploadDialogOpen}
                      attachments={this.state.selectedAttachments}
                      onClose={this.onAttachmentsUploadDialogClosed}
                      onRetryUpload={this.onRetryUpload}
                      onCancelUpload={this.onCancelUpload}
                    />
                  )}
                  {this.state.helpDialogOpen && (
                    <BasicDialog
                      isOpen={this.state.helpDialogOpen}
                      title={this.state.helpDialogTitle}
                      content={this.state.helpDialogContent}
                      onClose={this.onHelpDialogClosed}
                    />
                  )}
                  {this.state.helpImagesOpen && (
                    <HelpImages
                      isOpen={this.state.helpImagesOpen}
                      imageUrls={this.state.helpImagesList}
                      onClose={this.onHelpImagesClosed}
                    />
                  )}
                </div>
                {iconsOrMenu}
              </DialogContent>
              <DialogActions>
                <Button
                  id='previous_button'
                  color='primary'
                  onClick={this.onPrevious}
                  disabled={!this.props.buttonsEnabled.previous}
                >
                  {this.props.ctx.i18next.t('previous')}
                </Button>
                {!this.props.buttonsEnabled.addRow && (
                  <Button
                    id='next_button'
                    color='primary'
                    onClick={this.onNext}
                    disabled={!this.props.buttonsEnabled.next}
                  >
                    {this.props.ctx.i18next.t('next')}
                  </Button>
                )}
                {this.props.buttonsEnabled.addRow && (
                  <Button
                    id='add_row_button'
                    color='primary'
                    onClick={this.onAddRow}
                  >
                    {this.props.ctx.i18next.t('add_row')}
                  </Button>
                )}
                <Button id='ok_button' color='primary' onClick={this.onOK}>
                  {this.props.ctx.i18next.t('ok')}
                </Button>
              </DialogActions>
            </Dialog>
            <Camera
              open={this.state.cameraOpen}
              onPhotoTaken={(photo: IPhoto) => {
                this.onPhotoUploaded([photo])
                this.setState({ cameraOpen: false })
              }}
              onCloseCamera={() => this.setState({ cameraOpen: false })}
            />
          </div>
        )
      }
    }
  )
)
