import * as React from 'react'
import { AddPhotoButtonCordova } from './AddPhotoButtonCordova'
import { AddPhotoButtonWeb } from './AddPhotoButtonWeb'
import { IPhoto } from '../../models/Checklist'
import { IImageStorage } from '../../data_sources/imagestorage/imageStorage'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'
import { IEnvironment } from '../../environments'

export interface IAddPhotoButtonProps extends IOneplaceLibraryContextProp {
  className: string
  controlId: string
  imageStorage: IImageStorage
  onPhotoUploaded: (photo: IPhoto[]) => void
  style?: React.CSSProperties
  env: IEnvironment
  standalone?: boolean
  allowMultipleFiles?: boolean
  allowPhotosFromGallery?: boolean
}

export const AddPhotoButton = withOneplaceLibraryContext(
  class extends React.Component<IAddPhotoButtonProps> {
    static displayName = 'AddPhotoButton'

    render() {
      const TagName =
        this.props.env.platform == 'cordova' && this.props.env.os != 'browser'
          ? AddPhotoButtonCordova
          : AddPhotoButtonWeb
      return <TagName {...(this.props as IAddPhotoButtonProps)} />
    }
  }
)
export default AddPhotoButton
