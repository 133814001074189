import {
  IChecklistField,
  IChecklist,
  IChecklistGroup
} from '../../../models/Checklist'
import { containsIgnoringCase } from '../../../utils/stringUtil'
import {
  IChecklistConditionalRule,
  IChecklistCondition,
  SELECT_OPTIONS_DELIMITER
} from '../../../models/ConditionalRule'

export function findChecklistGroupById(
  groupId: number | undefined,
  checklist: IChecklist
): IChecklistGroup | undefined {
  return checklist.groups.find((group) => group.id == groupId)
}

export function isConditionalRuleShow(
  conditionalRule: IChecklistConditionalRule,
  conditionalRulesList: IChecklistConditionalRule[]
): boolean {
  let isApplied
  if ('OR' == conditionalRule.logicalOperator) {
    isApplied = conditionalRule.conditions!.some((c) => c.applied)
  } else {
    // and
    isApplied = !conditionalRule.conditions!.some((c) => !c.applied)
  }
  if (isApplied) {
    if (conditionalRule.parentConditionalRuleId > 0) {
      const parentConditional = conditionalRulesList.find(
        (c) =>
          c.id == conditionalRule.parentConditionalRuleId &&
          c.row == conditionalRule.row
      )
      return isConditionalRuleShow(parentConditional!, conditionalRulesList)
    }
    return true
  }
  return false
}
// return true if condition is applied
export function hasConditionApplied(
  newValue: string | string[],
  question: IChecklistField,
  condition: IChecklistCondition
): boolean {
  if (question.hidden) {
    // if question is not displayed on the page, the condition should not applied
    return false
  }
  // check for answered/ananswered conditions
  if (question.type == 'checkbox') {
    if (!newValue && condition.value == 'UNTI') {
      // 'checkbox unticked' is applied by default
      return true
    }
  } else {
    if (newValue == null || newValue == '') {
      if (condition.matchType == 'INT') {
        if (question.type == 'select' || question.type == 'select_many') {
          return true
        }
      }
      if (condition.matchType == 'ADNI') {
        return true
      }
      if (condition.matchType == 'AI') {
        return false
      }
      if (condition.matchType == 'ISTA') {
        return true
      } else {
        return false
      }
    }
    if (newValue != null && newValue != '') {
      if (condition.matchType == 'ISAN') {
        return true
      }
      if (condition.matchType == 'ISTA') {
        return false
      }
    }
  }
  // check for other conditions depends on type
  switch (question.type) {
    case 'yesno':
      if (condition.value == null) {
        return false
      }
      if (condition.matchType == 'IS') {
        return newValue == condition.value
      } else if (condition.matchType == 'INT') {
        return newValue != condition.value
      }
      break
    case 'select':
      if (condition.value == undefined || condition.value == '') {
        return false
      }
      if (condition.matchType == 'IS') {
        return (
          condition.value
            .split(SELECT_OPTIONS_DELIMITER)
            .filter((optionValue) => optionValue == newValue).length > 0
        )
      } else if (condition.matchType == 'INT') {
        return (
          condition.value
            .split(SELECT_OPTIONS_DELIMITER)
            .filter((optionValue) => optionValue == newValue).length == 0
        )
      }
      break
    case 'select_many': {
      const optionsInCondition: string[] = condition.value.split(
        SELECT_OPTIONS_DELIMITER
      )

      if (condition.value == undefined || condition.value == '') {
        // unanswered
        return false
      }
      if (condition.matchType == 'IS') {
        if (optionsInCondition.length == (newValue as string[]).length) {
          return (
            optionsInCondition.filter(
              (option) => !(newValue as string[]).includes(option)
            ).length == 0
          )
        }
        return false
      } else if (condition.matchType == 'INT') {
        if (optionsInCondition.length == (newValue as string[]).length) {
          return (
            optionsInCondition.filter(
              (option) => !(newValue as string[]).includes(option)
            ).length != 0
          )
        }
        return true
      } else if (condition.matchType == 'LST') {
        return newValue.length < parseInt(condition.value, 10)
      } else if (condition.matchType == 'GRT') {
        return newValue.length > parseInt(condition.value, 10)
      } else if (condition.matchType == 'LTOE') {
        return newValue.length <= parseInt(condition.value, 10)
      } else if (condition.matchType == 'GTOE') {
        return newValue.length >= parseInt(condition.value, 10)
      } else if (condition.matchType == 'NET') {
        return newValue.length == parseInt(condition.value, 10)
      } else if (condition.matchType == 'NNET') {
        return newValue.length != parseInt(condition.value, 10)
      } else if (condition.matchType == 'BT') {
        return (
          newValue.length >= parseInt(condition.minValue, 10) &&
          newValue.length <= parseInt(condition.maxValue, 10)
        )
      } else if (condition.matchType == 'AI') {
        return (
          (newValue as string[]).filter((optionValue) =>
            optionsInCondition.includes(optionValue)
          ).length > 0
        )
      } else if (condition.matchType == 'ADNI') {
        return (
          (newValue as string[]).filter((optionValue) =>
            optionsInCondition.includes(optionValue)
          ).length == 0
        )
      }
      break
    }
    case 'checkbox':
      if (
        ('true' == newValue.toString() && 'TICK' == condition.value) ||
        ('false' == newValue.toString() && 'UNTI' == condition.value)
      ) {
        return true
      } else {
        return false
      }
    case 'text':
    case 'textarea':
      if (condition.matchType == 'CONT') {
        return containsIgnoringCase(newValue as string, condition.value)
      } else if (condition.matchType == 'NCON') {
        return !containsIgnoringCase(newValue as string, condition.value)
      } else if (condition.matchType == 'ISAN') {
        return newValue.length == 0
      } else if (condition.matchType == 'ISTA') {
        return newValue.length > 0
      }
      break
    case 'number':
    case 'percentage': {
      const floatValue: number = parseFloat(newValue as string)
      const floatConditionValue: number = parseFloat(condition.value)
      if (condition.matchType == 'IS') {
        return floatValue == floatConditionValue
      } else if (condition.matchType == 'INT') {
        return floatValue != floatConditionValue
      } else if (condition.matchType == 'LST') {
        return floatValue < floatConditionValue
      } else if (condition.matchType == 'GRT') {
        return floatValue > floatConditionValue
      } else if (condition.matchType == 'LTOE') {
        return floatValue <= floatConditionValue
      } else if (condition.matchType == 'GTOE') {
        return floatValue >= floatConditionValue
      } else if (condition.matchType == 'BT') {
        return (
          floatValue >= parseFloat(condition.minValue) &&
          floatValue <= parseFloat(condition.maxValue)
        )
      }
    }
    // eslint-disable-next-line no-fallthrough
    default:
      return false
  }
  return false
}

export function validateConditionalQuestion(
  newValue: string,
  question: IChecklistField | null,
  conditionalRulesList: IChecklistConditionalRule[],
  checklist: IChecklist
): void {
  if (question == null) {
    return
  }
  const filteredConditionalRules: IChecklistConditionalRule[] = []
  question.conditions!.forEach((conditionId) => {
    conditionalRulesList.forEach(
      (
        conditionRule: IChecklistConditionalRule,
        _conditionalRuleIdx: number
      ) => {
        conditionRule.conditions!.forEach(
          (condition: IChecklistCondition, _conditionIdx: number) => {
            if (condition.id === conditionId) {
              condition.applied = hasConditionApplied(
                newValue,
                question,
                condition
              )
              if (!filteredConditionalRules.includes(conditionRule)) {
                filteredConditionalRules.push(conditionRule)
              }
            }
          }
        )
      }
    )
  })
  filteredConditionalRules.forEach((conditionalRule) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    refreshConditionalRuleAndSubQuestions(
      checklist,
      conditionalRule,
      conditionalRulesList
    )
  })
}

export function refreshConditionalRuleAndSubQuestions(
  checklist: IChecklist,
  conditionalRule: IChecklistConditionalRule,
  conditionalRulesList: IChecklistConditionalRule[]
): void {
  const currentGroup = findChecklistGroupById(
    conditionalRule.groupId,
    checklist
  )
  const showFieldFlag = isConditionalRuleShow(
    conditionalRule,
    conditionalRulesList
  )
  currentGroup!.fields.forEach((field) => {
    if (conditionalRule.conditionalQuestions.includes(field.id)) {
      field.hidden = !showFieldFlag
      if (field.type === 'dynamicdatalist') {
        updateDynamicDatalistQuestions(field)
      }
      if (field.type === 'conditionalRule') {
        refreshConditionalRuleAndSubQuestions(
          checklist,
          field.conditionalRule!,
          conditionalRulesList
        )
      }
    }
  })
}

export function updateDynamicDatalistQuestions(field: IChecklistField): void {
  if (field.type === 'dynamicdatalist' && field.dataGrid?.values) {
    let conditionalRulesList: IChecklistConditionalRule[] = []
    let filteredConditionalRules: IChecklistConditionalRule[] = []
    for (const rows of field.dataGrid?.values) {
      conditionalRulesList = []
      filteredConditionalRules = []
      for (const gridField of rows) {
        gridField.hidden = field.hidden

        if (gridField.conditionalRule && gridField.conditionalRule != null) {
          conditionalRulesList.push(gridField.conditionalRule)
        }
      }
      for (const gridField of rows) {
        gridField.conditions!.forEach((conditionId) => {
          conditionalRulesList.forEach((conditionRule, _conditionalRuleIdx) => {
            conditionRule.conditions!.forEach((condition, _conditionIdx) => {
              if (condition.id == conditionId) {
                condition.applied = hasConditionApplied(
                  gridField.value,
                  gridField,
                  condition
                )
                if (
                  filteredConditionalRules.includes(conditionRule) === false
                ) {
                  filteredConditionalRules.push(conditionRule)
                }
              }
            })
          })
        })
      }
      filteredConditionalRules.forEach((conditionalRule) => {
        const showFieldFlag = isConditionalRuleShow(conditionalRule, [])
        for (const gridField of rows) {
          if (conditionalRule.conditionalQuestions.includes(gridField.id)) {
            gridField.hidden = !showFieldFlag
          }
        }
      })
    }
  }
}
