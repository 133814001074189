/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import { AssigneeType } from '../../types'
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles
} from '@material-ui/core/styles'
import {
  ISites,
  IFranchisees,
  getFranchisee,
  getSite
} from '../../models/Franchise'
import AutoComplete, { IAutoCompleteOption } from './AutoComplete'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'
const styles = (_theme: Theme) =>
  createStyles({
    entitySelector: {
      marginTop: 10
    }
  })

export interface IAssignment {
  assignee: AssigneeType
  assigneeId: number
}

export interface IAssignmentFormState {
  assignee: AssigneeType | null
  assigneeId: number
  autocompleteSelectedItem: IAutoCompleteOption | null
}

export interface IAssigneeFormProps
  extends WithStyles<typeof styles>,
    IOneplaceLibraryContextProp {
  useAutocomplete: boolean
  franchiseesShown: boolean
  entityAssignment: 'franchisee' | 'site' | 'both'
  franchiseeNameShownWithSite?: boolean
  assignee: AssigneeType | null
  assigneeId: number
  franchisees: IFranchisees
  sites: ISites
  inline?: boolean
  onAssignmentChanged: (assignment: IAssignment) => void
  disableChangeType?: boolean
  onInputSiteChanged?: (value: string) => Promise<ISites>
  onInputFranchiseeChanged?: (value: string) => Promise<IFranchisees>
}

function getSuggestions(
  options: IAutoCompleteOption[],
  inputValue: string
): IAutoCompleteOption[] {
  let count = 0
  return options.filter((option) => {
    const keep =
      (!inputValue ||
        option.name.toLowerCase().includes(inputValue.toLowerCase())) &&
      count < 20
    if (keep) {
      count += 1
    }
    return keep
  })
}

export default withStyles(styles)(
  withOneplaceLibraryContext(
    class AssignmentForm extends React.Component<
      IAssigneeFormProps,
      IAssignmentFormState
    > {
      static displayName = 'AssignmentForm'
      constructor(props: any) {
        super(props)
        // if (this.props.franchiseesShown) {
        if (this.props.assignee == 'franchisee') {
          let franchisee = null
          if (this.props.useAutocomplete) {
            try {
              franchisee = getFranchisee(
                this.props.franchisees,
                this.props.assigneeId
              )
            } catch (e) {
              franchisee = null
            }
          }
          this.state = {
            assignee: this.props.assignee,
            assigneeId: this.props.assigneeId,
            autocompleteSelectedItem: franchisee
          }
        } else if (this.props.assignee == 'site') {
          let site = null
          if (this.props.useAutocomplete) {
            try {
              site = getSite(this.props.sites.sites, this.props.assigneeId)
            } catch (e) {
              site = null
            }
          }
          this.state = {
            assignee: this.props.assignee,
            assigneeId: this.props.assigneeId,
            autocompleteSelectedItem: site
          }
        } else {
          this.state = {
            assignee: null,
            assigneeId: -1,
            autocompleteSelectedItem: null
          }
        }
        // } else {
        //   let site = null
        //   if (this.props.useAutocomplete) {
        //     try {
        //       site = getSite(this.props.sites.sites, this.props.assigneeId)
        //     } catch (e) {
        //       site = null
        //     }
        //   }
        //   this.state = {
        //     assignee: 'site',
        //     assigneeId: this.props.assigneeId,
        //     autocompleteSelectedItem: site
        //   }
        // }
        this.assigneeTypeChanged = this.assigneeTypeChanged.bind(this)
        this.assigneeIdChanged = this.assigneeIdChanged.bind(this)
        this.autocompleteSelectedItemChanged =
          this.autocompleteSelectedItemChanged.bind(this)
        this.autocompleteInputChanged = this.autocompleteInputChanged.bind(this)
      }

      assigneeTypeChanged(event: any) {
        const type = event?.target?.value
        const assignment: IAssignment = {
          assignee: type,
          assigneeId: -1
        }
        this.setState(
          {
            autocompleteSelectedItem: null,
            ...assignment
          },
          () => {
            this.props.onAssignmentChanged(assignment)
          }
        )
      }

      assigneeIdChanged(event: any) {
        if (this.state.assignee) {
          const assigneeId = Number(event.target.value)
          const assignment: IAssignment = {
            assignee: this.state.assignee,
            assigneeId
          }
          this.setState(assignment, () => {
            this.props.onAssignmentChanged(assignment)
          })
        }
      }
      async autocompleteInputChanged(
        value: string
      ): Promise<IAutoCompleteOption[]> {
        if (this.state.assignee == 'site') {
          if (this.props.onInputSiteChanged) {
            const data = await this.props.onInputSiteChanged(value)
            return data.sites
          } else {
            return getSuggestions(this.props.sites.sites, value)
          }
        } else {
          if (this.props.onInputFranchiseeChanged) {
            const data = await this.props.onInputFranchiseeChanged(value)
            return data.franchisees
          } else {
            return getSuggestions(this.props.franchisees.franchisees, value)
          }
        }
      }

      autocompleteSelectedItemChanged(
        item: IAutoCompleteOption | null,
        _stateAndHelpers: any
      ) {
        this.setState(
          {
            autocompleteSelectedItem: item
          },
          () => {
            if (this.state.assignee) {
              if (item) {
                this.props.onAssignmentChanged({
                  assignee: this.state.assignee,
                  assigneeId: item.id
                })
              } else {
                this.props.onAssignmentChanged({
                  assignee: this.state.assignee,
                  assigneeId: -1
                })
              }
            }
          }
        )
      }

      render() {
        const getSelectionOptions = () => {
          if (this.state.assignee == 'franchisee') {
            if (this.props.franchisees && this.props.franchisees.franchisees) {
              return this.props.franchisees.franchisees.map((franchisee) => (
                <option key={franchisee.id} value={franchisee.id}>
                  {franchisee.name}
                </option>
              ))
            }
          } else {
            if (this.props.sites && this.props.sites.sites) {
              return this.props.sites.sites.map((site) => (
                <option key={site.id} value={site.id}>
                  {this.props.franchiseesShown ||
                  this.props.franchiseeNameShownWithSite
                    ? `${site.franchiseeName || site.franchisee!.name}--${
                        site.name
                      }`
                    : site.name}
                </option>
              ))
            }
          }
          return []
        }

        const inline = this.props.inline

        return (
          <div
            style={{
              display: inline ? 'flex' : 'block',
              width: '100%',
              flexWrap: 'wrap'
            }}
          >
            {this.props.franchiseesShown && (
              <div
                style={{
                  display: inline ? 'inline-block' : 'block',
                  marginRight: 16
                }}
              >
                {this.props.entityAssignment === 'franchisee' ||
                  (this.props.entityAssignment === 'both' && (
                    <FormControlLabel
                      control={
                        <Radio
                          checked={this.state.assignee === 'franchisee'}
                          onChange={this.assigneeTypeChanged}
                          value='franchisee'
                          disabled={
                            this.props.disableChangeType != null
                              ? this.props.disableChangeType
                              : false
                          }
                        />
                      }
                      label={
                        <Typography variant='subtitle1'>
                          {this.props.ctx.i18next.t('customLabel_franchisee')}
                        </Typography>
                      }
                    />
                  ))}
                {this.props.entityAssignment === 'site' ||
                  (this.props.entityAssignment === 'both' && (
                    <FormControlLabel
                      control={
                        <Radio
                          checked={this.state.assignee === 'site'}
                          onChange={this.assigneeTypeChanged}
                          value='site'
                          disabled={
                            this.props.disableChangeType != null
                              ? this.props.disableChangeType
                              : false
                          }
                        />
                      }
                      label={
                        <Typography variant='subtitle1'>
                          {this.props.ctx.i18next.t('customLabel_site')}
                        </Typography>
                      }
                    />
                  ))}
              </div>
            )}
            {this.state.assignee && (
              <div
                style={{
                  display: inline ? 'inline-block' : 'block',
                  flexGrow: 1,
                  minWidth: '65%'
                }}
              >
                <FormControl
                  fullWidth
                  className={this.props.classes.entitySelector}
                >
                  {this.props.useAutocomplete && (
                    <AutoComplete
                      placeholder={
                        this.props.ctx.i18next.t('search') +
                        ' ' +
                        (this.state.assignee == 'franchisee'
                          ? this.props.ctx.i18next.t('customLabel_franchisees')
                          : this.props.ctx.i18next.t('customLabel_sites'))
                      }
                      options={
                        this.state.assignee == 'franchisee'
                          ? this.props.franchisees.franchisees
                          : this.props.sites.sites
                      }
                      selectedItem={this.state.autocompleteSelectedItem}
                      onChange={this.autocompleteSelectedItemChanged}
                      fetchEntities={this.autocompleteInputChanged}
                    />
                  )}

                  {!this.props.useAutocomplete && (
                    <>
                      <InputLabel htmlFor='assigneeId'>
                        {this.props.ctx.i18next.t('select') +
                          ' ' +
                          (this.state.assignee == 'franchisee'
                            ? this.props.ctx.i18next.t('customLabel_franchisee')
                            : this.props.ctx.i18next.t('customLabel_site'))}
                      </InputLabel>
                      <Select
                        native
                        value={this.state.assigneeId}
                        onChange={this.assigneeIdChanged}
                      >
                        <option value={-1}></option>
                        {getSelectionOptions()}
                      </Select>
                    </>
                  )}
                </FormControl>
              </div>
            )}
          </div>
        )
      }
    }
  )
)
