export const SELECT_OPTIONS_DELIMITER = '¬'

export interface IChecklistConditionalRule {
  id: number
  label: string
  logicalOperator: 'OR' | 'AND'
  conditions?: IChecklistCondition[]
  conditionalQuestions: number[]
  conditionalRuleFieldId: number
  parentConditionalRuleId: number
  groupId?: number
  dataListFieldId?: number | null
  row: number
  applied: boolean
}

export interface IChecklistCondition {
  id: number
  matchType:
    | 'IS' // is
    | 'INT' // is not
    | 'LST' // less than
    | 'GRT' // greater than
    | 'LTOE' // less than or equal
    | 'GTOE' // greater than or equal
    | 'BT' // between
    | 'YES' // yes
    | 'NO' // no
    | 'NA' // na
    | 'ISAN' // is answered
    | 'ISTA' // is not answered
    | 'CONT' // contains
    | 'NCON' // does not contains
    | 'NET' // number of selected options equals to
    | 'NNET' // number of selected options not equal to
    | 'AI' // answer includes
    | 'ADNI' // answer doesn't includes
  value: string
  maxValue: string
  minValue: string
  questionType:
    | 'yesno'
    | 'checkbox'
    | 'text'
    | 'textarea'
    | 'select'
    | 'select_many'
    | 'number'
    | 'percentage'
  selectedOptionValue?: string | null
  sortOrder: number
  triggerQuestionGroupId: number
  triggerQuestionId: number
  applied: boolean
}
