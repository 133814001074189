import { IIDAndName, RecStatus } from './Entity'

export const EmailTemplateType = {
  INCIDENT: 'incident'
} as const

export interface IEmailTemplate {
  id: number | null
  name: string
  subject: string
  body: string
  attachLogo: boolean
  type: 'incident' //| 'newtype'
  status?: RecStatus
  incidentTypes?: IIDAndName[]
}
