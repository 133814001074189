import { IPhoto } from '../../../models/Checklist'
import {
  getExifData,
  transformImageData,
  convertExifDate,
  getRandomFileName
} from './imageUtils'
import { CONFIG } from '../../../config'
import { formatDate } from '../../../utils/dates'
import { IImageStorage } from '../../../data_sources/imagestorage/imageStorage'

export async function processPhoto(
  photoData: Blob,
  fileName: string,
  storage: IImageStorage
): Promise<IPhoto> {
  let exif: any
  try {
    exif = await getExifData(photoData)
  } catch (err) {
    console.error('getExifData() error', err)
    exif = {}
  }
  console.log('EXIF Data', exif)
  const compressedImgData = await transformImageData(
    photoData,
    photoData.type,
    CONFIG.imageMaxResolution,
    CONFIG.imageCompression,
    exif.Orientation || 0
  )
  const thumbnailData = await transformImageData(
    photoData,
    photoData.type,
    CONFIG.imageThumbnailMaxResolution,
    CONFIG.imageThumbnailCompression,
    exif.Orientation || 0
  )
  const url = await storage.storeImage(compressedImgData, 'temporary')
  const localObjectUrl = await storage.getImageObjectUrl(url)
  const thumbnailUrl = await storage.storeImage(thumbnailData, 'temporary')
  const localThumbnaiObjectlUrl = await storage.getImageObjectUrl(thumbnailUrl)

  // add a random string to the file name to avoid duplicate file name problem
  const randomString = getRandomFileName()
  const ext = fileName.substring(fileName.lastIndexOf('.'), fileName.length)
  const uniqueFileName =
    fileName.substring(0, fileName.lastIndexOf('.')) + '_' + randomString + ext
  console.log(uniqueFileName)

  const photo: IPhoto = {
    url,
    localObjectUrl,
    thumbnailUrl,
    localThumbnaiObjectlUrl,
    comment: '',
    fileName: uniqueFileName,
    fileSize: photoData.size,
    contentType: photoData.type,
    photoTakenDate:
      convertExifDate(exif.DateTimeOriginal) ||
      convertExifDate(exif.DateTime) ||
      formatDate('iso', new Date())
  }
  console.log('Photo meta', photo)
  return photo
}
