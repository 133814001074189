import * as React from 'react'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { IPhoto } from '../../models/Checklist'
import { PhotoThumbnail } from './PhotoThumbnail'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'

export interface IPhotosFieldProps extends IOneplaceLibraryContextProp {
  photos: IPhoto[]
  onPhotoClicked: (photo: IPhoto) => void
}

export const PhotosField = withOneplaceLibraryContext(
  (props: IPhotosFieldProps) => {
    return (
      <FormControl fullWidth style={{ marginBottom: 10 }}>
        <InputLabel shrink={true}>{props.ctx.i18next.t('photos')}</InputLabel>
        <div style={{ marginTop: 20, display: 'flex', flexWrap: 'wrap' }}>
          {props.photos &&
            props.photos
              .filter((p) => !p.attachmentType || p.attachmentType === 'photo')
              .map((photo: IPhoto, idx: number) => {
                const photoUrl = props.ctx.client.getAuthenticatedPhotoUrl(
                  photo.localThumbnaiObjectlUrl || photo.url
                )
                return (
                  <PhotoThumbnail
                    key={idx}
                    src={photoUrl}
                    photo={photo}
                    onClick={() => props.onPhotoClicked(photo)}
                  />
                )
              })}
        </div>
      </FormControl>
    )
  }
)
