import * as React from 'react'
import {
  withStyles,
  Theme,
  createStyles,
  WithStyles
} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { IPhotoComment, IPhoto } from '../../models/Checklist'
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  ListItemSecondaryAction,
  IconButton,
  TextareaAutosize,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@material-ui/core'
import { formatDate } from '../../utils/dates'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import { deepCopy } from '../../utils'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'
const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    button: {
      float: 'right',
      marginLeft: 20
    }
  })

export interface IPhotoDialogProps
  extends WithStyles<typeof styles>,
    IOneplaceLibraryContextProp {
  photo: IPhoto
  isOpen: boolean
  fullName?: string // full name of the current user
  dateTimeFormat?: string // date format for the current user
}

export interface IPhotoDialogState {
  comment: string
  comments: IPhotoComment[]
  editingCommentContent?: string //comment content for editing
  editingCommentId?: number | null //comment id for editing
  currentIndex: number | null
  confirmDeleteDialogOpen: boolean
  confirmDeleteContent: React.ReactElement<any> | null
  confirmDeleteAction: React.ReactElement<any> | null
}

export const PhotoComments = withStyles(styles)(
  withOneplaceLibraryContext(
    class extends React.Component<IPhotoDialogProps, IPhotoDialogState> {
      static displayName = 'PhotoComments'
      constructor(props: any) {
        super(props)
        this.state = {
          comment: '',
          comments: this.props.photo.comments!,
          currentIndex: null,
          confirmDeleteDialogOpen: false,
          confirmDeleteContent: null,
          confirmDeleteAction: null
        }
      }
      onSave = () => {
        if (this.state.comment.length == 0) return
        this.state.comments.push({
          comment: this.state.comment,
          name: this.props.fullName ? this.props.fullName : 'unknown',
          photoId: this.props.photo.id ? this.props.photo.id : null,
          id: null,
          createdDateTime: formatDate('utc', new Date()),
          action: 'add'
        })
        const comments1 = this.state.comments
        this.setState({ comments: comments1, comment: '' })
        this.props.photo.comments = comments1.slice(0)
      }

      onClickEdit = (currentComment: IPhotoComment, indexToEdit: number) => {
        this.setState({ editingCommentContent: currentComment.comment! })
        if (currentComment.id) {
          this.setState({ editingCommentId: currentComment.id })
          this.setState({ currentIndex: null })
        } else {
          //new comment
          this.setState({ currentIndex: indexToEdit })
          this.setState({ editingCommentId: null })
        }
      }
      onSaveEdit = (currentComment: IPhotoComment) => {
        if (this.state.editingCommentId) {
          currentComment.action = 'update'
          this.setState({ editingCommentId: null })
        }
        this.setState({ currentIndex: null })
      }
      onConfirmDialogClosed = () => {
        this.setState({ confirmDeleteDialogOpen: false })
      }
      onCommentChanged = (event: any) => {
        this.setState({ comment: event.target.value })
      }
      onTempCommentChanged = (event: any) => {
        this.setState({ editingCommentContent: event.target.value })
        if (this.state.editingCommentId) {
          this.state.comments
            .filter((c) => this.state.editingCommentId == c.id)
            .forEach((c) => (c.comment = event.target.value))
        } else {
          //new comment
          this.state.comments.filter((c1) => c1.action != 'delete')[
            this.state.currentIndex!
          ].comment = event.target.value
        }
      }
      onDeleteClicked = (comment: IPhotoComment) => {
        this.setState({
          confirmDeleteDialogOpen: true,
          confirmDeleteContent: (
            <DialogContentText>
              Are you sure you want to delete this comment?
              <br />
              {comment.comment}
            </DialogContentText>
          ),
          confirmDeleteAction: (
            <Typography component='div' style={{ whiteSpace: 'pre-line' }}>
              <Button
                color='primary'
                onClick={() => this.deleteComment(comment)}
              >
                Delete
              </Button>
              <Button color='primary' onClick={this.onConfirmDialogClosed}>
                Cancel
              </Button>
            </Typography>
          )
        })
      }
      deleteComment = (comment: IPhotoComment) => {
        if (!comment.id) {
          const commentIdx = this.state.comments.findIndex(
            (c) =>
              c.id == null &&
              c.createdDateTime == comment.createdDateTime &&
              c.comment == comment.comment
          )
          if (commentIdx > -1) {
            const tempComments = deepCopy(this.state.comments)
            tempComments.splice(commentIdx, 1)
            this.setState({ comments: tempComments })
            this.props.photo.comments = tempComments
            document.getElementById('photoComment')?.focus()
          }
        } else {
          const tempComments = deepCopy(this.state.comments)
          tempComments
            .filter((c) => c.id == comment.id)
            .forEach((c) => (c.action = 'delete'))
          this.setState({ comments: tempComments })
          this.props.photo.comments = tempComments
        }
        this.setState({ confirmDeleteDialogOpen: false })
      }
      render() {
        return (
          <div style={{ width: '100%' }}>
            <div>
              <TextField
                id='photoComment'
                autoFocus
                fullWidth
                label={this.props.ctx.i18next.t('new_photo_comment')}
                value={this.state.comment}
                onChange={this.onCommentChanged}
                margin='dense'
                inputProps={{ maxLength: 255 }} // set max length to 255 chars
              />
            </div>
            <div>
              <Button
                color='secondary'
                onClick={this.onSave}
                className={this.props.classes.button}
              >
                {this.props.ctx.i18next.t('add')}
              </Button>
            </div>
            <div style={{ marginTop: 30 }}>
              <List className={this.props.classes.root}>
                {this.state.comments &&
                  this.state.comments
                    .filter((c1) => c1.action != 'delete')
                    .map((comment: IPhotoComment, index: number) => {
                      const id = index
                      return (
                        <React.Fragment key={id}>
                          <ListItem>
                            <ListItemText
                              primary={comment.name}
                              secondary={
                                <Typography component='h5' color='textPrimary'>
                                  Date:{' '}
                                  {comment.createdDateTime
                                    ? formatDate(
                                        'display_datetime',
                                        comment.createdDateTime,
                                        this.props.dateTimeFormat
                                      )
                                    : ''}{' '}
                                  <br />
                                  Content: {comment.comment} <br />
                                  <br />
                                </Typography>
                              }
                            />
                            <ListItemSecondaryAction style={{ marginTop: 10 }}>
                              <IconButton
                                edge='end'
                                aria-label='edit'
                                onClick={() => this.onClickEdit(comment, index)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                edge='end'
                                aria-label='delete'
                                onClick={() => this.onDeleteClicked(comment)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                          {((this.state.editingCommentId &&
                            this.state.editingCommentId == comment.id) ||
                            (this.state.currentIndex != null &&
                              this.state.currentIndex == index)) && (
                            <>
                              <TextareaAutosize
                                aria-label='empty textarea'
                                style={{ width: '80%' }}
                                minRows={5}
                                value={this.state.editingCommentContent}
                                onChange={this.onTempCommentChanged}
                              />
                              <IconButton
                                edge='end'
                                aria-label='save'
                                onClick={() => this.onSaveEdit(comment)}
                              >
                                <CheckIcon />
                              </IconButton>
                            </>
                          )}
                          <Divider />
                        </React.Fragment>
                      )
                    })}
              </List>
              {this.state.confirmDeleteDialogOpen && (
                <Dialog
                  open={this.state.confirmDeleteDialogOpen}
                  onClose={this.onConfirmDialogClosed}
                >
                  <DialogTitle>Confirm Delete Comment</DialogTitle>
                  <DialogContent>
                    {this.state.confirmDeleteContent}
                  </DialogContent>
                  <DialogActions>
                    {this.state.confirmDeleteAction}
                  </DialogActions>
                </Dialog>
              )}
            </div>
          </div>
        )
      }
    }
  )
)
