import {
  IChecklist,
  IChecklistField,
  IChecklistRenderProps
} from '../../../models/Checklist'
import { hasAttachments, hasPhotos } from '../utils/attachments'
import { isMandatory } from '../utils/accessoryFields'

export interface IChecklistError {
  groupIdx: number
  fieldIdx: number
  errorText: string
}

// TODO: this logic should probably exist nearer the field types
export function mandatoryFieldNotSet(field: IChecklistField): boolean {
  return (
    (field.type === 'select_many' &&
      (!field.values || field.values.length === 0)) ||
    (field.type !== 'select_many' && !field.value)
  )
}

export function validateGroups(
  checklist: IChecklist,
  renderProps: IChecklistRenderProps
): IChecklistRenderProps {
  // Update renderProps.groups.incompleteFields for any groups that do not have
  // a value for fields with "Highlight if not completed" ie trackedField
  let showTab = false
  let incompleteFields = false
  checklist.groups.forEach((group, groupIdx) => {
    incompleteFields = false
    showTab = false
    group.fields.forEach((field, fieldIdx) => {
      if (
        !renderProps.groups[groupIdx].fields[fieldIdx].hidden &&
        !field.hidden
      ) {
        // should group label if there's any question display
        showTab = true

        if (field.trackField || field.mandatory) {
          if (
            field.dataGrid &&
            field.type !== 'datagrid' &&
            field.dataGrid.values instanceof Array
          ) {
            field.dataGrid.values.forEach((row) => {
              row.forEach((gridField) => {
                if (
                  !gridField.hidden &&
                  (gridField.trackField || field.mandatory)
                ) {
                  if (mandatoryFieldNotSet(gridField)) {
                    incompleteFields = true
                  }
                }
              })
            })
          } else if (
            field.dataGrid &&
            field.type === 'datagrid' &&
            field.value
          ) {
            // value is from question root rather than dataGrid.values
            // need to remove the first entry for each array since that is already
            // given and first header is usually blank
            const arrayOfDataGridValues: string[][] = JSON.parse(
              field.value
            ) as string[][]
            const modifiedArray = arrayOfDataGridValues.map((row: string[]) => {
              const newRow = [...row]
              newRow.shift()
              return newRow
            })

            modifiedArray.forEach((modifiedGroup: string[]) => {
              modifiedGroup.forEach((value: string) => {
                if (!value) {
                  incompleteFields = true
                }
              })
            })
          } else {
            if (mandatoryFieldNotSet(field)) {
              incompleteFields = true
            }
          }
        }
      }
    })
    renderProps.groups[groupIdx].showTab = showTab
    renderProps.groups[groupIdx].incompleteFields = incompleteFields
  })
  return renderProps;
}

export function validateChecklist(
  checklist: IChecklist,
  renderProps: IChecklistRenderProps,
  t: any
) {
  const errors: IChecklistError[] = []
  //const t = i18n.t.bind(i18n) survey app
  //const t = i18n.t; oneplace app
  if (!checklist.name) {
    errors.push({
      groupIdx: -1,
      fieldIdx: -1,
      errorText: t('error_must_enter_value_for', {
        field: t('checklist_name'),
        tab: t('details')
      })
    })
  }
  if (!checklist.date) {
    errors.push({
      groupIdx: -1,
      fieldIdx: -1,
      errorText: t('error_must_enter_value_for', {
        field: t('checklist_date'),
        tab: t('details')
      })
    })
  }
  checklist.groups.forEach((group, groupIdx) => {
    group.fields.forEach((field, fieldIdx) => {
      if (
        !renderProps.groups[groupIdx].fields[fieldIdx].hidden &&
        !field.hidden
      ) {
        // Checks for standard fields
        if (field.mandatory) {
          if (mandatoryFieldNotSet(field)) {
            errors.push({
              groupIdx,
              fieldIdx,
              errorText: t('error_must_enter_value_for', {
                field: field.label,
                tab: group.label
              })
            })
          }
        }
        if (isMandatory(field.commentOption) && !field.comment) {
          errors.push({
            groupIdx,
            fieldIdx,
            errorText: t('error_must_enter_comment_for', {
              field: field.label,
              tab: group.label
            })
          })
        }
        if (isMandatory(field.photoOption) && !hasPhotos(field.photos!)) {
          errors.push({
            groupIdx,
            fieldIdx,
            errorText: t('error_must_attach_photo_for', {
              field: field.label,
              tab: group.label
            })
          })
        }
        if (
          isMandatory(field.attachmentOption) &&
          !hasAttachments(field.photos!)
        ) {
          errors.push({
            groupIdx,
            fieldIdx,
            errorText: t('error_must_attach_file_for', {
              field: field.label,
              tab: group.label
            })
          })
        }

        // Checks for grid fields inside FDG, DDG and DDL
        if (field.dataGrid && field.dataGrid.values instanceof Array) {
          field.dataGrid.values.forEach((row) => {
            row
              .filter((gf) => !gf.hidden)
              .forEach((gridField) => {
                if (gridField.mandatory) {
                  if (mandatoryFieldNotSet(gridField)) {
                    errors.push({
                      groupIdx,
                      fieldIdx,
                      errorText: t('error_must_enter_grid_value_for', {
                        gridField: gridField.label,
                        field: field.label,
                        tab: group.label
                      })
                    })
                  }
                }
                if (
                  isMandatory(gridField.commentOption) &&
                  !gridField.comment
                ) {
                  errors.push({
                    groupIdx,
                    fieldIdx,
                    errorText: t('error_must_enter_grid_comment_for', {
                      gridField: gridField.label,
                      field: field.label,
                      tab: group.label
                    })
                  })
                }
                if (
                  isMandatory(gridField.photoOption) &&
                  !hasPhotos(gridField.photos!)
                ) {
                  errors.push({
                    groupIdx,
                    fieldIdx,
                    errorText: t('error_must_attach_grid_photo_for', {
                      gridField: gridField.label,
                      field: field.label,
                      tab: group.label
                    })
                  })
                }
                if (
                  isMandatory(gridField.attachmentOption) &&
                  !hasAttachments(gridField.photos!)
                ) {
                  errors.push({
                    groupIdx,
                    fieldIdx,
                    errorText: t('error_must_attach_grid_file_for', {
                      gridField: gridField.label,
                      field: field.label,
                      tab: group.label
                    })
                  })
                }
              })
          })
        }
      }
    })
  })
  return errors
}
