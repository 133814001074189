import * as React from 'react'

// 1Place Markdowns list
export const onePlaceMarkdown = {
  BOLD: {
    name: 'bold',
    regex: new RegExp('\\*\\*.*\\*\\*'),
    patten: '\\*\\*',
    beginTab: '<strong>',
    endTag: '</strong>'
  },
  ITALIC: {
    name: 'italic',
    regex: new RegExp('\\*(.+)\\*'),
    patten: '\\*',
    beginTab: '<em>',
    endTag: '</em>'
  },
  UNDERLINE: {
    name: 'underline',
    regex: new RegExp('\\[u\\].*\\[\\/u\\]'),
    patten: null,
    beginTab: '<ins>',
    endTag: '</ins>'
  },
  H3: {
    name: 'h3',
    regex: new RegExp('\\#\\#\\#'),
    patten: '\\#\\#\\#',
    beginTab: '<h3>',
    endTag: '</h3>'
  },
  H2: {
    name: 'h2',
    regex: new RegExp('\\#\\#'),
    patten: '\\#\\#',
    beginTab: '<h2>',
    endTag: '</h2>'
  },
  H1: {
    name: 'h1',
    regex: new RegExp('\\#'),
    patten: '\\#',
    beginTab: '<h1>',
    endTag: '</h1>'
  }
}

// markdown html data are sanitized in backend. Only the 1Place markdowns are allowed.
// If you calling this function for other pages, please make sure the source data is sanitized properly.
export const dangerouslyInsertMarkdownHtml: any = (rawData: string) => {
  return <div dangerouslySetInnerHTML={{ __html: decodeHtml(rawData) }}></div>
}

const decodeHtml = (html: string) => {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}
