import * as React from 'react'
import IconButton from '@material-ui/core/IconButton'
import PhotoIcon from '@material-ui/icons/AddAPhoto'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import TakePhotoIcon from '@material-ui/icons/PhotoCamera'
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles
} from '@material-ui/core/styles'
import { logError } from '../../logging'
import { processPhoto } from './utils/processPhoto'
import { withOneplaceLibraryContext } from '../OneplaceLibraryProvider'
import { IAddPhotoButtonProps } from './AddPhotoButton'
import PhotoLibrary from '@material-ui/icons/PhotoLibrary'
import { IPhoto } from '../../models/Checklist'
import { IBasicDialogProps, BasicDialog } from '../common/BasicDialog'
import { CONFIG } from '../../config'
import { CircularProgress, Typography } from '@material-ui/core'
import { HtmlCamera } from './HtmlCamera'

// AddPhotoButton for PWA / Web

const styles = (_theme: Theme) =>
  createStyles({
    listItemText: {
      display: 'inline-block'
    },
    label: {
      width: 48,
      height: 48
    },
    uploadLabel: {
      width: '100%',
      height: '100%',
      cursor: 'pointer'
    },
    uploadButtonText: {
      position: 'absolute',
      textAlign: 'left',
      fontSize: 16,
      left: 48,
      top: 14,
      width: 200
    },
    uploadInput: {
      opacity: 0,
      position: 'absolute',
      zIndex: -10,
      right: 0
    }
  })

export interface IAddPhotoButtonWebProps
  extends IAddPhotoButtonProps,
    WithStyles<typeof styles> {}

export interface IAddPhotoButtonWebState {
  photoMenuAnchor: any
  cameraOpen: boolean
  dialogProps: IBasicDialogProps
}
export const AddPhotoButtonWeb = withStyles(styles)(
  withOneplaceLibraryContext(
    class extends React.Component<
      IAddPhotoButtonWebProps,
      IAddPhotoButtonWebState
    > {
      public static defaultProps = {
        allowPhotosFromGallery: true
      }
      static displayName = 'AddPhotoButtonWeb'
      private fileInputRef: React.RefObject<HTMLInputElement>
      constructor(props: any) {
        super(props)
        this.fileInputRef = React.createRef()
        this.addPhotoButtonClick = this.addPhotoButtonClick.bind(this)
        this.photoMenuClose = this.photoMenuClose.bind(this)
        this.takeFromCamera = this.takeFromCamera.bind(this)
        this.takeFromGallery = this.takeFromGallery.bind(this)
        this.closeCamera = this.closeCamera.bind(this)
        this.showFileGallery = this.showFileGallery.bind(this)

        this.state = {
          photoMenuAnchor: null,
          cameraOpen: false,
          dialogProps: {
            content: <></>,
            isOpen: false,
            onClose: () => null,
            disableBackdropClick: true
          }
        }
      }

      async takeFromGallery(event: any) {
        this.photoMenuClose()
        const files: FileList = event.target.files
        let error = false
        if (files && files[0]) {
          try {
            if (!this.validateFiles(files)) {
              this.showValidationDialog()
              return
            }

            this.showLoadingImagesDialog(files.length)

            const photos: IPhoto[] = []
            for (let i = 0; i < files.length; i++) {
              const photo = await processPhoto(
                files[i],
                files[i].name,
                this.props.ctx.imageStorage
              )
              photo.attachmentType = 'photo'
              photo.source = 'gallery'
              photos.push(photo)
              this.updateLoadingProgress(photos.length, files.length)
            }

            this.props.onPhotoUploaded(photos)
          } catch (e) {
            logError(e, 'Error while processing uploaded image')
            error = true
          }
        }

        if (this.fileInputRef.current) {
          /*this allows the same file to be uploaded twice
          (chrome only add the file when the field changes)*/
          this.fileInputRef.current.value = ''
        }
        this.hideDialog()
        if (error) {
          this.showUploadErrorDialog()
        }
      }

      addPhotoButtonClick(event: any): void {
        this.setState({ photoMenuAnchor: event.currentTarget })
      }

      photoMenuClose(): void {
        this.setState({ photoMenuAnchor: null })
      }

      takeFromCamera(): string {
        this.setState({ cameraOpen: true })
        this.photoMenuClose()
        return ''
      }

      closeCamera(): string {
        this.setState({ cameraOpen: false })
        return ''
      }

      showFileGallery(): void {
        this.fileInputRef.current?.click()
      }

      hideDialog = () => {
        this.setState({
          dialogProps: {
            ...this.state.dialogProps,
            isOpen: false
          }
        })
      }

      showLoadingImagesDialog = (total: number) => {
        this.setState({
          dialogProps: {
            ...this.state.dialogProps,
            isOpen: true,
            title: `Loading Images... (0 of ${total})`,
            content: (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <CircularProgress color='primary' disableShrink={true} />
              </div>
            ),
            showCloseButton: false
          }
        })
      }

      showValidationDialog = () => {
        this.setState({
          dialogProps: {
            ...this.state.dialogProps,
            isOpen: true,
            title: 'Validation Error',
            content: (
              <>
                {`The limit for uploading photos at once is
                ${CONFIG.imageMultipleMaxNumber} photos or
                ${CONFIG.imageMultipleMaxSize} MB`}
              </>
            ),
            showCloseButton: true,
            onClose: this.hideDialog
          }
        })
      }

      showUploadErrorDialog = () => {
        this.setState({
          dialogProps: {
            ...this.state.dialogProps,
            isOpen: true,
            title: 'Error processing image',
            content: (
              <>
                <Typography variant='body1'>
                  We are unable to process this image. Our system supports only
                  JPG and PNG file formats. Below are some possible reasons why
                  this error occurred:
                </Typography>
                <ul>
                  <li>
                    The image is corrupted in some way that prevents it from
                    being loaded.
                  </li>
                  <li>
                    The image format is not supported. eg you uploaded a High
                    Efficiency Image Format file (HEIF)
                  </li>
                </ul>
              </>
            ),
            showCloseButton: true,
            onClose: this.hideDialog
          }
        })
      }

      validateFiles = (files: FileList): boolean => {
        if (files.length > CONFIG.imageMultipleMaxNumber) {
          return false
        }

        let size = 0
        for (let i = 0; i < files.length; i++) {
          size += files[i].size
        }
        if (size / 1024 / 1024 > CONFIG.imageMultipleMaxSize) {
          return false
        }
        return true
      }

      updateLoadingProgress = (loaded: number, total: number) => {
        this.setState({
          dialogProps: {
            ...this.state.dialogProps,
            title: `Loading Images... (${loaded} of ${total})`
          }
        })
      }

      render(): React.ReactNode {
        const menuItems = [
          <MenuItem
            onClick={this.takeFromCamera}
            key='takeFromCamera'
            id={this.props.controlId + '-photobutton-takefromcamera'}
            className={this.props.className}
          >
            <ListItemIcon className={this.props.className}>
              <TakePhotoIcon />
            </ListItemIcon>
            <ListItemText primary={this.props.ctx.i18next.t('take_a_photo')} />
          </MenuItem>,
          this.props.allowPhotosFromGallery && (
            <MenuItem
              onClick={this.showFileGallery}
              key='showFileGallery'
              id={this.props.controlId + '-photobutton-showfilegallery'}
              className={this.props.className}
            >
              <ListItemIcon className={this.props.className}>
                <PhotoLibrary />
              </ListItemIcon>
              <ListItemText
                className={this.props.classes.listItemText}
                primary={this.props.ctx.i18next.t('select_from_gallery')}
              />
            </MenuItem>
          )
        ]

        const iconOnly =
          this.props.standalone != null ? this.props.standalone : true
        return (
          <React.Fragment>
            {iconOnly ? (
              <IconButton
                id={this.props.controlId + '-photobutton'}
                className={this.props.className}
                onClick={this.addPhotoButtonClick}
              >
                <PhotoIcon />
              </IconButton>
            ) : (
              menuItems
            )}
            <Menu
              anchorEl={this.state.photoMenuAnchor}
              open={Boolean(this.state.photoMenuAnchor)}
              onClose={this.photoMenuClose}
            >
              {menuItems}
            </Menu>

            <input
              data-testid={this.props.controlId + '-photo-upload'}
              multiple={this.props.allowMultipleFiles !== false}
              ref={this.fileInputRef}
              type='file'
              accept='image/jpeg, image/png, image/gif'
              id={this.props.controlId + '-photo-upload'}
              className={this.props.classes.uploadInput}
              onChange={this.takeFromGallery}
            />

            <HtmlCamera
              open={this.state.cameraOpen}
              onPhotoTaken={(photo: IPhoto) => {
                this.props.onPhotoUploaded([photo])
                this.setState({ cameraOpen: false })
              }}
              onCloseCamera={() => this.setState({ cameraOpen: false })}
            />

            <BasicDialog {...this.state.dialogProps} />
          </React.Fragment>
        )
      }
    }
  )
)
