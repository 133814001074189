import { IChecklistField } from '../../../models/Checklist'

export const SUPPORTED_TYPES = ['checkbox', 'yesno', 'select']

export function presetTicketMatch(question: IChecklistField, value: any) {
  const presets = question.checklistTemplateFieldPresetTicketJsons
  if (presets && presets.length && SUPPORTED_TYPES.includes(question.type)) {
    let testValue = value
    if (question.type == 'checkbox') {
      testValue = value ? 'TICKED' : 'NOT_TICKED'
    }
    return presets.find((preset) => preset.answer == testValue)
  }
  return undefined
}
