import { IPhoto, IAttachment } from '../../../models/Checklist'

export function hasPhotos(photos: IPhoto[] | null): boolean {
  return photos ? photos.some((p) => p.attachmentType !== 'attachment') : false
}

export function hasAttachments(photos: IPhoto[] | null): boolean {
  return photos ? photos.some((p) => p.attachmentType === 'attachment') : false
}

export function createPhotoAttachment(attachment: IAttachment): IPhoto {
  const photo = {} as IPhoto
  photo.id = attachment.id
  photo.fileName = attachment.fileName
  photo.uuid = attachment.uuid
  photo.url = attachment.url!
  photo.attachmentType = 'attachment'
  photo.contentType = attachment.contentType
  photo.fileSize = attachment.fileSize
  return photo
}

export function createAttachment(photo: IPhoto): IAttachment {
  return {
    id: photo.id,
    comment: photo.comment,
    fileName: photo.fileName,
    uuid: photo.uuid,
    url: photo.url,
    localObjectUrl: photo.localObjectUrl,
    contentType: photo.contentType,
    fileSize: photo.fileSize
  } as IAttachment
}

export function createAttachments(photos: IPhoto[]): IAttachment[] {
  return photos.map((photo) => createAttachment(photo))
}

export function isAttachment(
  attachment: IAttachment | IPhoto
): attachment is IAttachment {
  return (<IPhoto>attachment).thumbnailUrl === undefined
}
