import * as React from 'react'
import Input from '@material-ui/core/Input'
import { IControlProps } from './types'
import { GRID_CELLPADDING, GRID_CELLFONTSIZE } from './grids/consts'
import { InputBaseComponentProps } from '@material-ui/core/InputBase'

export default class TextControl extends React.Component<IControlProps> {
  constructor(props: any) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event: any): void {
    this.props.form.setFieldValue(this.props.field.name, event.target.value)
  }

  render(): React.ReactNode {
    const inputProps: InputBaseComponentProps = {}
    if (this.props.maxLength) {
      inputProps.maxLength = this.props.maxLength
    }
    if (this.props.gridCell) {
      return (
        <div
          id={this.props.id}
          style={{ padding: GRID_CELLPADDING, fontSize: GRID_CELLFONTSIZE }}
        >
          {this.props.field.value || ''}
        </div>
      )
    } else {
      return (
        <Input
          id={this.props.id}
          fullWidth
          value={this.props.field.value || ''}
          onChange={this.handleChange}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder || ''}
          inputProps={inputProps}
        />
      )
    }
  }
}
