import * as React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

export interface IBasicDialogProps {
  title?: string
  closeLabel?: string
  content: React.ReactElement<any>
  isOpen: boolean
  onClose: () => void
  disableBackdropClick?: boolean
  showCloseButton?: boolean
}

export const BasicDialog = (
  props: IBasicDialogProps
): React.FunctionComponentElement<IBasicDialogProps> => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={(_event, reason) => {
        if (
          props.disableBackdropClick &&
          ['backdropClick', 'escapeKeyDown'].includes(reason)
        ) {
          return
        }
        props.onClose()
      }}
    >
      {props.title && <DialogTitle>{props.title}</DialogTitle>}
      <DialogContent>{props.content}</DialogContent>
      <DialogActions>
        {props.showCloseButton !== false && (
          <Button color='primary' onClick={props.onClose}>
            {props.closeLabel ? props.closeLabel : 'Close'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
