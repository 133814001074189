import { IChecklistField } from '../../../models/Checklist'
import { ISelectControlProps } from '../../form_controls/SelectControl'
import { IDataGridControlProps } from '../../form_controls/grids/DataGridControl'
import { IControlProps } from '../../form_controls/types'

export const selectPropsConverter = (
  field: IChecklistField,
  props: ISelectControlProps
) => {
  props.options = field.options!
  props.colourRules = field.importanceAnswerColorRules
}

export const propConverters: {
  [fieldType: string]: (field: IChecklistField, props: any) => void
} = {
  select: selectPropsConverter,
  yesno: selectPropsConverter,
  checkbox: (field, props: IControlProps) => {
    props.colourRules = field.importanceAnswerColorRules
  },
  number: (field, props: IControlProps) => {
    props.colourRules = field.importanceAnswerColorRules
  },
  select_many: (field, props: ISelectControlProps) => {
    props.options = field.options!
  },
  datagrid: (field, props: IDataGridControlProps) => {
    props.dataGrid = field.dataGrid!
  }
}

// TODO: We should have integration tests over this

export function fieldToControlProps(field: IChecklistField, propsObj: any) {
  if (field.type in propConverters) {
    propConverters[field.type](field, propsObj)
  }
}
