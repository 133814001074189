import { ICacheManager } from './AssetCacheManager'

export type AssetType = 'helpimg' | 'other'

export const convertToBase64 = (path: string): string => {
  return Buffer.from(JSON.stringify(path)).toString('base64')
}

export class InMemoryCacheManager<T> implements ICacheManager<T> {
  items: { [key: string]: any } = {}
  isCacheable: (action: string) => boolean
  isValid: (response: T & { timestamp: number }) => boolean

  constructor(
    isCacheable: (action: string) => boolean,
    isValid: (response: T & { timestamp: number }) => boolean
  ) {
    this.isCacheable = isCacheable
    this.isValid = isValid
  }

  add(path: string, value: T): void {
    if (this.isCacheable(path)) {
      this.items[convertToBase64(path)] = {
        ...value,
        timestamp: Date.now()
      }
    }
  }
  remove(path: string): void {
    delete this.items[convertToBase64(path)]
  }

  get(path: string): (T & { timestamp: number }) | null {
    const response = this.items[convertToBase64(path)]
    const valid = response && this.isValid(response)

    if (valid) {
      return response
    }

    if (response && !valid) {
      this.remove(path)
    }
    return null
  }
}
