export class InternalServerError extends Error {
  constructor(message: string) {
    super('HTTP 500 Error: ' + message)
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, InternalServerError.prototype)
  }
}

// tslint:disable-next-line:max-line-length
// https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
