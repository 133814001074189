import * as React from 'react'
import { IControlProps } from './types'
import { formatDate } from '../../utils/dates'
import { GRID_CELLPADDING, GRID_CELLFONTSIZE } from './grids/consts'
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { isValid } from 'date-fns'
import { CONFIG } from '../../config'
import InputAdornment from '@material-ui/core/InputAdornment'
import EventIcon from '@material-ui/icons/Event'
import DateFnsUtils from '@date-io/date-fns'

export interface ITimeControlState {
  value: any
}

export default class TimeControl extends React.Component<
  IControlProps,
  ITimeControlState
> {
  timeFormat: string
  constructor(props: any) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    if (props.timeFormat) {
      this.timeFormat = props.timeFormat
    } else {
      this.timeFormat = CONFIG.displayTimeFormat
    }
    const initialValue = this.fromStoredValue(this.props.field.value || '')
    this.state = {
      value: initialValue == '' ? null : initialValue
    }
  }

  fromStoredValue(value: string) {
    if (!value || value.length === 0) {
      return ''
    }
    const dateParts = value.split(':')
    const d = new Date()
    d.setHours(Number(dateParts[0]))
    d.setMinutes(Number(dateParts[1]))
    d.setSeconds(dateParts[2] ? Number(dateParts[2]) : 0)
    return d
  }

  toStoredValue(value: string | null) {
    return value ? formatDate('time', value) : ''
  }

  handleChange(date: any) {
    if (date == null || isValid(date)) {
      this.props.form.setFieldValue(
        this.props.field.name,
        this.toStoredValue(date)
      )
    } else {
      this.props.form.setFieldValue(
        this.props.field.name,
        this.toStoredValue(null)
      )
    }
    this.setState({ value: date })
  }

  render() {
    if (this.props.gridCell) {
      const value = this.fromStoredValue(this.props.field.value || '')
      const displayValue = value
        ? formatDate('display_time', value, this.timeFormat)
        : ''
      return (
        <div
          id={this.props.id}
          style={{ padding: GRID_CELLPADDING, fontSize: GRID_CELLFONTSIZE }}
        >
          {displayValue}
        </div>
      )
    } else {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TimePicker
            ampm={this.timeFormat.includes('a')}
            disabled={this.props.disabled}
            clearable
            value={this.state.value}
            onChange={(date) => this.handleChange(date)}
            format={this.timeFormat}
            id={this.props.id}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <EventIcon />
                </InputAdornment>
              )
            }}
          />
        </MuiPickersUtilsProvider>
      )
    }
  }
}
