import { IAttachmentResponse } from '..'
import { IApi } from '../data_sources/api/api'

export class MockApi implements IApi {
  authHeaders!: { [header: string]: string }
  validateAuth!: () => Promise<void>

  _mocks = {
    getAuthenticatedPhotoUrl: jest.fn((url: string) => url),
    saveAttachment: jest.fn(() => Promise.resolve({ UUID: 'test', url: '' })),
    viewAttachment: jest.fn(() => {
      return
    }),
    deleteAttachment: jest.fn(() => Promise.resolve('OK'))
  }

  getAuthenticatedPhotoUrl(url: string): string {
    return this._mocks.getAuthenticatedPhotoUrl(url)
  }
  async saveAttachment(): Promise<IAttachmentResponse> {
    return this._mocks.saveAttachment()
  }
  viewAttachment(): void {
    return this._mocks.viewAttachment()
  }
  async deleteAttachment(): Promise<string> {
    return this._mocks.deleteAttachment()
  }
}
