import { IChecklistField } from '..'
import { ITimerValue } from '../components/form_controls/TimerControl'

export const allQuestionsAnsweredForEachGroup = (
  data: IChecklistField[][] | null | undefined
): boolean[] => {
  if (!data || !data.length) {
    return []
  }

  return data.map((group: any[]) =>
    group.every((question: IChecklistField) => {
      if (question.mandatory === false && question.trackField === false) {
        return true
      } else {
        if (question.type === 'timer') {
          const timerValue: ITimerValue = JSON.parse(
            question.value
          ) as ITimerValue
          return timerValue.startDate > 0 && timerValue.endDate > 0;
        } else {
          return question.value && question.value !== ''
        }
      }
    })
  )
}

export const selectManyQuestionAnswered = (answers: any[] | undefined): boolean => {
  if (!answers) {
    return false;
  }
  return answers.length > 0;
}
