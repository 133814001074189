import * as React from 'react'
import { OneplaceLibraryContext } from '../../components/OneplaceLibraryProvider'
import * as i18next from 'i18next'
import { i18n } from '../../settings/i18n'
import { IEnvironment } from '../../environments'
import { getDefaultEnvironment } from '../mock_environment'
import IClient from '../../data_sources'
import { Client } from '../../data_sources/client'
import { MockApi } from '../mock_api'
import { IImageStorage } from '../../data_sources/imagestorage/imageStorage'
import { MockImageStorage } from '../mock_imagestorage'
import { ISettings } from '../../settings/settings'

export interface IMockAppContextProps {
  mock_i18next?: i18next.i18n
  mock_env?: IEnvironment
  mock_client?: IClient
  imageStorage?: IImageStorage
}

export class MockOneplaceLibraryContext extends React.Component<IMockAppContextProps> {
  mockEnv: IEnvironment
  i18nextInstance: i18next.i18n
  mockClient: IClient
  mockImageStorage: IImageStorage

  constructor(props: any) {
    super(props)

    const settings: ISettings = {
      addPhotosToGallery: false,
      disableBackgroundSync: true
    }

    this.mockEnv = props.mock_env || getDefaultEnvironment()
    //this.mockClient = new Client({ api: new MockApi() })
    this.mockImageStorage = new MockImageStorage()
    this.mockClient = new Client({
      api: new MockApi(),
      imageStorage: this.mockImageStorage,
      settings
    })
    this.init()

    this.i18nextInstance = props.mock_i18next || i18n.instance()
  }

  async init(): Promise<void> {
    try {
      await i18n.initialise()
    } catch (e) {
      // console.log('swallow error for MockOneplaceLibraryContext')
    }
  }

  render(): React.ReactNode {
    return (
      <OneplaceLibraryContext.Provider
        value={{
          i18next: this.i18nextInstance,
          env: this.mockEnv,
          client: this.mockClient,
          imageStorage: this.mockImageStorage
        }}
      >
        {this.props.children}
      </OneplaceLibraryContext.Provider>
    )
  }
}
