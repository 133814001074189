import React, { useEffect, useState, useRef } from 'react'
import { FormControl, FormLabel } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import DescriptionIcon from '@material-ui/icons/Description'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles'
import { IAttachment } from '../..'

const styles = () =>
  createStyles({
    item: {
      width: '100%',
      marginTop: '10px'
    },
    fileName: {
      marginLeft: '5px',
      marginRight: '5px',
      verticalAlign: 'bottom',
      whiteSpace: 'nowrap'
    },
    icon: {
      verticalAlign: 'bottom',
      fontSize: 'medium'
    }
  })

export interface IAttachmentsPanelProps
  extends IOneplaceLibraryContextProp,
    WithStyles<typeof styles> {
  attachments: IAttachment[]
  onAttachmentClicked: (attachment: IAttachment) => void
}

const showFileName = (filename: string, maxCharSize: number) => {
  if (maxCharSize === 0) {
    return '...'
  }
  if (filename.length <= maxCharSize) {
    return filename
  }
  return filename.substring(0, maxCharSize) + '...'
}

const getMaxCharSize = (ref: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const divSize = ref.current ? ref.current.offsetWidth : 0
  return divSize / 10
}

export const AttachmentsPanel = withOneplaceLibraryContext(
  withStyles(styles)((props: IAttachmentsPanelProps) => {
    const ref = useRef(null)
    const [maxCharSize, setMaxCharSize] = useState<number | null>(null)

    useEffect(() => {
      setMaxCharSize(getMaxCharSize(ref))
    }, [])

    return (
      <FormControl fullWidth style={{ marginBottom: 10 }}>
        <InputLabel shrink={true}>
          Attachments ({props.attachments.length})
        </InputLabel>
        <div style={{ marginTop: 15 }} ref={ref}>
          {props.attachments.map((attachment: IAttachment, idx: number) => {
            return (
              <div key={idx} className={props.classes.item}>
                <FormLabel role='listitem' style={{ display: 'inline-flex' }}>
                  <DescriptionIcon className={props.classes.icon} />
                  <span
                    title='Attachment File Name'
                    className={props.classes.fileName}
                    style={{
                      cursor: attachment.url ? 'pointer' : ''
                    }}
                    onClick={() => props.onAttachmentClicked(attachment)}
                  >
                    {maxCharSize &&
                      showFileName(attachment.fileName, maxCharSize)}
                  </span>
                </FormLabel>
              </div>
            )
          })}
        </div>
      </FormControl>
    )
  })
)
