import React from 'react'
import { IAttachment } from '../../models/Checklist'
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'
import DescriptionIcon from '@material-ui/icons/Description'
import { FormLabel } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import CloudDoneIcon from '@material-ui/icons/CloudDone'
import ErrorIcon from '@material-ui/icons/Error'
import ReplayIcon from '@material-ui/icons/Replay'
import StopIcon from '@material-ui/icons/Stop'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'
import { CONFIG } from '../../config'

const styles = (theme: Theme) =>
  createStyles({
    item: {
      width: '100%',
      marginTop: '10px'
    },
    fileName: {
      marginLeft: '5px',
      marginRight: '5px',
      verticalAlign: 'bottom',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    icon: {
      verticalAlign: 'bottom',
      fontSize: 'medium'
    },
    saved: {
      color: theme.palette.primary.light
    },
    error: {
      color: theme.palette.secondary.main
    },
    stop: {
      color: theme.palette.secondary.main,
      cursor: 'pointer'
    },
    retry: {
      marginLeft: '5px',
      color: theme.palette.primary.light,
      cursor: 'pointer'
    }
  })

export interface IAttachmentItemProps
  extends IOneplaceLibraryContextProp,
    WithStyles<typeof styles> {
  item: IAttachment
  onRetryUpload: (attachment: IAttachment) => void
  onCancelUpload: (attachment: IAttachment) => void
}

export const AttachmentItem = withOneplaceLibraryContext(
  withStyles(styles)((props: IAttachmentItemProps) => {
    return (
      <div className={props.classes.item}>
        <FormLabel
          disabled={props.item.status === 'Canceled'}
          title='Attachment Item'
          style={{ display: 'flex' }}
        >
          <DescriptionIcon className={props.classes.icon} />
          <span className={props.classes.fileName}>{props.item.fileName}</span>
          {props.item.fileSizeExceeded && (
            <span>
              (File size limit exceeded - {CONFIG.attachmentMaxSize}MB)
            </span>
          )}
          {props.item.status === 'Saving' && (
            <>
              <CircularProgress color='primary' size={12} role='progressbar' />
              <StopIcon
                titleAccess='Stop'
                onClick={() => props.onCancelUpload(props.item)}
                className={`${props.classes.icon} ${props.classes.stop}`}
              />
            </>
          )}
          {props.item.status === 'Saved' && (
            <CloudDoneIcon
              titleAccess='Saved'
              className={`${props.classes.icon} ${props.classes.saved}`}
            />
          )}
          {props.item.status === 'Error' && (
            <>
              <ErrorIcon
                titleAccess='Error'
                className={`${props.classes.icon} ${props.classes.error}`}
              />
              <ReplayIcon
                titleAccess='Retry'
                onClick={() => props.onRetryUpload(props.item)}
                className={`${props.classes.icon} ${props.classes.retry}`}
              />
            </>
          )}
        </FormLabel>
      </div>
    )
  })
)
