export const STORED_IMAGE_PREFIX = 'storedimg://'
export const TEMP_IMAGE_PREFIX = 'tempimg://'

export type ImageStorageType = 'temporary' | 'permanent'

export function isLocalImage(imageUrl: string): boolean {
  return (
    imageUrl.startsWith(STORED_IMAGE_PREFIX) ||
    imageUrl.startsWith(TEMP_IMAGE_PREFIX)
  )
}

export function getImageStorageType(
  localImageUrl: string
): [ImageStorageType, string] {
  if (localImageUrl.startsWith(STORED_IMAGE_PREFIX)) {
    return ['permanent', localImageUrl.substr(STORED_IMAGE_PREFIX.length)]
  } else if (localImageUrl.startsWith(TEMP_IMAGE_PREFIX)) {
    return ['temporary', localImageUrl.substr(TEMP_IMAGE_PREFIX.length)]
  } else {
    throw new Error(localImageUrl + ' is not a valid local image URL')
  }
}

export interface IImageStorage {
  // Store image data and return storedimg:// or tempimg:// URL
  storeImage(imageData: Blob, storageType: ImageStorageType): Promise<string>

  // Generate an ObjectURL suitable for use as an img src
  getImageObjectUrl(localImageUrl: string, reloadImageUrl?: boolean): Promise<string>

  // Return the blob representing the image
  getImageData(localImageUrl: string): Promise<Blob>

  // Convert a Temporary image into a permenant image and returns storedimg://
  makePermenant(tempImageUrl: string): Promise<string>

  // Remove stored image
  removeImage(localImageUrl: string): Promise<void>

  // Release all in-memory object URLs
  revokeObjectUrls(): void
}
