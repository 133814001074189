import * as React from 'react'
import { IControlProps } from './types'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { formatDate } from '../../utils/dates'
import { toDate } from 'date-fns'
import { GRID_CELLPADDING, GRID_CELLFONTSIZE } from './grids/consts'
import {
  IOneplaceLibraryContextProp,
  withOneplaceLibraryContext
} from '../OneplaceLibraryProvider'

export interface ITimerValue {
  startDate: number // timestamp or 0
  endDate: number // timestamp or 0
  breakStart: number // timestamp or 0
  duration: number // break duration in ms
}

export type TIMER_ACTIONS =
  | 'start_timer'
  | 'start_break'
  | 'stop_break'
  | 'stop_timer'

export interface ITimerControlProps
  extends IOneplaceLibraryContextProp,
    IControlProps {}

export function timerStateChange(
  state: ITimerValue,
  action: TIMER_ACTIONS
): ITimerValue | null {
  const { startDate, endDate, breakStart, duration } = state

  if (startDate == 0 && endDate == 0 && breakStart == 0) {
    // not started
    if (action == 'start_timer') {
      return {
        startDate: Date.now(),
        endDate: 0,
        breakStart: 0,
        duration: 0
      }
    }
  }
  if (startDate > 0 && endDate == 0 && breakStart == 0) {
    // started, not on a break
    if (action == 'start_break') {
      return {
        startDate,
        endDate,
        duration,
        breakStart: Date.now()
      }
    } else if (action == 'stop_timer') {
      return {
        startDate,
        endDate: Date.now(),
        duration,
        breakStart: 0
      }
    }
  }
  if (startDate > 0 && endDate == 0 && breakStart > 0) {
    // started and on a break
    if (action == 'stop_break') {
      return {
        startDate,
        endDate,
        duration: duration + (Date.now() - breakStart),
        breakStart: 0
      }
    } else if (action == 'stop_timer') {
      return {
        startDate,
        endDate: Date.now(),
        duration,
        breakStart: 0
      }
    }
  }
  return null
}

export default withOneplaceLibraryContext(
  class TimerControl extends React.Component<ITimerControlProps> {
    getValue(): ITimerValue {
      return (
        this.props.field.value || {
          startDate: 0,
          endDate: 0,
          breakStart: 0,
          duration: 0
        }
      )
    }

    timerAction(action: TIMER_ACTIONS) {
      const newValue = timerStateChange(this.getValue(), action)
      console.log('timer_state', newValue)
      if (newValue) {
        this.props.form.setFieldValue(this.props.field.name, newValue)
      }
    }

    getStatusText(): string {
      const { startDate, endDate, breakStart /* duration*/ } = this.getValue()
      if (startDate == 0) {
        return ''
      } else {
        let status = `Started: ${formatDate(
          'display_datetime',
          toDate(startDate),
          this.props.dateTimeFormat
        )}`
        if (breakStart > 0) {
          status += `\nOn a break since: ${formatDate(
            'display_datetime',
            toDate(breakStart),
            this.props.dateTimeFormat
          )}`
        }
        if (endDate > 0) {
          status += `\nEnded: ${formatDate(
            'display_datetime',
            toDate(endDate),
            this.props.dateTimeFormat
          )}`
        }
        return status
      }
    }

    render(): React.ReactNode {
      const buttonStyle = { backgroundColor: '#888', marginRight: 12 }
      const { startDate, endDate, breakStart } = this.getValue()
      const statusText = this.getStatusText()

      if (this.props.gridCell) {
        return (
          <div
            id={this.props.id}
            style={{
              whiteSpace: 'pre-line',
              padding: GRID_CELLPADDING,
              fontSize: GRID_CELLFONTSIZE
            }}
          >
            {statusText}
          </div>
        )
      } else {
        return (
          <div style={{ display: 'flex' }}>
            {startDate > 0 && (
              <Typography style={{ marginRight: 12, whiteSpace: 'pre-line' }}>
                {statusText}
              </Typography>
            )}
            {startDate == 0 && endDate == 0 && (
              <Button
                variant='contained'
                color='primary'
                style={buttonStyle}
                onClick={this.timerAction.bind(this, 'start_timer')}
              >
                {this.props.ctx.i18next.t('start_timer')}
              </Button>
            )}
            {startDate > 0 && breakStart == 0 && endDate == 0 && (
              <Button
                variant='contained'
                color='primary'
                style={buttonStyle}
                onClick={this.timerAction.bind(this, 'start_break')}
              >
                {this.props.ctx.i18next.t('start_break')}
              </Button>
            )}
            {startDate > 0 && breakStart > 0 && endDate == 0 && (
              <Button
                variant='contained'
                color='primary'
                style={buttonStyle}
                onClick={this.timerAction.bind(this, 'stop_break')}
              >
                {this.props.ctx.i18next.t('stop_break')}
              </Button>
            )}
            {startDate > 0 && endDate == 0 && (
              <Button
                variant='contained'
                color='primary'
                style={buttonStyle}
                onClick={this.timerAction.bind(this, 'stop_timer')}
              >
                {this.props.ctx.i18next.t('stop_timer')}
              </Button>
            )}
          </div>
        )
      }
    }
  }
)
