import * as React from 'react'
import { IControlProps } from './types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import PenIcon from '@material-ui/icons/Check'
import SignaturePad from 'signature_pad'
import { logError } from '../../logging'
import {
  IOneplaceLibraryContextProp,
  withOneplaceLibraryContext
} from '../OneplaceLibraryProvider'

export interface IPhotoControlState {
  dialogOpen: boolean
  penColour: string
}

export interface IPhotoControlProps
  extends IControlProps,
    IOneplaceLibraryContextProp {}

export const SIGNATURE_PAD_DELAY = 200

export default withOneplaceLibraryContext(
  class PhotoControl extends React.Component<
    IPhotoControlProps,
    IPhotoControlState
  > {
    photoCanvas: React.RefObject<HTMLCanvasElement>
    photoImg: React.RefObject<HTMLImageElement>
    photoAspectRatio = 1 // set in componentDidMount()
    signaturePad!: SignaturePad

    constructor(props: any) {
      super(props)
      this.state = {
        dialogOpen: false,
        penColour: 'black'
      }
      this.photoCanvas = React.createRef()
      this.photoImg = React.createRef()
    }

    componentDidMount() {
      // Img data set by props, but night not be loaded immediately
      const img = this.photoImg.current!
      if (img.complete) {
        this.calculatePhotoAspectRatio()
      } else {
        img.onload = this.calculatePhotoAspectRatio
      }
    }

    calculatePhotoAspectRatio = () => {
      const img = this.photoImg.current!
      this.photoAspectRatio = img.height / img.width
      console.log('photo aspect ratio', this.photoAspectRatio)
    }

    onSave = () => {
      let newVal: string | null
      if (this.signaturePad.isEmpty()) {
        newVal = null
      } else {
        newVal = this.signaturePad.toDataURL('image/jpeg')
      }
      this.props.form.setFieldValue(this.props.field.name, newVal)
      this.closeDialog()
    }

    closeDialog = () => {
      this.setState({ dialogOpen: false })
      if (this.signaturePad) {
        this.signaturePad.off()
      }
    }

    initSignaturePad = () => {
      this.signaturePad = new SignaturePad(this.photoCanvas.current!)
      const value = this.getValue()
      if (value) {
        this.signaturePad.fromDataURL(value, { ratio: 1 })
      }
    }

    openDialog = () => {
      this.setState({ dialogOpen: true })
      // There is a short delay before the canvas is available
      setTimeout(() => {
        try {
          this.initSignaturePad()
        } catch (e) {
          logError(e, 'Unable to mount signature pad.')
        }
      }, SIGNATURE_PAD_DELAY)
    }

    setPenColour = (colour: string) => {
      this.setState({ penColour: colour })
      this.signaturePad.penColor = colour
    }

    getValue(): string | null {
      return this.props.field.value
    }

    resetCanvas = () => {
      if (this.props.templateValue) {
        this.signaturePad.fromDataURL(this.props.templateValue, { ratio: 1 })
      }
    }

    render() {
      const value = this.getValue()

      if (this.props.gridCell) {
        return (
          <div>
            {value && (
              <img
                src={value}
                style={{
                  width: 140
                }}
                ref={this.photoImg}
              />
            )}
          </div>
        )
      } else {
        const canvasWidth = 300
        const canvasHeight = canvasWidth * this.photoAspectRatio

        const colourButtonStyle = {
          width: 40,
          height: 40,
          marginLeft: 8,
          marginBottom: 8,
          minWidth: 0,
          minHeight: 0,
          boxShadow:
            '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
          borderRadius: '50%'
        }

        const colours = [
          { colour: 'black', iconColour: 'white' },
          { colour: 'red', iconColour: 'white' },
          { colour: 'yellow', iconColour: 'black' }
        ]

        return (
          <div>
            <div>
              <img
                src={value || ''}
                style={{
                  width: 250,
                  border: '1px solid #AAA'
                }}
                ref={this.photoImg}
                onClick={this.openDialog}
              />
            </div>
            <Dialog
              open={this.state.dialogOpen}
              onClose={this.closeDialog}
              PaperProps={{ style: { margin: 12 } }}
            >
              <DialogContent>
                {!this.props.disabled && (
                  <div style={{ textAlign: 'right' }}>
                    {colours.map((colour) => {
                      const style = {
                        ...colourButtonStyle,
                        background: colour.colour,
                        color: colour.iconColour
                      }
                      return (
                        <Button
                          variant='contained'
                          style={style}
                          key={colour.colour}
                          onClick={() => this.setPenColour(colour.colour)}
                        >
                          {this.state.penColour == colour.colour && (
                            <PenIcon fontSize='small' />
                          )}
                        </Button>
                      )
                    })}
                  </div>
                )}
                <div
                  style={{
                    border: '1px solid #000',
                    pointerEvents: this.props.disabled ? 'none' : 'auto'
                  }}
                >
                  <canvas
                    width={canvasWidth}
                    height={canvasHeight}
                    ref={this.photoCanvas}
                  ></canvas>
                </div>
              </DialogContent>
              <DialogActions>
                <Button color='primary' onClick={this.closeDialog}>
                  {this.props.ctx.i18next.t('cancel')}
                </Button>
                {!this.props.disabled && (
                  <>
                    <Button
                      style={{ color: '#F00' }}
                      onClick={this.resetCanvas}
                    >
                      {this.props.ctx.i18next.t('clear')}
                    </Button>
                    <Button color='primary' onClick={this.onSave}>
                      {this.props.ctx.i18next.t('save')}
                    </Button>
                  </>
                )}
              </DialogActions>
            </Dialog>
          </div>
        )
      }
    }
  }
)
