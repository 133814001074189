import * as React from 'react'
import { IControlProps } from '../types'
import {
  IDataGridFieldProps,
  IChecklistField,
  IFieldRenderProps
} from '../../../models/Checklist'
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { deepCopy } from '../../../utils'
import DeleteIcon from '@material-ui/icons/Delete'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import {
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Divider
} from '@material-ui/core'
import Question from '../../checklists/QuestionForDDL'
import randomstring from 'randomstring'
import { IChecklistConditionalRule } from '../../../models/ConditionalRule'

import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../../OneplaceLibraryProvider'
import {
  isConditionalRuleShow,
  hasConditionApplied
} from '../../checklists/utils/conditionalQuestion'
import { CheckCircle } from '@material-ui/icons'
import { allQuestionsAnsweredForEachGroup } from '../../../utils/questionAnswers'

const styles = (theme: Theme) =>
  createStyles({
    mandatoryQuestion: {
      color: theme.palette.secondary.main
    },
    expansionPanelDetails: {
      display: 'block'
    },
    expansionPanel: {
      backgroundColor: theme.palette.grey[50]
    },
    tabCompletedIcon: {
      width: 20,
      height: 20,
      marginLeft: 6,
      color: theme.palette.secondary.main
    },
    panelSummary: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }
  })

export interface IDynamicDataListControlProps
  extends IControlProps,
    IOneplaceLibraryContextProp,
    WithStyles<typeof styles> {
  groupIndex: number
  question: IChecklistField
  renderProps: IFieldRenderProps
}
export interface IDynamicDataListControlState {
  expandFlags: boolean[] // expand flags for rows
  allGroupQuestionsAnswered: Record<string, any>
}

export default withStyles(styles)(
  withOneplaceLibraryContext(
    class DynamicDataListControl extends React.Component<
      IDynamicDataListControlProps,
      IDynamicDataListControlState
    > {
      static displayName = 'DynamicDataListControl'
      conditionalRulesList: IChecklistConditionalRule[]
      dataListLabels: string[]

      constructor(props: any) {
        super(props)

        this.addRow = this.addRow.bind(this)
        this.removeRow = this.removeRow.bind(this)
        this.onDataUpdated = this.onDataUpdated.bind(this)
        this.onFieldChange = this.onFieldChange.bind(this)
        this.onHandleClick = this.onHandleClick.bind(this)
        this.allQuestonsAnsweredForEachListGroup =
          this.allQuestonsAnsweredForEachListGroup.bind(this)
        this.conditionalRulesList = []
        this.dataListLabels = []
        this.findConditionalRulesFromDataList()
        this.state = {
            expandFlags: this.getInitRowNumber(),
            allGroupQuestionsAnswered: this.allQuestonsAnsweredForEachListGroup()
        }
      }

      getValue(): IDataGridFieldProps {
        return this.props.field.value
      }

      getInitRowNumber(): boolean[] {
        const oldFlags = this.props.field.value.expandFlags
        if (oldFlags) {
          // switching back from other group
          return oldFlags
        }
        // new editing, only show the first row
        const expandFlags = []
        expandFlags.push(true)
        for (let i = 1; i < this.getValue().values!.length; i++) {
          expandFlags.push(false)
        }
        return expandFlags
      }

      getExpandFlagByRow(row: number): boolean {
        return this.state.expandFlags[row]
      }
      onHandleClick = (row: number) => (_event: any, expanded: boolean) => {
        const newFlags = this.state.expandFlags
        newFlags[row] = expanded
        this.setState({ expandFlags: newFlags })
        this.props.field.value.expandFlags = newFlags
      }
      findConditionalRulesFromDataList() {
        const dataGrid = this.getValue().values
        dataGrid!.forEach((row: IChecklistField[], index: number) => {
          row.forEach((field: IChecklistField, _i: number) => {
            if (field.conditionalRule != null) {
              // set row index for each conditional rule, so we know which row it belongs to
              field.conditionalRule.row = index
              this.conditionalRulesList.push(field.conditionalRule)
            }
          })
        })
      }
      validateConditionalQuestion = (
        newValue: string,
        question: IChecklistField | null,
        rowIndex?: number
      ) => {
        if (question == null) {
          // no conditional question change, don't need to refresh page
          return
        }

        const filteredConditionalRules: IChecklistConditionalRule[] = []
        question.conditions!.forEach((conditionId) => {
          this.conditionalRulesList.forEach(
            (conditionRule, _conditionalRuleIdx) => {
              if (conditionRule.row == rowIndex) {
                conditionRule.conditions!.forEach(
                  (condition, _conditionIdx) => {
                    if (condition.id == conditionId) {
                      condition.applied = hasConditionApplied(
                        newValue,
                        question,
                        condition
                      )
                      if (!filteredConditionalRules.includes(conditionRule)) {
                        filteredConditionalRules.push(conditionRule)
                      }
                    }
                  }
                )
              }
            }
          )
        })
        filteredConditionalRules.forEach((conditionalRule) => {
          this.refreshConditionalRuleAndSubQuestions(conditionalRule)
        })
      }
      refreshConditionalRuleAndSubQuestions(
        conditionalRule: IChecklistConditionalRule
      ) {
        // conditional questions not in data list
        const showFieldFlag = isConditionalRuleShow(
          conditionalRule,
          this.conditionalRulesList
        )
        const dataGrid = this.getValue()
        const data = dataGrid.values!
        data.forEach((row: IChecklistField[], index: number) => {
          if (index == conditionalRule.row) {
            row.forEach((field: IChecklistField, _i: number) => {
              if (conditionalRule.conditionalQuestions.includes(field.id)) {
                field.hidden = !showFieldFlag
                if (field.type == 'conditionalRule') {
                  this.refreshConditionalRuleAndSubQuestions(
                    field.conditionalRule!
                  )
                }
              }
            })
          }
        })
      }
      addRow() {
        const dataGrid = this.getValue()
        const data = dataGrid.values!
        const newRow: IChecklistField[] = []
        dataGrid.columnLabels.forEach((col) => {
          newRow.push(deepCopy(col.field!))
        })
        newRow[0].key = randomstring.generate()
        data.push(newRow)

        const rowCnt = data.length
        newRow.forEach((field: IChecklistField, _i: number) => {
          if (field.conditionalRule != null) {
            // set row index for each conditional rule, so we know which row it belongs to
            field.conditionalRule.row = rowCnt - 1
            this.conditionalRulesList.push(field.conditionalRule)
          }
        })
        this.onDataUpdated(data)

        // update flags
        const newFlags = this.state.expandFlags.slice()
        newFlags.push(true)
        this.setState({ expandFlags: newFlags })
        this.props.field.value.expandFlags = newFlags
      }

      removeRow(rowIdx: number) {
        const dataGrid = this.getValue()
        const data = dataGrid.values!

        // keep at least one row
        if (data.length > 1) {
          // remove linked conditionalRulesList
          this.conditionalRulesList = this.conditionalRulesList.filter(
            (conditionalRule) => conditionalRule.row != rowIdx
          )

          // update row index for subsquent conditional rules
          this.conditionalRulesList
            .filter((conditionalRule) => conditionalRule.row > rowIdx)!
            .forEach((c) => (c.row = c.row - 1))
          data.splice(rowIdx, 1)
          this.onDataUpdated(data)

          // update flags
          const newFlags = this.state.expandFlags.slice()
          newFlags.splice(rowIdx, 1)
          this.setState({ expandFlags: newFlags })
          this.props.field.value.expandFlags = newFlags
        }
      }

      onFieldChange() {
        const dataGrid = this.getValue()
        const data = dataGrid.values!
        this.onDataUpdated(data)

        const allGroupQuestionsAnswered =
          this.allQuestonsAnsweredForEachListGroup()
        this.setState({
          allGroupQuestionsAnswered
        })
      }

      allQuestonsAnsweredForEachListGroup() {
        const dataGrid = this.getValue()
        const data = dataGrid.values!

        return allQuestionsAnsweredForEachGroup(data)
      }

      onDataUpdated(newData: IChecklistField[][]) {
        const dataGrid = this.getValue()
        const data = [...newData]
        dataGrid.values = data
        this.props.form.setFieldValue(this.props.field.name, dataGrid)
      }

      render() {
        const data = this.getValue().values!
        return (
          <Typography component='div'>
            {data.map((row: IChecklistField[], index: number) => {
              const id = index
              const rowCnt = index + 1
              const renderProps: IFieldRenderProps = {}
              const listLabel = this.dataListLabels[rowCnt]
                ? this.dataListLabels[rowCnt]
                : `List ${rowCnt}`
              if (row[0].type == 'text') {
                this.dataListLabels[rowCnt] = row[0].value
              } else {
                this.dataListLabels[rowCnt] = ''
              }
              return (
                <ExpansionPanel
                  key={row[0].key}
                  expanded={this.state.expandFlags[index]}
                  classes={{ root: this.props.classes.expansionPanel }}
                  onChange={this.onHandleClick(index)}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1c-content'
                    id='panel1c-header'
                  >
                    <div className={this.props.classes.panelSummary}>
                      <Typography>{listLabel}</Typography>
                      {this.state.allGroupQuestionsAnswered[index] && (
                        <CheckCircle
                          className={this.props.classes.tabCompletedIcon}
                        />
                      )}
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    classes={{ root: this.props.classes.expansionPanelDetails }}
                  >
                    {this.getExpandFlagByRow(index) &&
                      row.map((field: IChecklistField, colIndex: number) => {
                        return (
                          <Question
                            key={colIndex}
                            cardKey={colIndex}
                            controlSuffix={`-${index}-${colIndex}`}
                            groupIndex={this.props.groupIndex}
                            question={field}
                            rowIndex={index}
                            imageStorage={this.props.imageStorage!}
                            dateTimeFormat={this.props.dateTimeFormat!}
                            dateFormat={this.props.dateFormat!}
                            fullName={this.props.fullName}
                            renderProps={renderProps}
                            onTicketPreset={this.props.onTicketPreset!}
                            onFieldChange={this.onFieldChange}
                            validateConditionalQuestion={
                              this.validateConditionalQuestion
                            }
                            parentQuestionId={this.props.fieldId}
                            onDeletePhoto={this.props.onDeletePhoto}
                            allowSavedPhotoDeletion={
                              this.props.allowSavedPhotoDeletion
                            }
                            allowPhotosFromGallery={
                              this.props.allowPhotosFromGallery
                            }
                            showDatalistVisualCue={
                              field.value === '' && field.trackField
                            }
                          />
                        )
                      })}
                  </ExpansionPanelDetails>
                  <Divider />
                  <ExpansionPanelActions>
                    <Button
                      size='small'
                      startIcon={<DeleteIcon />}
                      onClick={() => this.removeRow(id)}
                    >
                      {`${this.props.ctx.i18next.t(
                        'dynamic_datagridlist_delete_label'
                      )} ${
                        this.dataListLabels[rowCnt]
                          ? this.dataListLabels[rowCnt]
                          : rowCnt
                      }`}
                    </Button>
                  </ExpansionPanelActions>
                </ExpansionPanel>
              )
            })}

            <div style={{ marginTop: 12 }}>
              <Button
                variant='contained'
                color='primary'
                style={{ backgroundColor: '#888' }}
                id={this.props.id + '_add_row_button'}
                onClick={this.addRow}
              >
                {this.props.ctx.i18next.t('dynamic_datagridlist_add_label')}
              </Button>
            </div>
          </Typography>
        )
      }
    }
  )
)
