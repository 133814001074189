export function equalsIgnoringCase(text: string, other: string): boolean {
  if (text == null) {
    if (other == null) {
      return true
    } else {
      return false
    }
  } else {
    return text.localeCompare(other, undefined, { sensitivity: 'base' }) == 0
  }
}

export function containsIgnoringCase(text: string, other: string): boolean {
  if (text == null) {
    return false
  } else {
    if (other == null) {
      return false
    }
    return text.toLowerCase().includes(other.toLowerCase())
  }
}

export function truncateText(s: string, limit: number): string {
  if (!s) return ''
  const length = s.length
  if (length > limit) {
    return s.slice(0, limit) + '...'
  }
  return s
}
