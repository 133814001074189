import React from 'react'
import { IAttachment } from '../../models/Checklist'
import { AttachmentItem } from './AttachmentItem'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'
import { DialogActions, Button, Dialog, DialogContent } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'

export interface IAttachmentsUploadDialogProps
  extends IOneplaceLibraryContextProp {
  attachments: IAttachment[]
  onRetryUpload: (attachment: IAttachment) => void
  onCancelUpload: (attachment: IAttachment) => void
  isOpen: boolean
  onClose: () => void
}

export const AttachmentsUploadDialog = withOneplaceLibraryContext(
  (props: IAttachmentsUploadDialogProps) => {
    return (
      <Dialog
        disableBackdropClick={true}
        open={props.isOpen}
        onClose={() => props.onClose}
      >
        <DialogTitle>
          {` Saving Attachments (${
            props.attachments.filter((att) => att.status === 'Saved').length
          } of ${
            props.attachments.filter((att) => att.status !== 'Canceled').length
          })`}
        </DialogTitle>
        <DialogContent>
          {props.attachments &&
            props.attachments.map((attachment: IAttachment, idx: number) => {
              return (
                <AttachmentItem
                  key={idx}
                  item={attachment}
                  onRetryUpload={props.onRetryUpload}
                  onCancelUpload={props.onCancelUpload}
                />
              )
            })}
        </DialogContent>
        <DialogActions style={{ alignItems: 'flex-end' }}>
          <Button
            color='primary'
            onClick={props.onClose}
            disabled={props.attachments.some((att) => att.status === 'Saving')}
          >
            {props.ctx.i18next.t('ok')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)
