// import * as React from 'react'
import CheckboxControl, * as checklistControl from './components/form_controls/CheckboxControl'
// import styles from './styles.module.css'
import DateControl from './components/form_controls/DateControl'
import DateTimeControl from './components/form_controls/DateTimeControl'
import TimeControl from './components/form_controls/TimeControl'
import NumberControl from './components/form_controls/NumberControl'
import PercentageControl from './components/form_controls/PercentageControl'
import SelectControl, * as selectControl from './components/form_controls/SelectControl'
import SelectManyControl from './components/form_controls/SelectManyControl'
import SignatureControl, * as signatureControl from './components/form_controls/SignatureControl'
import SubtotalControl from './components/form_controls/SubtotalControl'
import TextAreaControl, * as textAreaControl from './components/form_controls/TextAreaControl'
import TextControl from './components/form_controls/TextControl'
import TimerControl, * as timerControl from './components/form_controls/TimerControl'
import { UnsupportedControl } from './components/form_controls/UnsupportedControl'
import OneplaceLibraryContextProvider, * as oneplaceLibraryProvider from './components/OneplaceLibraryProvider'
import { MockOneplaceLibraryContext } from './__mocks__/components/mock_oneplaceLibraryProvider'
import DataGridControl, * as dataGridControl from './components/form_controls/grids/DataGridControl'
import DynamicDataGridControl, * as dynamicDataGridControl from './components/form_controls/grids/DynamicDataGridControl'
import DynamicDataListControl, * as dynamicDataListControl from './components/form_controls/grids/DynamicDataListControl'
import FixedDataGridControl, * as fixedDataGridControl from './components/form_controls/grids/FixedDataGridControl'
import Client from './data_sources/client'
import * as client from './data_sources/index'
import Api, * as api from './data_sources/api/api'
import Question, * as question from './components/checklists/Question'
import QuestionGroup, * as questionGroup from './components/checklists/QuestionGroup'
import AutoComplete, * as autoComplete from './components/common/AutoComplete'
import DropdownAutoComplete from './components/common/DropdownAutoComplete'
import AssignmentForm, * as assignmentForm from './components/common/AssignmentForm'
import ValidationErrors, * as validationErrors from './components/checklists/ValidationErrors'
import TabsComponent, * as tabTypes from './components/common/Tabs'
import NotificationDialog from './components/notifications/NotificationDialog'
import TextChipListControl from './components/form_controls/TextChipListControl'
import { PageLoader } from './components/common/PageLoader'
import * as types from './types'
import * as formControlTypes from './components/form_controls/types'
import * as checklistModels from './models/Checklist'
import * as customFieldModels from './models/CustomFields'
import * as emailTypes from './models/EmailTypes'
import * as franchiseModels from './models/Franchise'
import * as personModels from './models/Person'
import * as conditionalRuleModels from './models/ConditionalRule'
import * as integrationModels from './models/Integration'
import * as emailTemplateModels from './models/EmailTemplate'
import * as notificationModels from './models/Notifications'
import { i18n } from './settings/i18n'
import * as dateUtils from './utils/dates'
import * as markdownDataUtils from './utils/MarkdownData'
import * as accessoryFieldsUtils from './components/checklists/utils/accessoryFields'
import * as attachmentUtils from './components/checklists/utils/attachments'
import * as utils from './utils'
import * as IImageStorageModule from './data_sources/imagestorage/imageStorage'
import * as IEnvironmentModule from './environments'
import * as ISettingsModule from './settings/settings'
import * as AssetCacheManager from './data_sources/assets/AssetCacheManager'
import HelpImages from './components/checklists/HelpImages'
import * as controlProps from './components/checklists/utils/controlProps'
import { PhotosField } from './components/photos/PhotosField'
import { AddPhotoButton } from './components/photos/AddPhotoButton'
import { PhotoDialog } from './components/photos/PhotoDialog'
import { Gallery } from './components/photos/Gallery'
import * as gridConsts from './components/form_controls/grids/consts'
import * as renderProps from './components/checklists/utils/getRenderProps'
import * as conditionalQuestion from './components/checklists/utils/conditionalQuestion'
import * as validateChecklistUtils from './components/checklists/utils/validateChecklist'
import * as entity from './models/Entity'
import CheckCircleCustomColor, * as checkCircleCustomColor from './components/custom_icons/CheckCircleCustomColor'
import { Attachments } from './components/attachments/Attachments'
import NotificationSnackbar from './components/notifications/NotificationSnackbar'

import { AttachmentsUploadDialog } from './components/attachments/AttachmentsUploadDialog'
//components
export {
  OneplaceLibraryContextProvider,
  MockOneplaceLibraryContext,
  CheckboxControl,
  DateControl,
  DateTimeControl,
  TimeControl,
  NumberControl,
  PercentageControl,
  SelectControl,
  SelectManyControl,
  SignatureControl,
  SubtotalControl,
  TextAreaControl,
  TextControl,
  TimerControl,
  UnsupportedControl,
  DataGridControl,
  DynamicDataGridControl,
  DynamicDataListControl,
  FixedDataGridControl,
  Client,
  Api,
  Question,
  QuestionGroup,
  AutoComplete,
  DropdownAutoComplete,
  AssignmentForm,
  ValidationErrors,
  TabsComponent,
  PageLoader,
  HelpImages,
  PhotosField,
  AddPhotoButton,
  PhotoDialog,
  Gallery,
  NotificationDialog,
  NotificationSnackbar,
  TextChipListControl,
  CheckCircleCustomColor,
  Attachments,
  AttachmentsUploadDialog
}

//interfaces and types
export type ITabDefinition = tabTypes.ITabDefinition
export type ITabsState = tabTypes.ITabsState
export type DeepPartial<T> = types.DeepPartial<T>
export type Omit<T, K extends string> = types.Omit<T, K>
export type IConfig = types.IConfig
export type AssigneeType = types.AssigneeType
export type IControlProps = formControlTypes.IControlProps
export type SelectOptions = formControlTypes.SelectOptions
export type ISelectionLists = formControlTypes.ISelectionLists
export type ICheckboxControlProps = checklistControl.ICheckboxControlProps
export type ITextAreaControlProps = textAreaControl.ITextAreaControlProps
export type ISelectControlProps = selectControl.ISelectControlProps
export type ISignatureControlProps = signatureControl.ISignatureControlProps
export type ISignatureValue = signatureControl.ISignatureValue
export type ITimerControlProps = timerControl.ITimerControlProps
export type ITimerValue = timerControl.ITimerValue
export type IClient = client.default
export type IClientOption = client.IClientOptions
export type IApi = api.IApi
export type IApiClientConfig = api.IApiClientConfig
export type IOneplaceLibraryConfigProviderProps =
  oneplaceLibraryProvider.IOneplaceLibraryConfigProviderProps
export type IOneplaceLibrary = oneplaceLibraryProvider.IOneplaceLibrary
export type IOneplaceLibraryContextProp =
  oneplaceLibraryProvider.IOneplaceLibraryContextProp
export type IDataGridControlProps = dataGridControl.IDataGridControlProps
export type IDynamicDataGridControlProps =
  dynamicDataGridControl.IDynamicDataGridControlProps
export type IDynamicDataListControlProps =
  dynamicDataListControl.IDynamicDataListControlProps
export type IFixedDataGridControlProps =
  fixedDataGridControl.IFixedDataGridControlProps
export type IQuestionProps = question.IQuestionProps
export type IQuestionGroupProps = questionGroup.IQuestionGroupProps
export type IAutoCompleteProps = autoComplete.IAutoCompleteProps
export type IAssigneeFormProps = assignmentForm.IAssigneeFormProps
export type IAssignment = assignmentForm.IAssignment
export type IAutoCompleteOption = autoComplete.IAutoCompleteOption
export type IValidationErrorsProps = validationErrors.IValidationErrorsProps
export type IChecklist = checklistModels.IChecklist
export type IChecklistGroup = checklistModels.IChecklistGroup
export type IChecklistField = checklistModels.IChecklistField
export type IPhoto = checklistModels.IPhoto
export type IPhotoComment = checklistModels.IPhotoComment
export type ISubmitChecklistResponse = checklistModels.ISubmitChecklistResponse
export type IPhotoHelp = checklistModels.IPhotoHelp
export type IChecklistRenderProps = checklistModels.IChecklistRenderProps
export type IFieldRenderProps = checklistModels.IFieldRenderProps
export type IChecklistColorRule = checklistModels.IChecklistColorRule
export type IChecklistTicketJson = checklistModels.IChecklistTicketJson
export type IChecklistEmailResponse = checklistModels.IChecklistEmailResponse
export type IAttachment = checklistModels.IAttachment
export type IChecklistSearchParams = checklistModels.IChecklistSearchParams
export type IChecklistTemplates = checklistModels.IChecklistTemplates
export type IFavouriteChecklist = checklistModels.IFavouriteChecklist
export type ICompletedChecklist = checklistModels.ICompletedChecklist
export type IChecklistTemplateList = checklistModels.IChecklistTemplateList
export type IStoredChecklist = checklistModels.IStoredChecklist
export type IStoredChecklistMeta = checklistModels.IStoredChecklistMeta
export type IChecklistTemplate = checklistModels.IChecklistTemplate
export type IAddRenderPropsParams = checklistModels.IAddRenderPropsParams
export type IChecklistTemplateMeta = checklistModels.IChecklistTemplateMeta
export type IGroupRenderProps = checklistModels.IGroupRenderProps
export type IDataGridFieldProps = checklistModels.IDataGridFieldProps
export type ICustomField = customFieldModels.ICustomField
export type ICustomFieldMeta = customFieldModels.ICustomFieldMeta
export type CustomFieldType = customFieldModels.CustomFieldType
export type IHelpInfo = customFieldModels.IHelpInfo
export type IEmailFields = emailTypes.IEmailFields
export type IFranchise = franchiseModels.IFranchise
export type IFranchisee = franchiseModels.IFranchisee
export type IFranchisees = franchiseModels.IFranchisees
export type ISites = franchiseModels.ISites
export type ISite = franchiseModels.ISite
export type ITag = franchiseModels.ITag
export type ITags = franchiseModels.ITags
export type IAttribute = franchiseModels.IAttribute
export type IContact = franchiseModels.IContact
export type IAddress = franchiseModels.IAddress
export type IChecklistConditionalRule =
  conditionalRuleModels.IChecklistConditionalRule
export type IImageStorage = IImageStorageModule.IImageStorage
export type ImageStorageType = IImageStorageModule.ImageStorageType
export type IEnvironment = IEnvironmentModule.IEnvironment
export type ISettings = ISettingsModule.ISettings
export type AssetType = AssetCacheManager.AssetType
export type ICacheManager<T> = AssetCacheManager.ICacheManager<T>
export type IPerson = personModels.IPerson
export type IPersonType = personModels.IPersonType
export type AssociationType = personModels.AssociationType
export type IPersonContact = personModels.IPersonContact
export type IIntegration = integrationModels.IIntegration
export type IIntegrationApp = integrationModels.IIntegrationApp
export type IIntegrationField = integrationModels.IIntegrationField
export type IIntegrationFieldMapping =
  integrationModels.IIntegrationFieldMapping
export type IIntegrationSchedule = integrationModels.IIntegrationSchedule
export type IIntegrationAuthDetails = integrationModels.IIntegrationAuthDetails
export type INotification = notificationModels.INotification
export type IEmailTemplate = emailTemplateModels.IEmailTemplate
export type IMyNotificationsWrapper = notificationModels.IMyNotificationsWrapper
export type INotificationCustomField =
  notificationModels.INotificationCustomField
export type INotificationTicket = notificationModels.INotificationTicket
export type INotificationRecipient = notificationModels.INotificationRecipient
export type INotificationFCMData = notificationModels.INotificationFCMData
export type IIDAndName = entity.IIDAndName
export type IIDAndLabel = entity.IIDAndLabel
export type INameRecStatus = entity.INameRecStatus
export type RecStatus = entity.RecStatus
export type IAttachmentResponse = entity.IAttachmentResponse
export type ISubmitAttachmentResponse = entity.ISubmitAttachmentResponse
export type IResponse = entity.IResponse
export type IListWrapper = entity.IListWrapper
export type CheckCircleCustomColorProps =
  checkCircleCustomColor.CheckCircleCustomColorProps
export const getFranchisee = franchiseModels.getFranchisee
export const getSite = franchiseModels.getSite
export const getSiteFranchiseeId = franchiseModels.getSiteFranchiseeId
export const hasConditionApplied = conditionalQuestion.hasConditionApplied
export const isConditionalRuleShow = conditionalQuestion.isConditionalRuleShow
export const findChecklistGroupById = conditionalQuestion.findChecklistGroupById
export const updateDynamicDatalistQuestions =
  conditionalQuestion.updateDynamicDatalistQuestions
export const OneplaceLibraryContext =
  oneplaceLibraryProvider.OneplaceLibraryContext
export const withOneplaceLibraryContext =
  oneplaceLibraryProvider.withOneplaceLibraryContext
export const STORED_IMAGE_PREFIX = IImageStorageModule.STORED_IMAGE_PREFIX
export const TEMP_IMAGE_PREFIX = IImageStorageModule.TEMP_IMAGE_PREFIX
export const getImageStorageType = IImageStorageModule.getImageStorageType
export const isLocalImage = IImageStorageModule.isLocalImage
export const SiteAddressFields = franchiseModels.SiteAddressFields
export const selectPropsConverter = controlProps.selectPropsConverter
export const TabsContext = tabTypes.TabsContext
export const findSite = franchiseModels.findSite
export const getFranchiseeIdBySite = franchiseModels.getFranchiseeIdBySite
export const GRID_CELLPADDING = gridConsts.GRID_CELLPADDING
export const GRID_CELLFONTSIZE = gridConsts.GRID_CELLFONTSIZE
export const getRenderProps = renderProps.getRenderProps
export const updateQuestionNumbers = renderProps.updateQuestionNumbers
export const validateChecklist = validateChecklistUtils.validateChecklist
export const validateGroups = validateChecklistUtils.validateGroups
export const getControlForField = customFieldModels.getControlForField
export const EmailTemplateType = emailTemplateModels.EmailTemplateType
export {
  i18n,
  dateUtils,
  utils,
  accessoryFieldsUtils,
  attachmentUtils,
  markdownDataUtils
}
