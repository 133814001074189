import * as React from 'react'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Input from '@material-ui/core/Input'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'

export interface ITextChipListControlProps extends IOneplaceLibraryContextProp {
  label: string
  value: string
  setFieldValue: (value: string) => void
}

export interface ITextChipListControlState {
  addDialogOpen: boolean
  addDialogValue: string
  addDialogValueValid: boolean
}

export default withOneplaceLibraryContext(
  class TextChipListControl extends React.Component<
    ITextChipListControlProps,
    ITextChipListControlState
  > {
    constructor(props: any) {
      super(props)
      this.openAddDialog = this.openAddDialog.bind(this)
      this.closeAddDialog = this.closeAddDialog.bind(this)
      this.onAddDialogValueChange = this.onAddDialogValueChange.bind(this)
      this.onAddButtonClicked = this.onAddButtonClicked.bind(this)
      this.onRemoveButtonClicked = this.onRemoveButtonClicked.bind(this)
      this.state = {
        addDialogOpen: false,
        addDialogValue: '',
        addDialogValueValid: false
      }
    }

    getValue(): string {
      return this.props.value || ''
    }

    getValueArray(value: string): Array<string> {
      return value
        .split(',')
        .map((item) => item.trim())
        .filter((item) => Boolean(item))
    }

    openAddDialog(): void {
      this.setState({
        addDialogOpen: true,
        addDialogValue: '',
        addDialogValueValid: false
      })
    }

    closeAddDialog(): void {
      this.setState({ addDialogOpen: false })
    }

    onAddDialogValueChange(event: React.ChangeEvent<HTMLInputElement>): void {
      const newVal = String(event.target.value || '')
      this.setState({
        addDialogValue: newVal,
        addDialogValueValid: Boolean(newVal.trim())
      })
    }

    onAddButtonClicked(): void {
      const newVal = String(this.state.addDialogValue || '').trim()
      if (newVal) {
        const items = this.getValueArray(this.getValue())
        items.push(newVal)
        this.closeAddDialog()
        this.props.setFieldValue(items.join(', '))
      }
    }

    onRemoveButtonClicked(index: number): void {
      const items = this.getValueArray(this.getValue())
      items.splice(index, 1)
      this.props.setFieldValue(items.join(', '))
    }

    render() {
      const value = this.getValue()
      const items = this.getValueArray(value)
      return (
        <div style={{ marginTop: 20, display: 'flex', flexWrap: 'wrap' }}>
          {items.map((item, itemIdx) => (
            <Chip
              key={itemIdx}
              style={{ margin: 2 }}
              label={item}
              onDelete={() => this.onRemoveButtonClicked(itemIdx)}
            />
          ))}
          <Button
            style={{ margin: 2, padding: 0, minWidth: 36, minHeight: 32 }}
            onClick={this.openAddDialog}
          >
            <AddIcon />
          </Button>
          <Dialog
            disableBackdropClick={true}
            open={this.state.addDialogOpen}
            onClose={this.closeAddDialog}
            PaperProps={{ style: { width: '90%', maxWidth: 700 } }}
          >
            <DialogTitle>{this.props.label}</DialogTitle>
            <DialogContent style={{ paddingBottom: 0 }}>
              <Input
                fullWidth
                value={this.state.addDialogValue}
                onChange={this.onAddDialogValueChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeAddDialog} color='primary'>
                {this.props.ctx.i18next.t('close')}
              </Button>
              <Button
                onClick={this.onAddButtonClicked}
                color='primary'
                disabled={!this.state.addDialogValueValid}
              >
                {this.props.ctx.i18next.t('add')}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
  }
)
