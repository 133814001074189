let Sentry: any = null

function checkSentry() {
  if (!Sentry) {
    Sentry = cordova.require('sentry-cordova.Sentry')
  }
}

export function logInfo(info: string) {
  checkSentry()
  if (Sentry) {
    Sentry.captureMessage(info)
  }
  console.log(info)
}

export function logError(error: any, message?: string) {
  if (Sentry) {
    checkSentry()
    const sentryData: any = {}
    if (message) {
      sentryData.extra = { message }
    }
    Sentry.captureException(error, sentryData)
  }
  console.error(error, message)
}
