import { IImageStorage } from './imagestorage/imageStorage'
import { DexieImageStorage } from './imagestorage/'
import { ICacheManager } from './assets/AssetCacheManager'
import IClient, { IClientOptions } from './index'
import { IApi } from './api/api'
import { AssetType } from './assets/AssetCacheManager'
import { withTimeout } from '../utils'
import { InMemoryCacheManager } from './assets/InMemoryCacheManager'
import { logError } from '../logging'
import { ISettings } from '../settings/settings'
import { IAttachment } from '../models/Checklist'
import { IAttachmentResponse } from '../models/Entity'

interface IResult {
  response: string
}

export class Client implements IClient {
  imageStorage: IImageStorage
  api: IApi
  defaultTimeout: number
  cache: ICacheManager<IResult>
  defaultSettings: ISettings

  constructor(options: IClientOptions) {
    //    this.cacheManager =
    //      options.cacheManager ||
    //      new NoCacheManager(
    //        () => true,
    //        () => true
    //      )
    this.defaultSettings = options.settings
    this.imageStorage = options.imageStorage || new DexieImageStorage()
    this.api = options.api
    this.defaultTimeout = options.defaultTimeout
      ? options.defaultTimeout
      : 180000
    this.cache = new InMemoryCacheManager<IResult>(
      () => true,
      () => true
    )
  }
  getAuthenticatedPhotoUrl(url: string): string {
    return this.api.getAuthenticatedPhotoUrl(url)
  }

  async saveAttachment(attachment: IAttachment): Promise<IAttachmentResponse> {
    return this.api.saveAttachment(attachment)
  }

  viewAttachment(attachment: IAttachment): void {
    this.api.viewAttachment(attachment)
  }

  async deleteAttachment(attachment: IAttachment): Promise<any> {
    return this.api.deleteAttachment(attachment)
  }

  // returns a url that could be cached
  async getLocalAssetUrl(
    type: AssetType,
    webUrl: string,
    _accessToken?: string,
    createObjectUrl?: boolean
  ): Promise<string> {
    let cacheLocalUrl: string | null

    // Use the full URL as the cache key
    const cacheKey = `${type}_${webUrl}`
    const httpOptions = {}
    const localUrl = this.cache.get(cacheKey)
    if (localUrl) {
      cacheLocalUrl = await this.imageStorage.getImageObjectUrl(
        localUrl.response
      )
      console.log('cached: ', cacheLocalUrl)
    } else {
      try {
        const res = await withTimeout(
          this.defaultTimeout,
          fetch(webUrl, httpOptions)
        )
        if (res.ok) {
          const data = await res.blob()
          cacheLocalUrl = await this.imageStorage.storeImage(data, 'temporary')
          this.cache.add(cacheKey, { response: cacheLocalUrl } as IResult)
          console.log('newly cached: ', cacheKey)
          console.log('newly cached cacheLocalUrl: ', cacheLocalUrl)
        } else {
          throw new Error(
            `Got status ${res.status} while downloading ${webUrl}`
          )
        }
      } catch (e) {
        logError(e)
        throw new Error(e)
      }
    }
    if (createObjectUrl) {
      return await this.imageStorage.getImageObjectUrl(cacheLocalUrl)
    } else {
      return cacheLocalUrl
    }
  }
  getSettings(): Promise<ISettings> {
    // fake promise as this will be stored in a database
    return new Promise((resolve) => {
      resolve(this.defaultSettings)
    })
  }
}
export default Client
