import * as React from 'react'
import {
  IChecklistField,
  IPhoto,
  IFieldRenderProps,
  IChecklistTicketJson,
  IAttachment,
  IQuestionIconOptions
} from '../../models/Checklist'

import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'
import { UnsupportedControl } from '../form_controls/UnsupportedControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Collapse from '@material-ui/core/Collapse'
import { PhotoDialog } from '../photos/PhotoDialog'
import { DeepPartial } from '../../types'
import { IControlProps } from '../form_controls/types'
import { fieldControls } from './utils/controls'
import {
  fieldToControlValue,
  controlValueToField,
  isDataGridComponent
} from './utils/controlValues'
import { fieldToControlProps } from './utils/controlProps'
import { IImageStorage } from '../../data_sources/imagestorage/imageStorage'
import { presetTicketMatch } from './utils/shouldShowQuestionTicket'
import { PhotosField } from '../photos/PhotosField'
import { BasicDialog } from '../common/BasicDialog'
import {
  FormControl,
  Typography,
  Card,
  CardContent,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  FormLabel,
  IconButton
} from '@material-ui/core'
import CommentIcon from '@material-ui/icons/Comment'
import HelpIcon from '@material-ui/icons/Help'
import HelpImageIcon from '@material-ui/icons/Image'
import { HelpImages } from './HelpImages'
import {
  IOneplaceLibraryContextProp,
  withOneplaceLibraryContext
} from '../OneplaceLibraryProvider'
import AddPhotoButton from '../photos/AddPhotoButton'
import AddAttachmentButton from '../attachments/AddAttachmentButton'
import { isAllowed, isMandatory } from './utils/accessoryFields'
import { AttachmentsField } from '../attachments/AttachmentsField'
import AttachmentDialog from '../attachments/AttachmentDialog'
import { AttachmentsUploadDialog } from '../attachments/AttachmentsUploadDialog'
import {
  hasAttachments,
  hasPhotos,
  createPhotoAttachment
} from './utils/attachments'
import { isDefinedAndNotNull } from '../../utils'
import {
  getMenuIconCount,
  hasHelp,
  IconStatusType,
  menuIconClass,
  ITEM_HEIGHT,
  MAX_ICONS
} from './utils/questionIcons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { makePhotosPermanent } from '../photos/utils/imageUtils'
import { Camera } from '../photos/Camera'
import { CheckCircle } from '@material-ui/icons'
import {
  allQuestionsAnsweredForEachGroup,
  selectManyQuestionAnswered
} from '../../utils/questionAnswers'
import { ITimerValue } from '../form_controls/TimerControl'

export const CHECKLIST_QUESTION_AVOID_CONDITIONAL_QUESTION_REFRESH =
  'avoidConditionalQuestionRefresh-'

const styles = (theme: Theme) =>
  createStyles({
    labelContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    mandatoryQuestion: {
      color: theme.palette.secondary.main
    },
    hasContent: {
      color: theme.palette.primary.light
    },
    mandatoryContent: {
      color: theme.palette.secondary.main
    },
    moreVertHasContent: {
      margin: '0 12px',
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
      '&:hover': { backgroundColor: theme.palette.primary.light, color: '#fff' }
    },
    moreVertMandatoryContent: {
      margin: '0 12px',
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: '#fff'
      }
    },
    moreVert: {
      margin: '0 12px',
      backgroundColor: theme.palette.action.active,
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.action.active,
        color: '#fff'
      }
    },
    actionsContainer: {
      display: 'flex',
      maxWidth: '150px',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    },
    questionWrapper: {
      display: 'flex',
      padding: '0 24px 12px',
      margin: '12px 0'
    },
    datagridQuestionWrapper: {
      padding: '0 24px 12px',
      margin: '12px 0'
    },
    hidden: {
      display: 'none'
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: '1 1 auto'
    },
    content: {
      padding: '0 16px 24px 16px',
      flex: '1 0 auto'
    },
    cover: {
      flex: '0 0 51px',
      paddingTop: '12px'
    },
    tabCompletedIcon: {
      width: 20,
      height: 20,
      marginLeft: 6,
      color: theme.palette.secondary.main
    }
  })

export interface IQuestionProps
  extends WithStyles<typeof styles>,
    IOneplaceLibraryContextProp {
  cardKey: number
  groupIndex: number
  question: IChecklistField
  imageStorage: IImageStorage
  renderProps: IFieldRenderProps
  dateTimeFormat: string
  dateFormat: string
  fullName?: string
  controlSuffix?: string
  rowIndex?: number // used in dynamic data list
  onFieldChange: (groupIndex: number, fieldId: number) => void
  onTicketPreset: (
    question: IChecklistField | null,
    preset: IChecklistTicketJson
  ) => void
  validateConditionalQuestion?: (
    newValue: string,
    question: IChecklistField | null,
    rowIndex?: number
  ) => void
  parentQuestionId?: number // used in dynamic data list
  hideQuestionNumber?: boolean
  onDeletePhoto?: (photo: IPhoto) => void
  allowSavedPhotoDeletion?: boolean
  allowPhotosFromGallery?: boolean
}

export interface IQuestionState {
  value: any
  commentShown: boolean
  comment: string
  photoDialogMode: 'new' | 'edit'
  photoDialogPhoto: IPhoto | null
  photoDialogOpen: boolean
  helpDialogOpen: boolean
  helpDialogTitle: string
  helpDialogContent: React.ReactElement<any>
  helpImagesOpen: boolean
  helpImagesList: Array<{ url: string }>
  hidden: boolean
  attachmentDialogAttachment: IPhoto | null
  attachmentDialogOpen: boolean
  selectedAttachments: IAttachment[]
  attachmentsUploadDialogOpen: boolean
  iconMenuAnchor: any
  cameraOpen: boolean
  allDynamicQuestionsAnswered: boolean
}

export default withStyles(styles)(
  withOneplaceLibraryContext(
    class Question extends React.Component<IQuestionProps, IQuestionState> {
      public static defaultProps = {
        allowSavedPhotoDeletion: false
      }
      control: React.ComponentType<IControlProps>

      isComponentMounted: boolean
      isNonDataGridComponent: boolean

      constructor(props: any) {
        super(props)
        const rowColumnsAnswered = allQuestionsAnsweredForEachGroup(
          this.props.question.dataGrid?.values
        )
        this.state = {
          iconMenuAnchor: null,
          commentShown: Boolean(this.props.question.comment),
          value: this.getValueFromField(),
          comment: this.props.question.comment || '',
          photoDialogOpen: false,
          photoDialogPhoto: null,
          photoDialogMode: 'new',
          helpDialogOpen: false,
          helpDialogTitle: '',
          helpDialogContent: null as any,
          helpImagesOpen: false,
          helpImagesList: [],
          hidden: this.props.question.hidden
            ? this.props.question.hidden
            : false,
          attachmentDialogAttachment: null,
          attachmentDialogOpen: false,
          selectedAttachments: [],
          attachmentsUploadDialogOpen: false,
          cameraOpen: false,
          allDynamicQuestionsAnswered: rowColumnsAnswered.every(
            (rowColumn) => rowColumn
          )
        }
        this.isComponentMounted = false
        this.isNonDataGridComponent = !isDataGridComponent(this.props.question)
        this.control = fieldControls[this.props.question.type]
        if (!this.control) {
          this.control = UnsupportedControl
        }
        this.setFieldValue = this.setFieldValue.bind(this)
        this.onChangeComment = this.onChangeComment.bind(this)
        this.onCommentIconClicked = this.onCommentIconClicked.bind(this)
        this.onPhotoUploaded = this.onPhotoUploaded.bind(this)
        this.onPhotoSaved = this.onPhotoSaved.bind(this)
        this.onPhotoDeleted = this.onPhotoDeleted.bind(this)
        this.onPhotoClicked = this.onPhotoClicked.bind(this)
        this.onPhotoDialogClosed = this.onPhotoDialogClosed.bind(this)
        this.onMenuItemClick = this.onMenuItemClick.bind(this)
        this.onMenuItemClose = this.onMenuItemClose.bind(this)
        this.removeFirstColumnOfDataGrid =
          this.removeFirstColumnOfDataGrid.bind(this)
        this.attachStyle = this.attachStyle.bind(this)
      }

      shouldComponentUpdate(
        nextProps: IQuestionProps,
        nextState: IQuestionState
      ) {
        if (nextProps.question != this.props.question) {
          return true
        } else if (nextState != this.state) {
          return true
        } else if (nextProps.question.hidden != this.state.hidden) {
          this.setState({
            hidden: !this.state.hidden
          })
          return true
        } else if (nextProps.renderProps.questionNeedRefresh) {
          // added questionNeedRefresh for quesiton number change

          // reset flag to false make sure only run once
          nextProps.renderProps.questionNeedRefresh = false
          return true
        }
        return false
      }

      getValueFromField(): any {
        const field = this.props.question
        return fieldToControlValue(field)
      }

      setFieldValue(_fieldName: string, newValue: any) {
        let avoidConditionalQuestionRefresh = false

        if (
          String(newValue).includes(
            CHECKLIST_QUESTION_AVOID_CONDITIONAL_QUESTION_REFRESH
          )
        ) {
          // for percentage control, we only want to apply conditional rule when dragging end
          newValue = newValue.replace(
            CHECKLIST_QUESTION_AVOID_CONDITIONAL_QUESTION_REFRESH,
            ''
          )
          avoidConditionalQuestionRefresh = true
        }
        const field = this.props.question
        controlValueToField(newValue, field)
        this.setState({
          value: newValue
        })
        this.props.onFieldChange(this.props.groupIndex, this.props.question.id)

        // refresh page for conditional questions.
        if (
          !avoidConditionalQuestionRefresh &&
          this.props.validateConditionalQuestion != undefined &&
          this.props.question.conditions
        ) {
          if (this.props.question.conditions.length > 0) {
            this.props.validateConditionalQuestion(
              newValue,
              this.props.question,
              this.props.rowIndex
            )
          } else {
            // not a trigger question, we don't need to refresh conditional questions
            this.props.validateConditionalQuestion('', null)
          }
        }

        const match = presetTicketMatch(field, field.value)
        if (match) {
          this.props.onTicketPreset(this.props.question, match)
        }

        // special case for dynamic data grid since the icon
        // needs to appear on question level
        if (this.props.question.type === 'dynamicdatagrid') {
          const allRowQuestionsAnswered = allQuestionsAnsweredForEachGroup(
            this.props.question.dataGrid?.values
          )
          this.setState({
            allDynamicQuestionsAnswered: allRowQuestionsAnswered.every(
              (rowColumnAnswered) => rowColumnAnswered
            )
          })
        }
      }

      onChangeComment(event: any) {
        const comment = event.target.value
        this.props.question.comment = comment
        this.setState({ comment })
        this.props.onFieldChange(this.props.groupIndex, this.props.question.id)
      }

      onCommentIconClicked() {
        this.setState((state) => ({
          commentShown: !state.commentShown,
          iconMenuAnchor: null
        }))
      }

      ///////////// Attachment Methods /////////////
      onAttachmentsSelected = (attachments: IAttachment[]) => {
        this.setState(
          {
            selectedAttachments: attachments,
            attachmentsUploadDialogOpen: true,
            iconMenuAnchor: null
          },
          () => {
            this.state.selectedAttachments
              .filter((att) => att.status === null)
              .forEach((att) => this.saveAttachment(att))
          }
        )
      }

      saveAttachment = (attachment: IAttachment) => {
        if (
          attachment.status === 'Saving' ||
          attachment.status === 'Saved' ||
          attachment.localObjectUrl === null
        ) {
          return
        }

        attachment.status = 'Saving'
        attachment.forEntity = 'checklist'
        // if DDL
        if (
          isDefinedAndNotNull(this.props.rowIndex) &&
          isDefinedAndNotNull(this.props.cardKey)
        ) {
          attachment.fieldId = this.props.parentQuestionId!
          attachment.rowIdx = this.props.rowIndex! + 1
          attachment.colIdx = this.props.cardKey + 1
        } else {
          attachment.fieldId = this.props.question.id
        }
        this.updateAttachment(attachment)

        this.props.ctx.client
          .saveAttachment(attachment)
          .then((response) => {
            if (attachment.status !== 'Canceled') {
              attachment.uuid = response.UUID
              attachment.url = response.url
              attachment.status = 'Saved'
              this.addSavedAttachmentToField(attachment)
              this.revokeURLForAttachment(attachment.localObjectUrl)
            }
          })
          .catch((error) => {
            console.error(
              'Error uploading attachment: ' + JSON.stringify(error)
            )
            if (attachment.status !== 'Canceled') {
              attachment.status = 'Error'
            }
          })
          .finally(() => {
            this.updateAttachment(attachment)
          })
      }

      addSavedAttachmentToField(attachment: IAttachment) {
        if (
          this.props.question.photos === undefined ||
          this.props.question.photos === null
        ) {
          this.props.question.photos = []
        }
        this.props.question.photos.push(createPhotoAttachment(attachment))
        this.props.onFieldChange(this.props.groupIndex, this.props.question.id)
      }

      onRetryUpload = (attachment: IAttachment) => {
        this.saveAttachment(attachment)
      }

      onCancelUpload = (attachment: IAttachment) => {
        attachment.status = 'Canceled'
        this.revokeURLForAttachment(attachment.localObjectUrl)
        this.updateAttachment(attachment)
      }

      onAttachmentClicked = (attachment: IPhoto) => {
        if (attachment.url) {
          this.setState({
            attachmentDialogAttachment: attachment,
            attachmentDialogOpen: true
          })
        }
      }

      onAttachmentDeleted = (attachment: IAttachment) => {
        if (!this.props.question.photos) {
          return
        }
        const idx = this.props.question.photos.findIndex(
          (att) =>
            (attachment.uuid && attachment.uuid === att.uuid) ||
            (attachment.id && attachment.id === att.id)
        )
        if (idx !== -1) {
          this.props.question.photos.splice(idx, 1)
          if (attachment.id && attachment.id > 0) {
            this.props.onDeletePhoto?.(createPhotoAttachment(attachment))
          }
        }
        this.props.onFieldChange(this.props.groupIndex, this.props.question.id)
        this.setState({
          attachmentDialogAttachment: null,
          attachmentDialogOpen: false
        })
      }

      onAttachmentDialogClosed = () => {
        this.setState({
          attachmentDialogAttachment: null,
          attachmentDialogOpen: false
        })
      }

      onAttachmentsUploadDialogClosed = () => {
        //make sure we revoke all object url
        this.state.selectedAttachments.forEach((att) =>
          this.revokeURLForAttachment(att.localObjectUrl)
        )
        this.setState({
          selectedAttachments: [],
          attachmentsUploadDialogOpen: false
        })
      }

      updateAttachment = (attachment: IAttachment) => {
        this.state.selectedAttachments.forEach((att, index) => {
          if (att.localObjectUrl === attachment.localObjectUrl) {
            const attachmentsCopy = [...this.state.selectedAttachments] //array copy
            /* we don't copy the object because having a
            reference to it allows us to easily and quickly 'cancel' the upload*/
            attachmentsCopy[index] = attachment
            this.setState({ selectedAttachments: attachmentsCopy })
          }
        })
      }

      onAttachmentCommentAdded = (newComment: string) => {
        if (!this.props.question.photos) return

        const attachment = this.state.attachmentDialogAttachment!
        const photo = this.props.question.photos.find(
          (currentPhoto) =>
            (attachment.uuid && attachment.uuid === currentPhoto.uuid) ||
            (attachment.id && attachment.id === currentPhoto.id)
        )

        if (photo) {
          photo.comment = newComment
          this.props.onFieldChange(
            this.props.groupIndex,
            this.props.question.id
          )
          this.onAttachmentDialogClosed()
        }
      }

      revokeURLForAttachment = (url: string | null | undefined) => {
        if (url) {
          URL.revokeObjectURL(url)
        }
      }
      ///////////// End Attachment Methods /////////////

      async onPhotoUploaded(photos: IPhoto[]) {
        // if user selected only one photo, we show preview and option to add comment
        if (photos.length === 1) {
          this.setState({
            photoDialogMode: 'new',
            photoDialogPhoto: photos[0],
            photoDialogOpen: true,
            iconMenuAnchor: null,
            cameraOpen: false //reset flag (in case we're using native)
          })
        }
        // otherwise we just add all photos
        else {
          await makePhotosPermanent(
            photos,
            this.props.imageStorage,
            this.props.ctx.env.os == 'windows'
          )
          if (!this.props.question.photos) {
            this.props.question.photos = []
          }
          this.props.question.photos.push(...photos)
          this.props.onFieldChange(
            this.props.groupIndex,
            this.props.question.id
          )
          this.forceUpdate()
        }
      }

      onPhotoSaved(photo: IPhoto, takeAnotherPhoto: boolean) {
        if (this.state.photoDialogMode == 'new') {
          if (!this.props.question.photos) {
            this.props.question.photos = []
          }
          this.props.question.photos.push(photo)
        }
        this.setState({
          photoDialogOpen: false,
          cameraOpen: takeAnotherPhoto
        })
        this.props.onFieldChange(this.props.groupIndex, this.props.question.id)
      }

      onPhotoDeleted(photo: IPhoto) {
        if (this.props.question.photos) {
          const photoIdx = this.props.question.photos.findIndex(
            (storedPhoto) => storedPhoto.url == photo.url
          )
          if (photoIdx > -1) {
            // TODO: Delete photo from storage
            this.props.question.photos.splice(photoIdx, 1)
            this.props.onDeletePhoto?.(photo)
          }
        }
        this.setState({
          photoDialogOpen: false
        })
        this.props.onFieldChange(this.props.groupIndex, this.props.question.id)
      }

      onPhotoClicked(photo: IPhoto) {
        this.setState({
          photoDialogMode: 'edit',
          photoDialogPhoto: photo,
          photoDialogOpen: true
        })
      }

      onPhotoDialogClosed() {
        this.setState({
          photoDialogPhoto: null,
          photoDialogOpen: false
        })
      }

      onHelpIconClicked = () => {
        const field = this.props.question
        const helpDialogTitle = field.header || ''
        const helpDescription = field.description || ''

        const HelpLink = (props: { url: string }) => {
          if (this.props.ctx.env.platform == 'cordova') {
            return (
              <a
                href=''
                onClick={(e) => {
                  e.preventDefault()
                  cordova.InAppBrowser.open(props.url, '_system')
                }}
              >
                {props.url}
              </a>
            )
          } else {
            return (
              <a href={props.url} target='_blank' rel='noopener noreferrer'>
                {props.url}
              </a>
            )
          }
        }

        this.setState({
          helpDialogOpen: true,
          helpDialogTitle,
          helpDialogContent: (
            <Typography component='div' style={{ whiteSpace: 'pre-line' }}>
              {helpDescription}
              {field.helpLinks && field.helpLinks.length && (
                <>
                  <p>
                    <b>Links:</b>
                  </p>
                  <ul>
                    {field.helpLinks.map((link) => (
                      <li key={link.id}>
                        <HelpLink url={link.link} />
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Typography>
          )
        })
        if (this.state.iconMenuAnchor) {
          this.onMenuItemClose()
        }
      }

      onHelpImagesIconClicked = () => {
        const field = this.props.question
        if (field.helpImages) {
          this.setState({
            helpImagesOpen: true,
            helpImagesList: field.helpImages
          })
        }
        if (this.state.iconMenuAnchor) {
          this.onMenuItemClose()
        }
      }

      onHelpDialogClosed = () => {
        this.setState({ helpDialogOpen: false })
      }

      onHelpImagesClosed = () => {
        this.setState({ helpImagesOpen: false })
      }

      onMenuItemClick(event: any): void {
        this.setState({ iconMenuAnchor: event.currentTarget })
      }

      onMenuItemClose(): void {
        this.setState({ iconMenuAnchor: null })
      }

      removeFirstColumnOfDataGrid(dataGridStringValue: string): any[] {
        if (dataGridStringValue === '') {
          return []
        }
        // value is from question root rather than dataGrid.values
        // need to remove the first entry for each array since that is already
        // given and first header is usually blank
        const arrayOfDataGridValues: any[] = JSON.parse(dataGridStringValue)
        const modifiedArray = arrayOfDataGridValues.map((row: any[]) => {
          const newRow = [...row]
          newRow.shift()
          return newRow
        })

        return modifiedArray.map((group: any[]) => {
          return group.every((value: any) => value && value !== '')
        })
      }

      attachStyle(data: boolean[]): Record<string, any> {
        let style = {}
        if (!data.every((question) => question)) {
          style = {
            borderLeft: '5px solid #ff5983'
          }
        } else {
          style = {}
        }
        return style as Record<string, any>
      }

      insertStyleForTrackedQuestions = (question: IChecklistField) => {
        let trackedQuestionStyle = {}
        const isDataGrid = isDataGridComponent(question)
        const isPlainDataGrid = question.type === 'datagrid'
        const isDataList = question.type === 'dynamicdatalist'
        const isSelectMany = question.type === 'select_many'

        if (question.mandatory || question.trackField) {
          trackedQuestionStyle = {
            borderLeft: '5px solid #ff5983'
          }
        }
        let isAnswered = false
        if (!isSelectMany) {
          if (question.type === 'timer') {
            const timerValue: ITimerValue = JSON.parse(
              question.value
            ) as ITimerValue
            isAnswered = timerValue.startDate > 0 && timerValue.endDate > 0
          } else if (question.value) {
            isAnswered = true
          }
        }
        if (
          !isDataGrid &&
          ((!isSelectMany && isAnswered) ||
            (isSelectMany && selectManyQuestionAnswered(question.values)))
        ) {
          trackedQuestionStyle = {}
        }

        if (isDataList) {
          return {}
        }

        if (isDataGrid) {
          if (isPlainDataGrid && (question.mandatory || question.trackField)) {
            const plainDataGridAnswers = this.removeFirstColumnOfDataGrid(
              question.value
            )
            return this.attachStyle(plainDataGridAnswers)
          }

          const allRowQuestionsAnswered = allQuestionsAnsweredForEachGroup(
            question.dataGrid?.values
          )

          trackedQuestionStyle = this.attachStyle(allRowQuestionsAnswered)
        }

        return trackedQuestionStyle
      }

      render() {
        let idSuffix = ''
        if (this.props.controlSuffix) {
          idSuffix = this.props.controlSuffix
        }
        const field = this.props.question
        const Control = this.control
        const controlId = `Q${this.props.question.id}${idSuffix}`
        const controlProps: DeepPartial<IControlProps> = {
          field: {
            name: controlId,
            value: this.state.value
          },
          form: {
            setFieldValue: this.setFieldValue
          },
          id: controlId,
          fieldId: this.props.question.id,
          imageStorage: this.props.imageStorage,
          onTicketPreset: this.props.onTicketPreset,
          dateFormat: this.props.dateFormat,
          dateTimeFormat: this.props.dateTimeFormat,
          fullName: this.props.fullName,
          /* for DDL, DDG and FDG we don't call "this.onPhotoDeleted()" because the photo
          has already been removed from the collection, so we just need to send the event up*/
          onDeletePhoto: this.props.onDeletePhoto,
          allowSavedPhotoDeletion: this.props.allowSavedPhotoDeletion,
          allowPhotosFromGallery: this.props.allowPhotosFromGallery
        }

        // Add field-specific control properties
        fieldToControlProps(field, controlProps)

        const renderProps = this.props.renderProps || {}
        const labelClass = field.mandatory
          ? this.props.classes.mandatoryQuestion
          : ''

        const commentStatus: IconStatusType = this.state.comment
          ? 'completed'
          : isMandatory(field.commentOption)
          ? 'mandatory'
          : 'not_done'

        const photoStatus: IconStatusType = hasPhotos(field.photos!)
          ? 'completed'
          : isMandatory(field.photoOption)
          ? 'mandatory'
          : 'not_done'

        const attachmentStatus: IconStatusType = hasAttachments(field.photos!)
          ? 'completed'
          : isMandatory(field.attachmentOption)
          ? 'mandatory'
          : 'not_done'

        const questionIconOptions: IQuestionIconOptions = {
          commentIcon: isAllowed(this.props.question.commentOption),
          commentIconClass:
            commentStatus == 'completed'
              ? this.props.classes.hasContent
              : commentStatus == 'mandatory'
              ? this.props.classes.mandatoryContent
              : '',
          commentIconShow: false,
          photoIcon: isAllowed(this.props.question.photoOption),
          photoIconClass:
            photoStatus == 'completed'
              ? this.props.classes.hasContent
              : photoStatus == 'mandatory'
              ? this.props.classes.mandatoryContent
              : '',
          photoIconShow: false,
          attachmentIcon: isAllowed(this.props.question.attachmentOption),
          attachmentIconClass:
            attachmentStatus == 'completed'
              ? this.props.classes.hasContent
              : attachmentStatus == 'mandatory'
              ? this.props.classes.mandatoryContent
              : '',
          attachmentIconShow: false,
          helpImageIcon: hasHelp(field.helpImages),
          helpImageIconShow: false,
          helpTextIcon:
            hasHelp(field.header) ||
            hasHelp(field.description) ||
            hasHelp(field.helpLinks),
          helpTextIconShow: false,
          menuIconClass: ''
        }
        const menuCount = getMenuIconCount(questionIconOptions)
        const isMenu = menuCount > MAX_ICONS
        const isMenuStatus = menuIconClass(
          commentStatus,
          photoStatus,
          attachmentStatus
        )
        const isMenuClass =
          isMenuStatus == 'completed'
            ? this.props.classes.moreVertHasContent
            : isMenuStatus == 'mandatory'
            ? this.props.classes.moreVertMandatoryContent
            : this.props.classes.moreVert

        //show   menu icon  or 3 table list
        const questionWrapperClass = this.isNonDataGridComponent
          ? this.props.classes.questionWrapper
          : this.props.classes.datagridQuestionWrapper
        const iconsOrMenu = isMenu ? (
          <div
            className={
              this.isNonDataGridComponent === false
                ? this.props.classes.actionsContainer
                : this.props.classes.cover
            }
          >
            <IconButton
              size='small'
              id={controlId + '-menuIcon'}
              onClick={this.onMenuItemClick}
              className={isMenuClass}
              style={{ marginBottom: '5px' }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={this.state.iconMenuAnchor}
              open={Boolean(this.state.iconMenuAnchor)}
              onClose={this.onMenuItemClose}
              PaperProps={{
                style: {
                  /*Photo icon option has two items and we can't change the
                  menuCount because this would affect the menu render condition*/
                  minHeight:
                    ITEM_HEIGHT *
                    (questionIconOptions.photoIcon ? menuCount + 1 : menuCount)
                }
              }}
            >
              {questionIconOptions.helpImageIcon && (
                <MenuItem
                  onClick={this.onHelpImagesIconClicked}
                  id={controlId + '-help_image_button'}
                >
                  <ListItemIcon>
                    <HelpImageIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={this.props.ctx.i18next.t('question_help_image')}
                  />
                </MenuItem>
              )}
              {questionIconOptions.helpTextIcon && (
                <MenuItem
                  id={controlId + '-help_button'}
                  onClick={this.onHelpIconClicked}
                >
                  <ListItemIcon>
                    <HelpIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={this.props.ctx.i18next.t('question_help')}
                  />
                </MenuItem>
              )}

              {questionIconOptions.photoIcon && (
                <AddPhotoButton
                  env={this.props.ctx.env}
                  controlId={controlId}
                  className={questionIconOptions.photoIconClass}
                  imageStorage={this.props.imageStorage}
                  onPhotoUploaded={this.onPhotoUploaded}
                  standalone={false}
                  allowPhotosFromGallery={this.props.allowPhotosFromGallery}
                />
              )}
              {questionIconOptions.commentIcon && (
                <MenuItem
                  className={questionIconOptions.commentIconClass}
                  id={controlId + '-comment_button'}
                  onClick={this.onCommentIconClicked}
                >
                  <ListItemIcon
                    className={questionIconOptions.commentIconClass}
                  >
                    <CommentIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={this.props.ctx.i18next.t('question_comment')}
                  />
                </MenuItem>
              )}
              {questionIconOptions.attachmentIcon && (
                <AddAttachmentButton
                  platform={this.props.ctx.env.platform}
                  controlId={controlId}
                  className={questionIconOptions.attachmentIconClass}
                  onAttachmentsSelected={this.onAttachmentsSelected}
                  standalone={false}
                />
              )}
            </Menu>
          </div>
        ) : (
          <div
            className={
              this.isNonDataGridComponent === false
                ? this.props.classes.actionsContainer
                : this.props.classes.cover
            }
          >
            {questionIconOptions.helpImageIconShow && (
              <IconButton
                id={controlId + '-help_image_button'}
                onClick={this.onHelpImagesIconClicked}
              >
                <HelpImageIcon />
              </IconButton>
            )}
            {questionIconOptions.helpTextIconShow && (
              <IconButton
                id={controlId + '-help_button'}
                onClick={this.onHelpIconClicked}
              >
                <HelpIcon />
              </IconButton>
            )}
            {questionIconOptions.photoIconShow && (
              <AddPhotoButton
                env={this.props.ctx.env}
                controlId={controlId}
                className={questionIconOptions.photoIconClass}
                imageStorage={this.props.imageStorage}
                onPhotoUploaded={this.onPhotoUploaded}
                allowPhotosFromGallery={this.props.allowPhotosFromGallery}
              />
            )}
            {questionIconOptions.commentIconShow && (
              <IconButton
                id={controlId + '-comment_button'}
                className={questionIconOptions.commentIconClass}
                onClick={this.onCommentIconClicked}
              >
                <CommentIcon />
              </IconButton>
            )}
            {questionIconOptions.attachmentIconShow && (
              <AddAttachmentButton
                platform={this.props.ctx.env.platform}
                controlId={controlId}
                className={questionIconOptions.attachmentIconClass}
                onAttachmentsSelected={this.onAttachmentsSelected}
              />
            )}
          </div>
        )
        return (
          <Card
            role='listitem'
            key={this.props.cardKey}
            style={this.insertStyleForTrackedQuestions(this.props.question)}
            className={`${questionWrapperClass} ${
              this.state.hidden || field.type == 'conditionalRule'
                ? this.props.classes.hidden
                : ''
            }`}
          >
            <div className={this.props.classes.details}>
              <CardContent className={this.props.classes.content}>
                <FormControl fullWidth>
                  <div className={this.props.classes.labelContainer}>
                    <FormLabel
                      htmlFor={controlId}
                      className={labelClass}
                      style={{ whiteSpace: 'pre-line', padding: '8px 0' }}
                    >
                      <div style={{ display: 'flex', alignContent: 'center' }}>
                        {`${
                          (!this.props.hideQuestionNumber &&
                            renderProps.questionNumber) ||
                          ''
                        } ${field.label}`}
                        {field.type === 'dynamicdatagrid' &&
                          this.state.allDynamicQuestionsAnswered && (
                            <CheckCircle
                              className={this.props.classes.tabCompletedIcon}
                            />
                          )}
                      </div>
                    </FormLabel>
                    {this.isNonDataGridComponent === false && iconsOrMenu}
                  </div>
                  <div>
                    <Control {...(controlProps as IControlProps)} />
                  </div>
                </FormControl>
                <Collapse in={this.state.commentShown}>
                  <FormControl fullWidth style={{ marginBottom: 10 }}>
                    <InputLabel shrink={true}>
                      {this.props.ctx.i18next.t('comment')}
                    </InputLabel>
                    <Input
                      id={controlId + '-comment'}
                      fullWidth
                      multiline
                      value={this.state.comment}
                      onChange={this.onChangeComment}
                    />
                  </FormControl>
                </Collapse>
                {hasPhotos(field.photos!) && (
                  <PhotosField
                    photos={field.photos!}
                    onPhotoClicked={this.onPhotoClicked}
                  />
                )}
                {hasAttachments(field.photos!) && (
                  <AttachmentsField
                    attachments={field.photos!}
                    onAttachmentClicked={this.onAttachmentClicked}
                  />
                )}
                {this.state.photoDialogOpen && (
                  <PhotoDialog
                    isOpen={this.state.photoDialogOpen}
                    mode={this.state.photoDialogMode}
                    photo={this.state.photoDialogPhoto!}
                    imageStorage={this.props.imageStorage}
                    onClose={this.onPhotoDialogClosed}
                    onSave={this.onPhotoSaved}
                    onDelete={this.onPhotoDeleted}
                    isMultipleComment={true}
                    fullName={this.props.fullName}
                    allowSavedPhotoDeletion={this.props.allowSavedPhotoDeletion}
                  />
                )}
                {this.state.attachmentDialogOpen && (
                  <AttachmentDialog
                    attachmentEntity='checklist'
                    isOpen={this.state.attachmentDialogOpen}
                    attachment={this.state.attachmentDialogAttachment!}
                    onClose={this.onAttachmentDialogClosed}
                    onDelete={this.onAttachmentDeleted}
                    onAddComment={this.onAttachmentCommentAdded}
                  />
                )}
                {this.state.attachmentsUploadDialogOpen && (
                  <AttachmentsUploadDialog
                    isOpen={this.state.attachmentsUploadDialogOpen}
                    attachments={this.state.selectedAttachments}
                    onClose={this.onAttachmentsUploadDialogClosed}
                    onRetryUpload={this.onRetryUpload}
                    onCancelUpload={this.onCancelUpload}
                  />
                )}
                {this.state.helpDialogOpen && (
                  <BasicDialog
                    isOpen={this.state.helpDialogOpen}
                    title={this.state.helpDialogTitle}
                    content={this.state.helpDialogContent}
                    onClose={this.onHelpDialogClosed}
                  />
                )}
                {this.state.helpImagesOpen && (
                  <HelpImages
                    isOpen={this.state.helpImagesOpen}
                    imageUrls={this.state.helpImagesList}
                    onClose={this.onHelpImagesClosed}
                  />
                )}
              </CardContent>
            </div>
            {this.isNonDataGridComponent && iconsOrMenu}
            <Camera
              open={this.state.cameraOpen}
              onPhotoTaken={async (photo: IPhoto) => {
                await this.onPhotoUploaded([photo])
                this.setState({ cameraOpen: false })
              }}
              onCloseCamera={() => this.setState({ cameraOpen: false })}
            />
          </Card>
        )
      }
    }
  )
)
