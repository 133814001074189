import * as React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import { IPhoto } from '../../models/Checklist'
import {
  IImageStorage,
  isLocalImage
} from '../../data_sources/imagestorage/imageStorage'
import { Card, CardContent, DialogActions, Button } from '@material-ui/core'
import TabsComponent, { ITabDefinition } from '../common/Tabs'
import { PhotoComments } from './PhotoComments'
import { formatDate } from '../../utils/dates'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'

export interface IPhotoDialogProps extends IOneplaceLibraryContextProp {
  mode: 'new' | 'edit'
  photo: IPhoto
  fullName?: string
  dateTimeFormat?: string
  imageStorage: IImageStorage
  isOpen: boolean
  isMultipleComment?: boolean // allow adding multiple comments?
  onClose: () => void
  onSave: (photo: IPhoto, takeAnotherPhoto: boolean) => void
  onDelete: (photo: IPhoto) => void
  allowSavedPhotoDeletion?: boolean
  allowSavedPhotoCommentUpdate?: boolean
  showTakePhotoButton?: boolean
}

export interface IPhotoDialogState {
  // avoid double clicking on the save button
  isClosing: boolean
  comment: string
  // save the dialog dimmesion after loading the image, so we can display the same size for comments tab
  dialogHeight: number
  dialogWidth: number
}

export const PhotoDialog = withOneplaceLibraryContext(
  class extends React.Component<IPhotoDialogProps, IPhotoDialogState> {
    public static defaultProps = {
      allowSavedPhotoDeletion: false,
      showTakePhotoButton: true
    }
    static displayName = 'PhotoDialog'
    constructor(props: any) {
      super(props)
      const firstComment =
        this.props.photo.comments &&
        this.props.photo.comments.filter((c1) => c1.action != 'delete').length >
          0
          ? this.props.photo.comments.filter((c1) => c1.action != 'delete')[0]!
              .comment!
          : ''
      this.state = {
        comment: firstComment || this.props.photo.comment,
        isClosing: false,
        dialogHeight: 0,
        dialogWidth: 0
      }
      this.onCommentChanged = this.onCommentChanged.bind(this)
      this.onSave = this.onSave.bind(this)
      this.onDelete = this.onDelete.bind(this)
    }

    onCommentChanged(event: any): void {
      this.setState({ comment: event.target.value })
    }
    //  async is in the setState
    // eslint-disable-next-line @typescript-eslint/require-await
    async onSave(takeAnotherPhoto: boolean): Promise<string> {
      // avoid double clicking on the save button
      if (!this.state.isClosing) {
        this.setState(
          { isClosing: true },
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          async (): Promise<void> => {
            this.props.photo.comment = this.state.comment
            if (this.state.comment) {
              this.props.photo.comments = []
              this.props.photo.comments.push({
                comment: this.state.comment,
                // name : this.props.ctx ? this.props.ctx.auth.user.fullName : ' ',
                name: this.props.fullName ? this.props.fullName : 'unknown',
                id: null,
                photoId: this.props.photo.id ? this.props.photo.id : null,
                // tslint:disable-next-line:max-line-length
                createdDateTime: formatDate(
                  'display_datetime',
                  new Date(),
                  this.props.dateTimeFormat
                    ? this.props.dateTimeFormat
                    : 'yyyy-MM-dd'
                )
              }) // added the conditional test for jest
              // should be formatDate('utc', new Date()) ;
            }
            if (this.props.mode == 'new') {
              const photo = this.props.photo
              // tslint:disable-next-line:max-line-length
              const [storedUrl, storedThumbnailUrl] = await Promise.all([
                this.props.imageStorage.makePermenant(photo.url),
                this.props.imageStorage.makePermenant(photo.thumbnailUrl)
              ])
              photo.url = storedUrl
              photo.thumbnailUrl = storedThumbnailUrl
              if (this.props.ctx.env.os == 'windows') {
                const imgObjectUrl =
                  await this.props.imageStorage.getImageObjectUrl(
                    photo.url,
                    true
                  )
                const imgThumbnailObjectUrl =
                  await this.props.imageStorage.getImageObjectUrl(
                    photo.thumbnailUrl,
                    true
                  )
                photo.localObjectUrl = imgObjectUrl
                photo.localThumbnaiObjectlUrl = imgThumbnailObjectUrl
              }
            }
            this.props.onSave(this.props.photo, takeAnotherPhoto)
          }
        )
      }
      return ''
    }

    async onDelete(): Promise<string> {
      if (isLocalImage(this.props.photo.url)) {
        await this.props.imageStorage.removeImage(this.props.photo.url)
      }
      if (isLocalImage(this.props.photo.thumbnailUrl)) {
        await this.props.imageStorage.removeImage(this.props.photo.thumbnailUrl)
      }
      if (this.props.onDelete) {
        this.props.onDelete(this.props.photo)
      }
      return ''
    }

    onClose = (): void => {
      if (this.props.mode == 'new') {
        const photo = this.props.photo
        this.props.imageStorage.removeImage(photo.url)
        this.props.imageStorage.removeImage(photo.thumbnailUrl)
      }
      this.props.onClose()
    }
    render(): React.ReactNode {
      const photo = this.props.photo
      const localImage = isLocalImage(photo.url)
      const photoUrl = this.props.ctx.client.getAuthenticatedPhotoUrl(
        photo.localObjectUrl || photo.url
      )
      const style: React.CSSProperties = {}
      if (this.props.ctx.env.os == 'windows') {
        style.overflow = 'hidden'
      } else {
        style.overflow = 'scroll'
        if (this.props.ctx.env.os == 'browser') {
          style.maxWidth = '250px'
        }
      }
      const photoTab = (
        <Card style={{ marginTop: 0 }}>
          <CardContent>
            <div>
              <div style={style}>
                <img
                  src={photoUrl}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  label={this.props.ctx.i18next?.t('initial_photo_comment')}
                  value={this.state.comment}
                  onChange={this.onCommentChanged}
                  disabled={
                    !localImage && !this.props.allowSavedPhotoCommentUpdate
                  }
                  margin='dense'
                />
              </div>
            </div>
          </CardContent>
        </Card>
      )

      const tabs: ITabDefinition[] = [
        {
          name: 'Photo',
          label: 'Photo',
          component: photoTab
        }
      ]

      // only show comments tab for editing existing photo
      if (!localImage && this.props.isMultipleComment) {
        const commentTab = (
          <Card style={{ marginTop: 0 }}>
            <CardContent>
              <PhotoComments
                isOpen={true}
                photo={this.props.photo}
                fullName={this.props.fullName}
                dateTimeFormat={this.props.dateTimeFormat}
              />
            </CardContent>
          </Card>
        )
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const photoCount =
          this.props.photo && this.props.photo.comments
            ? this.props.photo.comments.filter((c1) => c1.action != 'delete')
                .length
            : 0
        tabs.push({
          name: 'Comments',
          label: 'Comments',
          component: commentTab,
          badgeContent: photoCount > 0 ? '' + photoCount : undefined
        })
      }
      return (
        <Dialog
          disableBackdropClick={true}
          open={this.props.isOpen}
          onClose={this.onClose}
          aria-labelledby='form-dialog-title'
          // windows app disbale scrolling by default, we need to handle it by adding overflow:auto does scroll=paper work on windows?
          PaperProps={{
            style: {
              // added ENV.os == '' for resizing image in browser mode
              overflow:
                this.props.ctx.env.os == 'windows' ||
                this.props.ctx.env.os == 'android' ||
                this.props.ctx.env.os == ''
                  ? 'auto'
                  : this.props.ctx.env.os == 'browser' ||
                    this.props.ctx.env.os == 'ios'
                  ? 'overflow'
                  : 'hidden',
              // make sure the dialog doesn't resize during tab switching
              height:
                this.state.dialogHeight > 0 ? this.state.dialogHeight : '',
              width: this.state.dialogWidth > 0 ? this.state.dialogWidth : ''
            }
          }}
        >
          <div
            id='photoDialog'
            style={{
              padding: 0,
              margin: 0,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <DialogContent>
              <TabsComponent
                hasDetailsTab={false}
                color='light'
                compact={true}
                onClickTab={(): void => {
                  if (
                    this.state.dialogHeight == 0 ||
                    this.state.dialogWidth == 0
                  ) {
                    // first time click the comment tab, we need to save the dialog dimension
                    const dialogHeight =
                      document.getElementById('photoDialog') != null &&
                      document.getElementById('photoDialog')?.clientHeight
                        ? document.getElementById('photoDialog')!.clientHeight
                        : 0
                    const dialogWidth =
                      document.getElementById('photoDialog') != null &&
                      document.getElementById('photoDialog')?.clientWidth
                        ? document.getElementById('photoDialog')!.clientWidth
                        : 0
                    this.setState({ dialogHeight, dialogWidth })
                  }
                }}
                tabs={tabs}
              />
            </DialogContent>
            <DialogActions style={{ alignItems: 'flex-end' }}>
              {localImage && (
                <Button color='primary' onClick={this.onClose}>
                  {this.props.ctx.i18next.t('cancel')}
                </Button>
              )}
              {(localImage || this.props.allowSavedPhotoDeletion) &&
                this.props.mode == 'edit' && (
                  <Button style={{ color: '#F00' }} onClick={this.onDelete}>
                    {this.props.ctx.i18next.t('delete')}
                  </Button>
                )}
              {(localImage || this.props.allowSavedPhotoCommentUpdate) && (
                <Button color='primary' onClick={() => this.onSave(false)}>
                  {this.props.ctx.i18next.t('save')}
                </Button>
              )}
              {this.props.showTakePhotoButton &&
                localImage &&
                photo.source == 'camera' &&
                this.props.mode == 'new' && (
                  <Button color='primary' onClick={() => this.onSave(true)}>
                    {this.props.ctx.i18next.t('save_and_take_another_photo')}
                  </Button>
                )}
              {!localImage && (
                <>
                  <Button color='primary' onClick={this.onClose}>
                    {this.props.ctx.i18next.t('close')}
                  </Button>
                </>
              )}
            </DialogActions>
          </div>
        </Dialog>
      )
    }
  }
)
