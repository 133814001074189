import * as React from 'react'
import { IPhoto } from '../../models/Checklist'
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { AddPhotoButton } from '../photos/AddPhotoButton'
import FormControl from '@material-ui/core/FormControl'
import { PhotoThumbnail } from '../photos/PhotoThumbnail'
import { PhotoDialog } from '../photos/PhotoDialog'
import { IImageStorage } from '../../data_sources/imagestorage/imageStorage'
import {
  withOneplaceLibraryContext,
  IOneplaceLibraryContextProp
} from '../OneplaceLibraryProvider'
import { makePhotosPermanent } from './utils/imageUtils'
import { Camera } from '../photos/Camera'

const styles = (_theme: Theme) =>
  createStyles({
    dividerWrapper: {
      marginTop: 16,
      marginBottom: 16
    },
    galleryHeading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  })

export interface IGalleryProps
  extends WithStyles<typeof styles>,
    IOneplaceLibraryContextProp {
  galleryId: string
  photos: IPhoto[]
  imageStorage: IImageStorage
  onPhotosUpdated: () => void
  isMultipleComment?: boolean // allow adding multiple comments?
  fullName?: string
  onDeletePhoto?: (photo: IPhoto) => void
  allowSavedPhotoDeletion?: boolean
  allowSavedPhotoCommentUpdate?: boolean
  allowPhotosFromGallery?: boolean
  hideAttachments?: boolean
}

export interface IGalleryState {
  photos: IPhoto[]
  photoDialogMode: 'new' | 'edit'
  photoDialogPhoto: IPhoto | null
  photoDialogOpen: boolean
  cameraOpen: boolean
}

export const Gallery = withStyles(styles)(
  withOneplaceLibraryContext(
    class extends React.Component<IGalleryProps, IGalleryState> {
      public static defaultProps = {
        allowSavedPhotoDeletion: false
      }
      static displayName = 'Gallery'
      constructor(props: any) {
        super(props)
        this.onPhotoUploaded = this.onPhotoUploaded.bind(this)
        this.onPhotoSaved = this.onPhotoSaved.bind(this)
        this.onPhotoDeleted = this.onPhotoDeleted.bind(this)
        this.onPhotoDialogClosed = this.onPhotoDialogClosed.bind(this)
        this.onPhotoClicked = this.onPhotoClicked.bind(this)
        this.state = {
          photos: this.props.photos,
          photoDialogOpen: false,
          photoDialogPhoto: null,
          photoDialogMode: 'new',
          cameraOpen: false
        }
      }

      async onPhotoUploaded(photos: IPhoto[]) {
        // if user selected only one photo, we show preview and option to add comment
        if (photos.length === 1) {
          this.setState({
            photoDialogMode: 'new',
            photoDialogPhoto: photos[0],
            photoDialogOpen: true,
            cameraOpen: false //reset flag (in case we're using native)
          })
        }
        // otherwise we just add all photos
        else {
          await makePhotosPermanent(
            photos,
            this.props.imageStorage,
            this.props.ctx.env.os == 'windows'
          )
          this.props.photos.push(...photos)
          this.props.onPhotosUpdated()
          this.forceUpdate()
        }
      }

      onPhotoSaved(photo: IPhoto, takeAnotherPhoto: boolean) {
        if (this.state.photoDialogMode == 'new') {
          this.props.photos.push(photo)
        }
        this.setState({
          photoDialogOpen: false,
          cameraOpen: takeAnotherPhoto
        })
        this.props.onPhotosUpdated()
      }

      onPhotoDeleted(photo: IPhoto) {
        const photoIdx = this.props.photos.findIndex(
          (storedPhoto) => storedPhoto.url == photo.url
        )
        if (photoIdx > -1) {
          // TODO: Delete photo from storage
          this.props.photos.splice(photoIdx, 1)
          this.props.onDeletePhoto?.(photo)
        }
        this.setState({
          photoDialogOpen: false
        })
        this.props.onPhotosUpdated()
      }

      onPhotoDialogClosed() {
        this.setState({
          photoDialogPhoto: null,
          photoDialogOpen: false
        })
      }

      onPhotoClicked(photo: IPhoto) {
        this.setState({
          photoDialogMode: 'edit',
          photoDialogPhoto: photo,
          photoDialogOpen: true
        })
      }

      render() {
        return (
          <div>
            <div className={this.props.classes.galleryHeading}>
              <Typography variant='subtitle1' style={{ fontSize: 18 }}>
                {this.props.ctx.i18next.t('gallery')}
              </Typography>
              <AddPhotoButton
                env={this.props.ctx.env}
                controlId={this.props.galleryId}
                className=''
                imageStorage={this.props.imageStorage}
                onPhotoUploaded={this.onPhotoUploaded}
                allowPhotosFromGallery={this.props.allowPhotosFromGallery}
              />
            </div>
            {this.props.photos && this.props.photos.length > 0 && (
              <FormControl fullWidth style={{ marginBottom: 10 }}>
                <div
                  style={{ marginTop: 20, display: 'flex', flexWrap: 'wrap' }}
                >
                  {this.props.photos
                    .filter(
                      (p) =>
                        !this.props.hideAttachments ||
                        (this.props.hideAttachments &&
                          p.attachmentType !== 'attachment')
                    )
                    .map((photo: IPhoto, idx: number) => {
                      const photoUrl =
                        this.props.ctx.client.getAuthenticatedPhotoUrl(
                          photo.localThumbnaiObjectlUrl || photo.url
                        )
                      return (
                        <PhotoThumbnail
                          key={idx}
                          src={photoUrl}
                          photo={this.props.isMultipleComment ? photo : null}
                          onClick={this.onPhotoClicked.bind(this, photo)}
                        />
                      )
                    })}
                </div>
              </FormControl>
            )}
            {this.state.photoDialogOpen && (
              <PhotoDialog
                isOpen={this.state.photoDialogOpen}
                mode={this.state.photoDialogMode}
                photo={this.state.photoDialogPhoto!}
                imageStorage={this.props.imageStorage}
                onClose={this.onPhotoDialogClosed}
                onSave={this.onPhotoSaved}
                onDelete={this.onPhotoDeleted}
                isMultipleComment={this.props.isMultipleComment}
                fullName={this.props.fullName}
                allowSavedPhotoDeletion={this.props.allowSavedPhotoDeletion}
                allowSavedPhotoCommentUpdate={
                  this.props.allowSavedPhotoCommentUpdate
                }
              />
            )}
            <Camera
              open={this.state.cameraOpen}
              onPhotoTaken={(photo: IPhoto) => {
                this.onPhotoUploaded([photo])
                this.setState({ cameraOpen: false })
              }}
              onCloseCamera={() => this.setState({ cameraOpen: false })}
            />
          </div>
        )
      }
    }
  )
)
